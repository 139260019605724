// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
import { Controller } from "stimulus"

// This controller Will be used to handle click events from
// a link and perform a ajax call..  The success of the AJAX
// Call will render a partial in the div specified

// Assumptions
// - overlay div is on the page
// - Data attributes
//   - action (endpoint to handle the reindex)
//   - display_div (div where results will get displayed)
//   - direction ( direction the record should go)

export default class extends Controller {
  static targets = ["direction_link"];

  connect() {
    let stim = this
    let spinner_overlay = $("#overlay")
    $(stim.direction_linkTargets).each(function (i, direction_link) {
      $(direction_link).on("click", stim, function (e) {
        e.preventDefault()
        e.stopImmediatePropagation()
        let display_div = $(this).data("displayDiv")
        let direction = $(this).data("direction")
        let action = $(this).data("action")
        let sort_params = ''
        if ($(this).data('sort') && $(this).data('sort') == true) {
          //console.log($("th[class^='sorting sorting_']"))
          if ($("th[class^='sorting sorting_']").length > 0) {
            let sort_index = $("th[class^='sorting sorting_']").last().data('sortIndex')
            let sort_direction = ($("th[class^='sorting sorting_']").last().hasClass('sorting_asc')) ?
              'asc' : 'desc'
            sort_params = `&sort_index=${sort_index}&sort_direction=${sort_direction}`
          }
        }
        spinner_overlay.show()
        stim.reorder_index(action, direction, display_div, spinner_overlay, sort_params)
      })
    })
  }

  reorder_index(action, direction, display_div, spinner_overlay, sort_params = '') {
    $.ajax({
      method: "GET",
      url: action + `?direction=${direction}${sort_params}`,
    }).then(
      function (data) {
        $(`#${display_div}`).html(data)
        spinner_overlay.hide()
      },
      function (data) {
        spinner_overlay.hide()
        alert("Problem encountered!!! Contact offices!!")
      }
    )
  }
}
