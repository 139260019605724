export function parse_unit_batch_data(data) {
  /*  $(".modal").modal("hide");
    $("#property-units-datatable").DataTable().ajax.reload();*/

  let url = window.location.href
  if (url.indexOf("?") > -1) {
    url += "&view=units"
  } else {
    url += "?view=units"
  }
  window.location.href = url
}
