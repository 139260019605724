// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side ajax form submit handler.
//
// This 'ajax' controller should ALWAYS BE LAST in any list of stimulus controllers you
// attach to a form. That will ensure that any submit-related stimulus actions from other
// controllers will execute first, and thereby prevent 'ajax' handlers being invoked if
// such be their intent. For example, some controllers might perform form-specific validation
// upon submit events. They should be able to prevent propagation of the event to 'ajax'
// if validation fails.
//
//
import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'

export default class extends Controller {
  static targets = ['form', 'notify', 'discard']

  initialize() {
    let stim = this

    //
    //
    $(stim.formTargets).each(function (f, form) {
      let $form = $(form)
      let $uploads = $('[data-direct-upload-url]', $form)
      let submit_fn = $uploads.length ? stim.null_submit : stim.submit
      let ctxt = { stim: stim, form: form, submit: stim.submit, uploads: $uploads.length }
      $form
        .on('ajax:beforeSend', ctxt, stim.intercept)
        .on('submit', stim, stim.null_submit)
        .on('real:submit', stim, stim.submit)
        .on('reset', stim, stim.clear)
        .on('direct-uploads:start', ctxt, stim.uploads_start)
        .on('direct-uploads:end', ctxt, stim.uploads_end)
        .on('change input', ctxt, stim.form_change)
        .find('div.form-feedback,div.alert-dismissable').remove()
    })

    //
    //
    $(stim.discardTargets).each(function (b, btn) {
      let $btn = $(btn)
      let ctxt = { stim: stim, form: $btn.closest('form').get(0), submit: null, uploads: null }
      $btn.on('click', ctxt, stim.discard)
    })
  }

  discard(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let $btn = $(svt.currentTarget)
    let $form = $btn.closest('form')
    console.log('ajax#discard', svt, ctxt)

    setTimeout(function () {
      $('div.form-feedback,div.alert-dismissable', $form).remove()
      $('.banjax.collapse.show, .special-btn.collapse.show', $form).collapse('hide')
    }, 600)
    $form.trigger('reset')

    return false
  }

  autosubmit(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    //$(svt.currentTarget).trigger('submit')
    let target = $(svt.currentTarget)
    setTimeout(function () { target.trigger('submit') }, 500)
  }

  form_change(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    console.log('ajax#form_change', svt)
    //
    /////////////////////////////////////////////////////////////////////////////////////////
    // Reveal submit buttons when a form input changes
    //
    // Sometimes the form is cleared involuntarily, so the buttons are shown too soon.
    // to get around that circumstance (resident home page plan form is an example) use
    // the d-none class too. This code will remove d-none on first change, then open it
    // on second pass.
    setTimeout(function () {
      let $reveal = $('.banjax.collapse, .special-btn.collapse', $form).not('d-none').not('show')
      let $uncloak = $('.banjax.d-none.collapse, .special-btn.d-none.collapse', $form).not('show')

      //
      // Uncloak any container that is d-none'ed, but ensure the collapsible remains hidden.
      if ($uncloak.length > 0) {
        $uncloak.toggleClass('d-none', false).collapse('hide')
      }
      //
      // OR... reveal the collapsible container
      else if ($reveal.length > 0) {
        $reveal.collapse('show')
      }
      // $('.banjax.collapse, .special-btn.collapse', $form).not('d-none').not('show').collapse('show')
      // $('.banjax.d-none.collapse, .special-btn.d-none.collapse', $form).not('show').toggleClass('d-none', false).collapse('hide')
    }, 600)

    //
    // Re-enable any buttons that may have been disabled by Rails UJS
    // double-click protection. Once they start typing again, we can
    // allow them to submit if they want.
    let $modal = $form.closest('div.modal')
    let $disabled = $form.find('[data-disable],[data-disable-with]')
    $disabled.prop('disabled', false)
    return true
  }



  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // Pre-submission check
  //
  // This special 'submit' handler is called for forms that include direct-upload elements.
  // Its task is to determine in advance whether direct-upload will occur.
  //
  // If 'Yes' then the function will exit immediately and allow the 'submit' event to propagate
  // freely. That will in-turn assure that direct-upload is performed, and that a 'real:submit'
  // event will be triggered when it finishes.
  //
  // If 'No' then the function will override default 'submit' activities in favor of our
  // own. It will do this because it has no guarantee that a direct-upload will later trigger
  // real:submit.
  null_submit(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    console.log('ajax#null_submit', svt)

    //
    // See if the originator (the submit button) has 'ajaxset' and 'ajaxval'
    // attributes.
    //
    // 'ajaxset' should be an in-form input selector, and 'ajaxval' the value
    // to set there.
    try {
      let $btn = $(svt.originalEvent.submitter)
      let $input = $($btn.data('ajaxset'), $form)
      if ($input.length) {
        let to = $btn.data('ajaxval')
        $input.val(to)
      }
    }
    // Meh. Don't care.
    catch (e) {
      console.error(`ajaxset: ignoring error`, e)
    }

    // svt.stopImmediatePropagation()
    // svt.preventDefault()
    return true
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // Full form submission via JSON
  submit(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    let $inputs = $(':input[name]', $form).toggleClass('is-valid', false).toggleClass('is-invalid', false)
    let postdata = {}

    // Start
    // Find submit button and disable until we get a response
    let $submit_trigger = null
    let original_submit_value = null
    try {
      $submit_trigger = $form.find(':submit')
      original_submit_value = $submit_trigger.val()
      $submit_trigger.prop('disabled', true)
      $submit_trigger.val('.....')
    }
    catch (err) {
      console.log('Error trying to disable submit on form!!!')
      console.log(err)
    }
    // End



    console.log('ajax#submit', svt)

    $form.find('div.form-feedback,div.alert-dismissable').remove()
    $inputs.not('.custom-file-input').each(function (i, elem) {
      let $input = $(elem)

      //
      // Ensure any checkboxes are set to '0' or '1' by 'checked'
      if ($input.attr('type') === 'checkbox') $input.val($input.prop('checked') ? '1' : '0')

      //
      // Store the input value in the postdata map, keyed to its name.
      // But if the input is a radio button, do that only if the input is checked.
      try {
        if ($input.attr('type') !== 'radio' || $input.prop('checked')) {
          postdata[$input.attr('name')] = $input.prop('multiple') ? $input.val() : $input.val().trim()
        }
      }
      catch (err) {
        console.log(`Error trimming ${$input.attr('name')}`)
        console.log(err)
        // debugger
      }

    })

    //
    ///////////////////////////////////////////////////////////////////////////////////////
    // Ajax Asynchronous Response Closures
    //
    // Here we create two closures to handle asynchronous 'done' and 'fail' callbacks from
    // Ajax. We use closures so that the handlers can access the stimulus controller and
    // the form JQ object.
    //
    // Submit Success:
    //
    // The success response from the controller will be empty. Clean-up the form and post
    // a smiley.
    //
    let done_fn = function (stim, $form) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        let dismissed = false
        let $noisy = $('div[data-target="ajax.notify"]:not(.quietly)', $form)
        console.log('ajax#submit#done', xhr, data)

        if (resp) {
          let $modal = $form.closest('.modal')
          let messages = resp && resp.hasOwnProperty('notify') ? resp['notify'] : null

          //
          // Write any messages as dismissable notifications
          if (messages) {
            for (let n in messages) {
              stim.dismissable($form, messages[n], n)
              dismissed = true
            }
          }

          if (resp.hasOwnProperty('reload') && resp.reload.length) {
            if ($modal.length && messages) {
              $(':input', $form).not('[data-dismiss]').prop('disabled', true)
              $(':input[type="submit"]', $form).not('[data-dismiss]').prop('hidden', true)
              $(':input[type="submit"]', $form).filter('[data-dismiss]').text('OK')
              $modal.on('hidden.bs.modal', function () { location = resp.reload })
            }
            else {
              location = resp.reload
            }
          }

          //
          // Replace the form with new HTML.
          if (resp.hasOwnProperty('html')) {
            let $html = $(resp.html)

            console.log(`ajax#submit#done#html:`, stim, $form, stimulus)

            //
            // The original $form variable is destroyed, or otherwise
            // transformed by this operation. So we must re-assign it
            // as we embed the replacement html.
            $form = $form.html($html.html())

            //
            // Look for stimulus controllers that the new form requires.
            // These need to be reset manually.
            //
            // restims here is a string of space-separated stimulus controller
            // names.
            let restims = $html.data('controller') || ''
            if (restims.length) {
              //
              // Take each controller name in turn...
              restims.split(' ').forEach((name, i, a) => {
                if (name.length) {
                  console.log(`ajax#submit#done#html#restim[${i}][${name}]:`)
                  //
                  // Find all stimulus controller instances that match that type
                  // and re-connect every one of them.
                  stimulus.controllers
                    .filter(obj => { return obj.context.scope.identifier == name })
                    .forEach((ctlr, ii) => {
                      console.log(`ajax#submit#done#html#stim[${i}][${name}][${ii}]: connect...`)
                      //
                      // This is only going to do something to controllers that have a connect() function.
                      // But many of our controllers just have an initialize() function... ajax included!
                      ctlr.connect()
                    })
                }
              })
            }
          }

          if (resp.hasOwnProperty('success_message')) {
            $form.html(resp.success_message)
          }
        }

        if (!dismissed && $noisy.length > 0) {
          stim.dismissable($form, gon.ajax_controller.submit_success_status, 'success')
        }
        enable_submit($submit_trigger, original_submit_value)
      }
    }

    //
    // Submit Failure:
    //
    // The response will be a hash of input names to errors. Use those
    // here to to apply the necessary feedback messages.
    let fail_fn = function (stim, $form) {
      return function (xhr, status, error) {
        let resp = xhr.responseJSON
        console.log('ajax#submit#fail', xhr, error)
        // debugger

        // Refresh table during fail_fn to show reason but update the data
        if (resp.hasOwnProperty('dt_refresh')) {
          console.log("DT REFRESH", resp.dt_refresh)
          let dt_id = resp.dt_refresh
          if ($(`#${dt_id}`).length == 1) {
            $(`#${dt_id}`).DataTable().ajax.reload()
          }
          delete resp['dt_refresh']
        }

        if (resp) {
          for (let f in resp) {
            for (let n in resp[f]) {
              let names = n.replace(/[.]/g, '_attributes.').split('.')
              let name = `${f}[${names.join('][')}]`
              let messages = [resp[f][n]]
              let $input = $(`:input[name$="${name}"]`, $form)
              if ($input.hasClass('flatpickr-input')) {
                let $sib = $input.siblings('.form-control.input')
                if ($sib.length) $input = $sib
              }
              if ($input.length) {
                $input.toggleClass('is-invalid', true)
                stim.feedback($input, messages, 'invalid')
              }
              else {
                if (resp.hasOwnProperty('hide_label')) {
                  stim.dismissable($form, messages, n, true)
                } else {
                  stim.dismissable($form, messages, n)
                }
              }
            }
          }
        }
        enable_submit($submit_trigger, original_submit_value)
      }
    }

    let enable_submit = function (submit_trigger, original_submit_value) {
      try {
        if (submit_trigger.hasClass('leave_disabled_on_submit')) {
          return
        }
        submit_trigger.prop('disabled', false)
        submit_trigger.val(original_submit_value)
      }
      catch (err) {
        console.log('Error trying to enable submit button!!!')
        console.log(err)
      }
    }

    // debugger
    svt.stopImmediatePropagation()
    svt.preventDefault()

    $.ajax({
      method: $form.attr('method') || 'POST',
      url: $form.attr('action'),
      data: $.param(postdata),
      dataType: 'json'
    }).then(done_fn(stim, $form), fail_fn(stim, $form))
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  clear(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    console.log('ajax#clear', svt)
    stim.clearFeedback($form)
    return true
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  // Collective direct uploads tracking handlers
  //
  // UJS ajax interception: this handler is invoked just prior to a UJS Ajax transaction.
  // Its purpose is, quite simply, to prevent that transaction from completing.
  // This it does merely by returning 'false'.
  //
  // However: it is at this time - when a 'submit' event has fully-propagated the stack -
  // that we would like to perform our own submit operation. This we accomplish by triggering
  // a 'real:submit' event on our form.
  //
  // UNLESS: our form tells us that a direct upload is in progress via data-du-live attribute.
  // In that case we do NOT trigger 'real:submit' now, but allow it to be triggered later when
  // direct uploads have completed.
  //
  // And all because the lady loves Milk Tray.
  //
  intercept(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let permit = true
    console.log("ajax#intercept:", svt)
    //
    // For safety sake we will only prevent ajax commencement for JS requests.
    // Although I'm pretty sure that the event is native to UJS, and only triggered
    // by UJS ajax requests.
    //
    // The event.detail field has two elements: the first is a copy of the XMLHttpRequest
    // to be made, and the second is a copy of the data block passed to Ajax.
    if (svt.detail.length > 1) {
      let ajax_data = svt.detail[1]
      if (ajax_data.dataType === 'script') {
        let $form = $(ctxt.form)
        let loading = $form.data('du-live') || false
        if (!loading) $form.trigger('real:submit')
        permit = false
      }
    }
    // debugger
    return permit
  }

  uploads_start(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("ajax#uploads_start:", svt)
    $(ctxt.form).data('du-live', true)
    // debugger
    return true
  }

  uploads_end(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("ajax#uploads_end:", svt)
    if (ctxt.hasOwnProperty('form')) {
      $(ctxt.form).data('du-live', false).trigger('real:submit')
    }
    return true
  }


  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // feedback: append bootstrap form feedback message
  feedback($for, messages = [], cat = 'invalid') {
    let $parent = $for.parents('div').first()
    $parent.find('div.form-feedback,div.alert-dismissable').remove()
    //
    // Need messages to be an array.
    if (!Array.isArray(messages)) {
      messages = [messages]
    }
    //
    // But not an array within an array. Eesh.
    if (messages.length == 1 && Array.isArray(messages[0])) {
      messages = messages[0]
    }
    // console.log(`ajax.feedback(${Array.isArray(messages)}, ${messages.length}, ${cat})`)
    if (messages.length && ['invalid', 'valid'].includes(cat)) {
      $parent.append('<div class="form-feedback ' + cat + '-feedback">' + messages.join(',<br>') + '</div>')
    }
    return messages.length
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  clearFeedback($form) {
    $form.find('div.form-feedback,div.alert-dismissable').remove()
    $(':input[name]', $form).toggleClass('is-valid', false).toggleClass('is-invalid', false)
    $('div.custom-file', $form).each(function (c, container) {
      let $container = $(container)
      $('input', $container).toggleClass('is-valid', false).toggleClass('is-invalid', false).attr('disabled', false).val('')
      $('label', $container).text('Choose file')
    })
  }

  //function to return translated field based on passed field
  translate_bootstrap_field(field) {
    let t_field = field
    switch (field) {
      case 'success':
        t_field = gon.ajax_controller.submit_success_label
        break
    }
    return ['danger', 'warning', 'info', 'success', 'light', 'dark'].includes(field) ? '' : `${t_field}: `
  }
  /////////////////////////////////////////////////////////////////////////////////////////
  // dismissable: generate a dismissable bootstrap alert inside a 'notify' container.
  dismissable($form, message, field, hide_field = false) {
    let cat = ['danger', 'warning', 'info', 'success', 'light', 'dark'].includes(field) ? field : 'light'
    cat = field === 'notice' ? 'info' : cat
    let $container = $('div[data-target="ajax.notify"]', $form)
    let msgary = Array.isArray(message) ? message.flat() : [message]

    let display_field = this.translate_bootstrap_field(field)

    console.log('ajax#dismissable:', msgary, message, Array.isArray(message))
    for (let m in msgary) {
      let msg = msgary[m]

      let dism_html = `<div class="alert alert-dismissable fade show alert-${cat}" role="alert">` +
        `<span>${display_field}${msg}</span>` +
        '<button class="close" type="button" data-dismiss="alert" aria-label="close">' +
        '<span aria-hidden="true"><i class="md-glyph">close</i></span></button></div>'

      if (hide_field == true) {
        dism_html = `<div class="alert alert-dismissable fade show alert-${cat}" role="alert">` +
          `<span>${msg}</span>` +
          '<button class="close" type="button" data-dismiss="alert" aria-label="close">' +
          '<span aria-hidden="true"><i class="md-glyph">close</i></span></button></div>'
      }

      $container.append(dism_html)
    }
  }
}
