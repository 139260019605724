import consumer from "./consumer"

consumer.subscriptions.create(
  {
    channel: "ServiceProviderAdministratorsChannel",
    room: room_name(),
  },
  {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log(`connected to room ${room_name()}`)
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      console.log("disconnected")
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      console.log(`Received data on room: ${room_name()}`)
      console.log(data)
      let dismiss_button = $(
        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
      )
      let div_html = $(`<strong>${data.message}</strong>`)
      let new_message_alert = $("<div></div>")
        .addClass(`alert alert-${data.type} alert-dismissable fade show`)
        .html(div_html)
      new_message_alert.append(dismiss_button)
      let alert_div = $("#action_cable_div")
      $(alert_div).append(new_message_alert)
    },
  }
)

function room_name() {
  return `spa_channel_on_${gon.cable_vars.tenant_schema}`
}