// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// hotref - Alternate form submit action
//
// This controller provides the ability to submit form data to an alternate destination
// by hotwiring an identifier embedded in the form to a target URL. It is intended for
// use with buttons or links whose target changes dynamically, and so cannot be entirely
// pre-scripted.
//
// By 'hotwiring' we mean re-writing the last integer in the target URL path (integers
// usually being _identifiers_) with the value read from a form input.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['shoot']

  connect() {
    console.info("HOTREF CONNECT")
    let stim = this

    $(stim.shootTargets)
      .filter('[data-hotref]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let ctxt = { stim: stim, hotref: $btn.data('hotref'), form: $btn.closest('form') }
        $btn.on('click', ctxt, stim.shoot)
      })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  // shoot: intercept a link click and re-write the destination so that the correct resource
  //        identifier is used.
  //
  //  Will also apply form inputs as parameters if any kind of POST operation is requested,
  //  and shoot-off an ajax request to deliver it.
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  shoot(svt) {
    //
    // Callback to handle successful Ajax response.
    // That response will be HTML for the target page.
    // We use it to re-write the document DOM.
    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        console.log('hotref#shoot#done', xhr, data)
        //
        if (/^<!doctype/i.test(data)) {
          document.open()
          document.write(data)
          document.close()
        }
      }
    }
    //
    // Callback to handle a failed Ajax response.
    // Not much we can do about that.
    let fail_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        // let resp = xhr.responseJSON
        console.error('hotref#shoot#fail', xhr, data)

      }
    }

    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let a = svt.currentTarget
    let $a = $(a)

    console.log('hotref#shoot:', svt, ctxt, a.constructor.name)
    let hotref = ctxt.hotref
    let $form = $(ctxt.form)

    let $to = null
    let url = hotref['url'] || $a.attr('href')
    let method = hotref['method'] || $a.attr('method') || 'get'
    let parts = url.split('?')[0].split(/([0-9]+)/).filter(function (el) { return el != null && el.length > 0; })
    let postparam = {}

    //
    // If a 'to' attribute was specified in the data bloc it means that
    // the last integer segment of the URL needs to be replaced with the
    // value of the 'to' input.
    if (hotref.hasOwnProperty('to')) {
      //
      // Find the 'to' input. It is probably a select, and read its value.
      $to = $(`:input[name$="[${hotref.to}]"]`, $form).last()
      let idval = $to.val()
      //
      // Run backwards through the pre-split url parts array, looking for
      // the first [last] part that is all digits. And replace that part
      // with the new identifier.
      for (let i = parts.length - 1; i >= 0; i--) {
        if (/[0-9]+/.test(parts[i])) {
          parts[i] = idval;
          break;
        }
      }
      //
      // Rebuild the url by joining its [possibly-tweaked] parts.
      url = parts.join('')
    }
    $form.find(':input[name]').each(function (e, elem) {
      let $elem = $(elem)
      postparam[$elem.attr('name')] = $elem.val()
    })

    // let query = method.toLowerCase() === 'get' ? '' : `?${$.param(postparam)}`
    let query = $.param(postparam)

    //
    // When the target element is an anchor, and method is 'get', all we need to do is
    // re-write the element 'href' with the new path and allow nature to take its course.
    if (a instanceof HTMLAnchorElement && method.toLowerCase() === 'get') {
      $a.attr('href', url)

    }
    //
    // But if the target element is NOT an anchor, or its method is posty, then we will
    // instead use Ajax to deliver the request. And we will furnish form inputs as parameters.
    else {
      svt.stopImmediatePropagation()
      svt.preventDefault()
      if (method == 'get') {
        $.get({ url: url }).then(done_fn(stim, ctxt), fail_fn(stim, ctxt))
      }
      else {
        $.post({
          method: method.toUpperCase(),
          url: url,
          data: $.param(postparam),
        }).then(done_fn(stim, ctxt), fail_fn(stim, ctxt))
      }
    }

    return true
  }
}
