// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// Depends - for showing and hiding components that depend on some [form control] value.
// For example: form inputs that depend on a particular selection.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['on']

  connect() {
    let stim = this
    console.info("DEPENDS CONNECT", stim)

    //
    // These targets are typically buttons or button-like elements, and are required
    // to provide additional information in data[drop-wifi].
    $(stim.onTargets)
      .filter('[data-depends]')
      .each(function (e, elem) {
        let $elem = $(elem)
        let depends = $elem.data('depends')
        let $form = depends.hasOwnProperty('form') ? $(depends.form) : $elem.closest('form')
        let $modal = $form.closest('div.modal')
        let ctxt = { stim: stim, depends: depends, form: $form, elem: $elem, modal: $modal }

        console.log("depends.on:", ctxt)
        $form
          .on('change', ctxt, stim.checkup)
          .on('reset', ctxt, stim.checkup_after)
          .trigger('change')
        if ($modal.length > 0) {
          $modal.on('shown.bs.modal', ctxt, stim.checkup)
        }
      })

  }

  //
  // Checkup a dependent element after form change or other event.
  checkup(svt) {
    // console.log('depends#checkup:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    ctxt = ctxt || alt

    //
    // Find the source. Search first for matching names, then for matching ID.
    // Select the last one if there are multiple hits.
    // We are always trying to match the trailing part of an identifier.
    //
    // Almost all object-driven Rails forms will assign []-bracketed names.
    // So that is what we first try to look for.
    let $src = $(`:input[name$="[${ctxt.depends.src}]"]`, ctxt.form)

    //
    // Search for Ransack names that are not enclosed in []-brackets.
    // This is a Lou thing.
    if (!$src.length) {
      $src = $(`:input[name$="${ctxt.depends.src}"]`, ctxt.form).last()
    }

    //
    // Search for trailing ID match, if we _still_ have not found anything
    if (!$src.length) {
      $src = $(`:input[id$="${ctxt.depends.src}"]`, ctxt.form).last()
      if ($src.length) {
        console.warn(`depends.checkup: found ${$src.length} ID match for ${ctxt.depends.src}`, $src)
      }
      else {
        console.warn(`depends.checkup: failed to find ${ctxt.depends.src}`)
        return true
      }
    }
    //
    // Or latch on the last listed of multiple matching elements.
    else if ($src.length > 1) {
      console.warn(`depends.checkup: found ${$src.length} matching names for ${ctxt.depends.src}`, $src)
      if ($src.first().attr('type') == 'radio') {
        //
        // Radio buttons tend to always be named the same way. So filter-out unchecked radio buttons.
        $src = $src.filter(":checked").first()
      }
      else {
        $src = $src.last()
      }
    }

    let cval = $src.val() || 'null'
    let nval = Number(cval)
    if ($src.filter('[type="checkbox"]').length) {
      nval = $src.prop('checked')
      cval = nval ? 'true' : 'false'
      console.log(`depends.checkup: ${$src.attr('id')} ${$src.attr('type')} resolves to ${cval}`)
    }
    else if ($src.filter('[type="radio"]').length) {
      cval = nval = $src.prop('checked') ? $src.val() : false
      console.log(`depends.checkup: ${$src.attr('id')} ${$src.attr('type')} resolves to ${cval}`)
    }

    let good = false
    let disable = false

    if (isNaN(nval)) nval = Infinity
    console.log(`depends#checkup: '${cval}' N[${nval}]`, $src)

    //
    // Value is in array
    if (ctxt.depends.hasOwnProperty('values')) {
      good = ctxt.depends.values.includes(cval)
      console.log(`depends#checkup.in: ${cval} in [ ${ctxt.depends.values} ] ? ${good}`)
    }
    //
    // Value is in array
    if (ctxt.depends.hasOwnProperty('eq')) {
      let eq = ctxt.depends.eq instanceof Array ? ctxt.depends.eq : [ctxt.depends.eq]
      good = eq.includes(cval)
      console.log(`depends#checkup.eq: ${cval} in [ ${eq} ] ? ${good}`)
    }
    // Value is NOT in array
    if (ctxt.depends.hasOwnProperty('ne')) {
      let ne = Array.isArray(ctxt.depends.ne) ? ctxt.depends.ne : [ctxt.depends.ne]
      good = !ne.includes(cval)
      console.log(`depends#checkup.ne: ${cval} NOT in [ ${ne} ] ? ${good}`)
    }
    //
    // Value is less than number
    if (ctxt.depends.hasOwnProperty('lt') && !good) {
      good = nval < Number(ctxt.depends.lt)
      console.log(`depends#checkup.lt: ${nval} < ${ctxt.depends.lt} ? ${good}`)
    }
    //
    // Value is greater than number
    if (ctxt.depends.hasOwnProperty('gt') && !good) {
      good = nval > Number(ctxt.depends.gt)
      console.log(`depends#checkup.gt: ${nval} > ${ctxt.depends.gt} ? ${good}`)
    }
    //
    // Value is less than or equal to number
    if (ctxt.depends.hasOwnProperty('lte') && !good) {
      good = nval <= Number(ctxt.depends.lte)
      console.log(`depends#checkup.lte: ${nval} <= ${ctxt.depends.lte} ? ${good}`)
    }
    //
    // Value is greater than or equal to number
    if (ctxt.depends.hasOwnProperty('gte') && !good) {
      good = nval >= Number(ctxt.depends.gte)
      console.log(`depends#checkup.gte: ${nval} > ${ctxt.depends.gte} ? ${good}`)
    }

    if (ctxt.depends.hasOwnProperty('disable')) {
      let eq = ctxt.depends.disable instanceof Array ? ctxt.depends.disable : [ctxt.depends.disable]
      let disable = !eq.includes(cval)
      good = true
      console.log(`depends#checkup.disable: '${cval}' !== '${eq}' ? ${disable}`)
      ctxt.elem.prop('disabled', disable)
    }

    //
    //
    if (ctxt.elem.hasClass('collapse')) {
      ctxt.elem.collapse(good ? 'show' : 'hide')
    }
    else if (ctxt.elem.hasClass('conceal')) {
      ctxt.elem.toggleClass('show', good)
      let $submit = $('input[type="submit"]', ctxt.form)
      console.log(`depends#checkup:`, ctxt, $submit)
      $submit.trigger('focus')
    }
    else {
      ctxt.elem.prop('hidden', !good).toggleClass('show', good)
    }
    return true
  }

  //
  // Trigger a delayed checkup some time after a form reset.
  // It works by triggering a 'change' event on the form, because checkup()
  // has to be called as an event handler.
  checkup_after(svt) {
    console.log('depends#checkup_after:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let fwd = svt
    setTimeout(function () { ctxt.form.trigger('change') }, 1)
    // setTimeout(function () {stim.checkup(fwd)}, 1)
    return true
  }

}
