// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side datepicker widget handler
//
//
import {Controller} from 'stimulus'
let convert = require('color-convert')
const reRGB = /rgb[(]\s*(?<r>\d{1,3})\s*,\s*(?<g>\d{1,3})\s*,\s*(?<b>\d{1,3})\s*[)]/

export default class extends Controller {
  static targets = ['color', 'reset']

  connect() {
    console.info("Theming CONNECT")
    let stim = this

    // set :root var colors based on gon variables
    for (const color_var in gon.customizations) {
      // console.log(`Color: ${color_var} - ${gon.customizations[color_var]}`);
      document.documentElement.style.setProperty(color_var, gon.customizations[color_var])
    }

    for (const color_var in gon.property_customizations) {
      // console.log(`Color: ${color_var} - ${gon.customizations[color_var]}`);
      document.documentElement.style.setProperty(color_var, gon.property_customizations[color_var])
    }

    $(stim.resetTargets).each(function (c, reset_button) {
      let $reset_button = $(reset_button)
      $reset_button.on('click', stim, stim.resetToDefault)
    })


    //
    // Read :root definitions into a table.
    $(stim.colorTargets)
      .filter('[data-color]')
      .each(function (c, cvar) {
        let $cvar = $(cvar)
        let $form = $cvar.parents('form').first()
        let data = $cvar.data('color')
        let src = $cvar.is('input[type="color"]') ?
          $cvar.attr('id') :
          $('input[type="color"]', $form)
            .filter('[data-color]')
            .filter(function () {
              return $(this).data('color')['name'] == data['name'];
            })
            .first().attr('id')

        let ctxt = {stim: stim, src: `#${src}`, data: data, form: $form}

        //
        // Color input change should trigger recoloring of all dependent shades.
        if ($cvar.is('input[type="color"]')) {

          //
          // HACK: if the color input value is the magic #010101 then we take
          // that to mean that we should read the current value of the associated
          // color variable into the input. In that way we can import the actual
          // theme created by the original CSS into our color variables. Which can
          // then be SAVED.
          if ($cvar.val() == '#010101') {
            let r = document.querySelector(':root')
            let rt = getComputedStyle(r)
            let orig_var = rt.getPropertyValue(data['name']).trim()
            let input_var = $cvar.val()

            console.log(`--- Initial value of ${data['name']} is "${orig_var}"`)
            //
            // Dangit! Google chrome does not like this at all: it takes my value
            // but changes it to #000000!!! Edge too. but Firefox is okay.
            //
            // CORRECTION: the value returned from CSS includes a leading space.
            // And _that_ is what breaks the google color widget. Just be sure that
            // the value written in a color input is trimmed of leading and trailing
            // whitespace!
            //
            if (orig_var && orig_var != input_var) {
              $cvar.val(orig_var)
            }
          }

          //
          // This line is not optional: any change in color input should
          // trigger a re-evaluation of all associated variables.
          $cvar.on('change', ctxt, stim.recolor_all)
        }

        //
        // All targets will respond to color:change event, to perform the
        // actual CSS color adjustment!
        $cvar.on('color:change', ctxt, stim.recolor_one)

        stim.set_color_var(ctxt)
      })
  }

  recolor_all(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    console.log('theming#recolor_all:', svt, ctxt)

    let $form = ctxt.form
    let $recipients = $form
      .find('[data-color]')
      .filter(function () {
        return $(this).data('color')['name'] == ctxt.data['name'];
      })

    $recipients.trigger('color:change')

    return true
  }

  resetToDefault(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let original_vars = stim.getOriginalCssVars()
    let finder = $(this).data('resetName')

    let default_value = $(this).data('defaultValue')
    let input = ($(`input[name$='${finder}']`).length > 0) ?
      $(`input[name$='${finder}']`) : $(`input[name$='${finder}]']`)
    let my_form = input.closest('form')

    // Check original css vars for the color name and populate the val
    // with branding css..  This is pulled from the order of stylesheets
    // fallback on system defaults.....
    let var_name = $(input).data('color').name
    if (var_name in original_vars) {
      default_value = original_vars[var_name]
    }

    let rgb = default_value.match(reRGB)
    if (rgb) {
      let r = Number(rgb.groups.r)
      let g = Number(rgb.groups.g)
      let b = Number(rgb.groups.b)
      let h = convert.rgb.hex(r, g, b)
      default_value = `#${h}`
    }

    input.val(default_value)
    input.trigger('change')
    my_form.trigger('change')
    return true
  }

  getOriginalCssVars(styleSheets = document.styleSheets) {
    /* Cannot remove the cssVars array..  Does not work if removed
    * Added a object to collect the first in order values ( original values)
    * */
    var cssVars = [];
    var cssOrigVals = {};
    // loop each stylesheet
    for (var i = 0; i < styleSheets.length; i++) {
      // loop stylesheet's cssRules
      try { // try/catch used because 'hasOwnProperty' doesn't work
        for (var j = 0; j < styleSheets[i].cssRules.length; j++) {
          try {
            // loop stylesheet's cssRules' style (property names)
            for (var k = 0; k < styleSheets[i].cssRules[j].style.length; k++) {
              let name = styleSheets[i].cssRules[j].style[k];
              // test name for css variable signiture and uniqueness
              if (name.startsWith('--') && cssVars.indexOf(name) == -1) {
                console.log(name);
                cssVars.push(name);
                if (!(name in cssOrigVals)) {
                  /* have to pull the value from a string using a regex */
                  let cssText = styleSheets[i].cssRules[j].cssText
                  let rgx_str = `^.*${name}:(.*?);`
                  let rgxp = new RegExp(rgx_str)
                  let rgxp_result = cssText.match(rgxp)
                  cssOrigVals[name] = rgxp_result[1].trim()
                }
              }
            }
          } catch (error) { }
        }
      } catch (error) { }
    }
    return cssOrigVals;
  }


  recolor_one(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    console.log('theming#recolor_one:', svt, ctxt)
    stim.set_color_var(ctxt)
    return true
  }

  set_color_var(ctxt) {
    console.log('theming.set_color_var:', ctxt)
    let data = ctxt['data']
    let hex = $(ctxt['src']).val()
    let rgb = convert.hex.rgb(hex)
    let hsl = convert.hex.hsl(hex)
    let css_var = data['name'] + (data['shade'] != 0 ? `-${data['shade']}` : '')

    console.log(`--- set ${css_var}: to shade(${data['shade']}) of ${hex}/rgb(${rgb})/hsl(${hsl})`)
    if (data['shade'] != 0) {
      //
      // Calculate shading value to be a delta fraction that should add (or subtract)
      // from current component value. We arrange things so that the '500' variant is
      // the original color itself; so a spectrum gets progressively darker or lighter
      // to left or right
      let shader = ((data['shade'] - 500) / 1000.0) * 2.0;

      //
      // For hsl we only need to adjust its third component, 'lightness'
      hsl[2] += hsl[2] * shader

      //
      // But for rgb we adjust all three components
      rgb[0] += rgb[0] * shader
      rgb[1] += rgb[1] * shader
      rgb[2] += rgb[2] * shader

      //
      // Have to keep all components in proper range: apparently
      // the color convertor does not do that properly. I kinda
      // suspect that it modulo's the values?
      hsl[2] = hsl[2] < 0 ? 0 : (hsl[2] > 255 ? 255 : hsl[2])
      rgb[0] = rgb[0] < 0 ? 0 : (rgb[0] > 255 ? 255 : rgb[0])
      rgb[1] = rgb[1] < 0 ? 0 : (rgb[1] > 255 ? 255 : rgb[1])
      rgb[2] = rgb[2] < 0 ? 0 : (rgb[2] > 255 ? 255 : rgb[2])

      console.log(`--- shader ${shader} yields hsl(${hsl}) or rgb(${rgb})`)
    }

    //
    // Convert shades back to hex.
    // Decide which is better here... rgb scaling or hsl scaling?
    //
    // The mixin that we use for SCSS currently shades on RGB.
    if (true) {
      hex = `#${convert.rgb.hex(rgb)}`
    }
    else {
      hex = `#${convert.hsl.hex(hsl)}`
    }

    console.log(`=== ${hex}`)
    if (true) {
      //document.documentElement.style.setProperty(css_var, hex)
    }

  }
}
