// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// Trip: cause a change in some named form control, such as a select, to trip an associated
// radio button, or toggle.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['up']

  connect() {
    let stim = this
    console.info("TRIP CONNECT", stim)

    //
    // These targets are typically radio buttons or checkboxes.
    // They must possess a data-trip block, in which the trailing part of a
    // src form-control name should be stipulated.
    //
    // Any change made to $src will result in a 'click' event being sent to $elem.
    // Thus a radio button will be selected, or a checkbox toggled.
    $(stim.upTargets)
      .filter('[data-trip]')
      .each(function (e, elem) {
        let $elem = $(elem)
        let trip = $elem.data('trip')
        let $form = trip.hasOwnProperty('form') ? $(trip.form) : $elem.closest('form')
        let $src = $(`:input[name$="[${trip.src}]"]`, $form)
        let ctxt = { stim: stim, trip: trip, form: $form, elem: $elem, src: $src }

        $src.on('change', ctxt, stim.up)
      })

  }

  //
  //
  up(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $elem = ctxt.elem
    console.log('trip#up:', ctxt, $elem)

    $elem.trigger('click')


    return (true)
  }


}
