// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side subscription form handler.
//
// This stimulus controller provides all kinds of useful support for Wi-Fi subscription
// forms: those forms that are used to create or update Wi-Fi SUBSCRIPTIONS and the user
// PROFILES that are associated with them.
//
//
// By convention: stimulus event handlers accept stimulus event arguments ('svt')
// whereas JQ event handlers accept JQ event arguments ('jvt')
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'query',      // A query input, upon which 'autocomplete' is built
    'query_select_search', // A query input, upon which 'autocomplete' is built and selects an option
    // on a form
    'href',       // An href to be modified by selected id
    'selected',   // An element to be shown after query is selected
    'deselected'  // An element to be shown after query is de-selected
  ]

  connect() {
    let stim = this

    //
    // Query targets: these are form inputs whose changing values can query the database
    // for possible matches or autofill suggestions.
    //
    // We use a devbridge autocomplete widget, with callbacks, to manage this for us.
    $(stim.queryTargets)
      .filter('input[data-query-url]')
      .each(function (q, query) {
        let ctx_invalidate = { stim: stim, query: query, fn: stim.queryInvalidate }
        let ctx_select = { stim: stim, query: query, fn: stim.querySelect }

        $(query).attr('autocomplete', 'new-password')
          .on('keydown', ctx_select, stim.killpress)
          .on('input', ctx_select, stim.trim)
          .autocomplete({
            serviceUrl: $(query).data('query-url'),
            type: 'POST',
            dataType: 'json',
            noCache: true,
            onInvalidateSelection: ctx_invalidate.fn.bind(ctx_invalidate),
            onSelect: ctx_select.fn.bind(ctx_select),
            deferRequestBy: 350,
            minChars: 1
          })
      })

    //
    // Query_select_search targets: these are inputs whose changing values can trigger an option
    // selection and submit a form for a lookup in a long list of options in a select
    // ****** Form must include a data-auto-complete-submit="true" attribute
    //
    $(stim.query_select_searchTargets)
      .filter('input[data-query-url]')
      .each(function (q, query) {
        let ctx_select = { stim: stim, query: query, fn: stim.query_searchSelect }
        $(query).attr('autocomplete', 'new-password')
          .on('keydown', ctx_select, stim.killpress)
          .on('input', ctx_select, stim.trim)
          .autocomplete({
            serviceUrl: $(query).data('query-url'),
            type: 'POST',
            dataType: 'json',
            noCache: true,
            onSelect: ctx_select.fn.bind(ctx_select),
            deferRequestBy: 350,
            minChars: 1
          })
      })

  }


  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Query Interface
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // querySelect: Select an autocomplete suggestion
  //
  // This function is a devbridge autocomplete callback that is called when
  // the user has selected one of the suggestions on offer.
  //
  // The callback context has been shaped by Function.prototype.bind()
  //
  querySelect(suggestion) {
    let ctxt = this
    let $query = $(ctxt.query)

    //
    // If the suggestion resolves to a specific profile_id, then submit a new
    // query to fetch the full profile.

    if (suggestion.data.profile_id) {
      let $form = $query.parents('form').first()
      let qdata = { query: suggestion.data.email }

      //
      // Query response handler: check that a profile is defined, and
      // use its fields to populate the form.
      let done_fn = function (ctxt) {
        return function (data, status, xhr) {
          let $query = $(ctxt.query)
          let $form = $query.parents('form').first()
          let resp = xhr.responseJSON

          if (resp.profile) {
            ctxt.stim.populateProfile($form, $query, resp.profile)
          }
        }
      }
      let fail_fn = function (ctxt) {
        return function (xhr, status, error) {
          // debugger
        }
      }

      $.ajax({
        type: 'POST',
        url: $query.data('query-url'),
        data: $.param(qdata),
        dataType: 'json'
      }).then(done_fn(ctxt), fail_fn(ctxt))
    }
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  queryInvalidate() {
    let ctxt = this
    let $query = $(ctxt.query)
    let $form = $query.parents('form').first()
    $('[data-target~="query.selected"]', $form).prop('hidden', true)
    $('[data-target~="query.deselected"]', $form).prop('hidden', false)
    ctxt.stim.depopulateProfile($form, $query)
  }

  //////////
  //  Callback for input selections that coinside with possible large sets
  // of options in a select
  query_searchSelect(suggestion) {
    if (suggestion.data) {
      let suggested_id = suggestion.data.toString().trim()
      let form = $(this.query).parents().find('form[data-auto-complete-submit="true"]')
      let select_property = $(':input', form)
      if (select_property.val() != suggested_id) {
        let current_option_val = select_property.val()
        //let previous_selected_option = select_property.find(`option[value="${current_option_val}"]`)
        //if(previous_selected_option.length){previous_selected_option.attr('checked','') }
        select_property.val(suggested_id)
        //let suggested_option = select_property.find(`option[value="${suggested_id}"]`)
        //if(suggested_option.length){suggested_option.attr('checked','checked') }
        form.submit()
      }
    }
  }



  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // killpress: filters keydown events to prevent input of Space characters.
  // All we _really_ want to do is ignore trailing whitespace; but the devbridge widget
  // cannot be configured to do that.
  //
  killpress(svt) {
    // console.log(`query#killpress: (${svt.code}), `, svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    if (svt.code === 'Space') {
      svt.stopImmediatePropagation()
      svt.preventDefault()
      return false
    }
    return true
  }

  //
  // Ensure that leading or trailing whitespace is expunged from input value,
  // even if pasted.
  //
  trim(svt) {
    // console.log(`query#trim:`, svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $query = $(ctxt.query)
    let qv = $query.val()
    // console.log(`query#trim: ('${qv}'), ${qv !== qv.trim()}:`, svt)
    if (qv !== qv.trim()) {
      // svt.stopImmediatePropagation()
      // svt.preventDefault()
      $query.val(qv.trim())
      // return false
    }
    return true
  }



  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Local support functions
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // populateProfile: populate form profile fields using a profile specification returned
  // by an exact-match query.
  //
  populateProfile($form, $query, profile) {
    let stim = this
    // console.info('Populate form with profile', profile)
    stim.clearFeedback($form)
    $query.toggleClass('is-valid', true)
    $('[data-target~="query.selected"]', $form).prop('hidden', false)
    $('[data-target~="query.deselected"]', $form).prop('hidden', true)
    let qname = $query.attr('name')

    //
    // Run through the profile parameters....
    for (let n in profile) {
      let name = `[profile_attributes][${n}]`
      //
      //
      if (!qname.endsWith(name)) {
        let $input = $(`:input[name$="${name}"]`, $form)
        if ($input.hasClass('flatpickr-input')) {
          $input = $input.siblings('.form-control.input')
        }

        if ($input.length) {
          $input.last().val(profile[n])
          if ($input.last().attr('type') === 'checkbox') {
            $input.last().prop('checked', profile[n] !== '0').trigger('change')
          }
        }
      }
    }

    $('[data-target~="query.href"]', $form).filter('[href]').each(function (l, link) {
      let $link = $(link)
      let href = $link.attr('href').replace(/[0-9]+$/, profile.profile_id)
      $link.attr('href', href)
    })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // De-populate form profile fields after query changes away from an exact match.
  //
  depopulateProfile($form, $query) {
    let stim = this
    // console.info('De-populate form profile because', $query.val())
    if ($query.hasClass('is-valid')) {
      let exclude = $query.attr('name')
      let family = $query.data('query-family') || ''
      let $family = $(`:input[name*="${family}"]`, $form).not(`[name="${exclude}"]`)
      //
      // Aaaaargh: really do not want to be doing this.
      $family.not('[data-dflt]').val(null)
      $family.filter('[data-dflt]').each(function (e, elem) {
        let $elem = $(elem)
        let dflt = $elem.data('dflt')
        $elem.not('[type="checkbox"]').val(dflt)
        $elem.filter('[type="checkbox"]').val(dflt).prop('checked', dflt === 1).trigger('change')
      })

      $query.toggleClass('is-valid', false)
    }
    stim.clearFeedback($form)
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  clearFeedback($form) {
    $form.find('div.form-feedback,div.alert-dismissable').remove()
    $(':input[name]', $form).toggleClass('is-valid', false).toggleClass('is-invalid', false)
  }

}
