// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [
        'table',
        'td_maint',
        'div_edit',
        'div_save',
        'tr',
        'i',
        'i_edit',
        'i_save',
        'i_reset'
    ]


    initialize() {
        let stim = this
        let iTargets = stim.iTargets
        let i_editTargets = stim.i_editTargets
        let i_saveTargets = stim.i_saveTargets
        let i_resetTargets = stim.i_resetTargets
        let div_saves = stim.div_saveTargets

        $(div_saves).each(function (i, div_saveTarget) {
            let label = $(div_saveTarget).find('label');
            label.addClass("d-none")
        })

        $(iTargets)
            .css({
                'cursor': 'pointer',
                // 'color': 'green'
            })

        $(i_saveTargets).each(function (i, i_saveTarget) {
            let div_save = $(this).parents().eq(2).find('td[data-target="resident-notice.td_edit"]').find('div[data-target="resident-notice.div_save"]');
            let form_submit = div_save.find('form :submit');

            $(i_saveTarget).on('click', this, function () {
                $(form_submit).click();
            })
        })

        $(i_editTargets).each(function (i, i_editTarget) {
            let div_edit_btns = $(this).parents().eq(1).find('div[data-target="resident-notice.div_edit"]');
            let div_save_btns = $(this).parents().eq(1).find('div[data-target="resident-notice.div_save"]');
            let div_display = $(this).parents().eq(2).find('td[data-target="resident-notice.td_edit"]').find('div[data-target="resident-notice.div_display"]');
            let div_save_form = $(this).parents().eq(2).find('td[data-target="resident-notice.td_edit"]').find('div[data-target="resident-notice.div_save"]');
            $(i_editTarget).on('click', this, function () {
                toggle_display([div_edit_btns, div_save_btns, div_save_form, div_display]);
            })
        })

        $(i_resetTargets).each(function (i, i_resetTarget) {
            let div_edit_btns = $(this).parents().eq(1).find('div[data-target="resident-notice.div_edit"]');
            let div_save_btns = $(this).parents().eq(1).find('div[data-target="resident-notice.div_save"]');
            let div_display = $(this).parents().eq(2).find('td[data-target="resident-notice.td_edit"]').find('div[data-target="resident-notice.div_display"]');
            let span_display = $(div_display).find('span[data-target="resident-notice.span_display"]');
            let div_save_form = $(this).parents().eq(2).find('td[data-target="resident-notice.td_edit"]').find('div[data-target="resident-notice.div_save"]');
            let input_text = $(div_save_form).find('input[name="resident_notice_translation[value]"]');
            $(i_resetTarget).on('click', this, function () {
                toggle_display([div_edit_btns, div_save_btns, div_save_form, div_display]);
                input_text.val(span_display.html());
            })
        })

        function toggle_display(elements) {
            for (let i in elements) {
                $(elements[i]).toggleClass("d-none");
            }
        }
    }


    connect() {
        let stim = this;

        $(stim.tableTargets).each(function (i, table) {
            let $table = $(table)
            let api = $table.DataTable({
                scrollY: '66vh',
                scrollCollapse: true,
                paging: false,
                stateSave: true,
                searching: false,
                stateDuration: 60 * 60 * 365,
                columnDefs: [{ type: 'natural-nohtml', targets: '_all' }]
            })

            //
            // Cue column-adjust actions whenever a collapsable panel or tab has opened.
            $('div.ez-interior')
                .on('shown.bs.collapse', api, stim.adjust)
                .on('shown.bs.tab', api, stim.adjust)
            $(window).on('pageshow', api, stim.adjust)
        })
    }




    //
    // This handler is called to adjust table columns after a tab or collapsable panel
    // has opened. Without this, nothing lines up.
    adjust(evt) {
        // console.log('dt-embed#adjust:', evt)
        evt.data.columns.adjust()
        return true
    }


}
