// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from "stimulus";

// This js will poll a server to know
// when the automatic session timeout happens.
// When there is 90 seconds or less it will popup a modal
// That will countdown 10 seconds allowing you to logout or
// continue (page refresh).  After the countdown you will be logged out
// modal target: has the id of the modat
// seconds_spam: placeholder of the seconds


// every fifteen minutes, more-or-less
const sessionTimeoutPollFrequency = 901;
const secondUpdateFrequency = 1;


//

export default class extends Controller {
    static targets = [
        'modal', 'seconds_span', 'continue_button'
    ]

    initialize() {
        let stim = this
        //console.log `Loaded timeout is ${sessionTimeoutPollFrequency}`

        window.TimeOutVars = {}
        TimeOutVars.poll_count = 0
        TimeOutVars.seconds = 0
        TimeOutVars.session_modal_id = $(stim.modalTarget).attr('id')
        TimeOutVars.session_seconds_span_id = $(stim.seconds_spanTarget).attr('id')

        window.setTimeout(function () { stim.pollForSessionTimeout(stim) }, (sessionTimeoutPollFrequency * 1000));


        $(stim.continue_buttonTargets).each(function (b, button) {
            let $button = $(button)
            $button.on('click', stim, function () {
                clearTimeout(TimeOutVars.seconds_update_id);
                location.reload();
            })
        })
    }

    pollForSessionTimeout(stim) {
        $.ajax({
            type: 'get',
            url: '/check_session_timeout',
            success: function (data, status, xhr) {
                //console.log(data.ttl_to_timeout)
                if (data.ttl_to_timeout <= 90) {
                    TimeOutVars.seconds = 11
                    window.setTimeout(function () { stim.updateSecondsInModal(stim) }, (secondUpdateFrequency * 1000));
                    $('#' + TimeOutVars.session_modal_id).modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                }
            },
            error: function (data, status, xhr) {
                console.log('Error polling for session!!!!!!')
            }
        })

        TimeOutVars.poll_count++
        //console.log `Im polling now this many times: ${TimeOutVars.poll_count}`
        window.setTimeout(function () { stim.pollForSessionTimeout(stim) }, (sessionTimeoutPollFrequency * 1000));

    }

    updateSecondsInModal(stim) {
        TimeOutVars.seconds--
        if (TimeOutVars.seconds <= 0) {
            window.location = '/session_timeout'
        } else {
            //debugger
            $('#' + TimeOutVars.session_seconds_span_id).html(TimeOutVars.seconds)
            TimeOutVars.seconds_update_id = window.setTimeout(function () { stim.updateSecondsInModal(stim) }, (secondUpdateFrequency * 1000));
        }
    }
}