// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// NQA - No Questions Asked!
//
// This controller provides the ability to request some arbitrary URL but not give a damn
// about its content or whether the request was even successful.
//
// It is typically used by buttons to trigger server-side actions: firing off some email,
// for example, or shooting somebody's password.
//
// RS3026: NQA requests should always report something as feedback
// Wow - for something that was originally meant to be purely Fire-and-Forget, it has since
// become something special, with the advent of the self-generated ACK modal and its embedded
// JSON browser.
//
// Eeeesh, and all because the lady loves to whine.
// (https://www.youtube.com/watch?v=3DWt39zk00I)
//
import { Controller } from 'stimulus'
import JSONFormatter from 'json-formatter-js'

const flatten = require('flat').flatten

const nqack_id = 'nqa-ack-container'


export default class extends Controller {
  static targets = ['shoot']   // A shoot button

  connect() {
    console.info("NQA CONNECT")
    let stim = this
    let ack_needed = false

    $(stim.shootTargets)
      .filter('[data-shoot-url]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let ctxt = { stim: stim,
                      btn: btn,
                      url: $btn.data('shoot-url'),                // URL to shoot at
                      method: $btn.data('shoot-with') || 'get',   // Method to use when shooting
                      confirm: $btn.data('shoot-confirm') || '',  // Confirm with this text before pulling the trigger
                      modal: $btn.data('shoot-modal'),            // Pop this modal [ID] before pulling the trigger...
                      ack: $btn.data('shoot-ack') || '',          // OR pop an internally-generated ACK modal with this text as header to show progress and results.
                      wait: $btn.data('shoot-wait'),              // ACK only: Wait for user to dismiss the Ack modal (boolean)
                      explain: $btn.data('shoot-explain' || ''),  // ACK only: Text that explains what is shown on the ACK modal
        }
        //
        // Make sure that we have an ack container built if we need to
        // acknowledge this shot.
        //
        if (ctxt.ack.length && $(`#${nqack_id}`).length < 1) {
          let html = `
            <div id="${nqack_id}" class="modal fade shadow-lg" tabindex="-1" role="dialog">
              <div class="modal-dialog modal-md modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title"></h5>
                    <div class="spinner-border text-secondary mr-3"><span class="sr-only">Loading...</span></div>
                    <i class="material-icons close" class="close" data-dismiss="modal">cancel</i>
                  </div>
                  <div class="modal-body">
                    <div class="col-12 mb-2 p-2 border explain"></div>
                    <div class="spinner"></div>
                    <div class="scrollify scrollify-lg small"></div>
                  </div>
                <div class="modal-footer">
                  <div class="col-12 mb-3">
                    <div class="progress">
                      <div class="progress-bar bg-secondary" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button type="button" class="btn btn-outline-dark rounded-lg float-right" data-dismiss="modal"><tt class="text-monospace">[Esc]</tt> Dismiss</button>
                  </div>
                </div>
                </div>
              </div>
            </div>`
          // $('div.ez-interior').append(html).modal({ show: false, keyboard: true })
          $('body').append(html).modal({ show: false, keyboard: true, backdrop: false })
        }
        //
        // Bind an event handler to the button.
        $btn.on('click', ctxt, stim.shoot)
      })
  }

  shoot(svt) {
    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        console.log('nqa#shoot#done', status, xhr, data)
        // debugger
        if (ctxt.ack.length) {
          stim.ack(stim, ctxt, status, resp)
        }
      }
    }

    let fail_fn = function (stim, ctxt) {
      return function (xhr, status, data) {
        let resp = xhr.responseJSON
        console.warn('nqa#shoot#fail', status, xhr, data)
        // debugger
        if (ctxt.ack.length) {
          stim.ack(stim, ctxt, status, resp)
        }
      }
    }
    console.log('nqa#shoot:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this

    //
    // Post a confirmation dialog if such has been requested.
    if (ctxt.confirm.length > 0) {
      if (!window.confirm(ctxt.confirm)) {
        svt.stopImmediatePropagation()
        svt.preventDefault()
        return false
      }
    }

    if (ctxt.method == 'get') {
      $.get({ url: ctxt.url }).then(done_fn(stim, ctxt), fail_fn(stim, ctxt))
    }
    else {
      $.post({ url: ctxt.url, data: '{}' }).then(done_fn(stim, ctxt), fail_fn(stim, ctxt))
    }

    if (ctxt.modal) {
      let $modal = $(ctxt.modal)
      if ($modal.length) {
        svt.stopImmediatePropagation()
        svt.preventDefault()
        $modal.modal('show')
      }
    }
    //
    // If an Ack is required, then pop the its [internally-constructed] modal
    else if (ctxt.ack.length) {
      let $ack = $(`#${nqack_id}`)
      if ($ack.length) {
        svt.stopImmediatePropagation()
        svt.preventDefault()
        $('.modal-title', $ack).text(ctxt.ack)
        $('.explain', $ack).empty().html(ctxt.explain)
        $('.scrollify', $ack).empty()
        $('.progress', $ack).hide()
        $('.spinner-border').css('animation-duration', '.7s').show()
        $ack.modal({ show: true, backdrop: false })
        .one('shown.bs.modal', function () { $(ctxt.btn).addClass('active')})
        .one('hidden.bs.modal', function () { $(ctxt.btn).removeClass('active')})
      }
    }
    return true
  }

  //
  // ack: Acknowledge request completion.
  //
  // NOTE:  This is NOT a stimulus target handler!
  //        It is _used_ by target handler.
  ack(stim, ctxt, status, resp) {
    let $ack = $(`#${nqack_id}`)
    let $content = $('.modal-body', $ack)
    let $explain = $('.explain', $content)
    let $scrollbox = $('.scrollify', $content)

    //
    // Determine the maximum depth at which a _named_ object is to be found
    // within the argument.
    //
    // It works by flattening the object, and counting the dots within the
    // flattened keys: but only after removing any enumerated parts of the keys.
    //
    // e.g. { bob: { fred: [ 'str0', 'str1' ] } } would flatten to bob.fred.0.0
    //      and bob.fred.1.0, but _we_ only care about counting bob.fred
    //
    function named_object_depth (obj) {
      if (typeof obj !== "object" || obj === null) { return 0 }

      //
      // flatten the object and extract its keys...
      const flat = flatten(obj)
      const keys = Object.keys(flat)
      if (keys.length === 0) { return 1 }

      //
      // Rewrite the key names to eliminate numeric indeces that represent
      // array elements: we only care about alpha fields.
      const key_strip = keys.map(key => key.replace(/[.]\d+/g, ''))

      //
      // Map the keys into an array of depth values, where each counts
      // the dots in a given key.
      const key_depths = key_strip.map(key => key.split(".").length)

      //
      // Search the results for the maximum depth value
      return Math.max(...key_depths)
    }

    //
    // Require a JSON response that contains either a 'notify' property or an 'errors' property.
    if (resp !== null && $ack.length > 0 && (resp.hasOwnProperty('notify') || resp.hasOwnProperty('errors'))) {
      let all_ok = status === 'success'
      let total = 0
      let messages = all_ok ? resp['notify'] : resp['errors']
      let depth = named_object_depth(messages)

      console.log(`nqa#shoot#ack(${status}[${depth}]):`, stim, ctxt, resp)
      // debugger

      $('.spinner-border', $ack).hide()
      // $explain.html(ctxt.explain)
      $scrollbox.empty()

      //
      // If the response contains an 'explain' string, then we use that to overwrite
      // any prior static explanatory text that came from the shoot target. Just a useful way
      // to provide a better explanation of what these results mean.
      if (resp.hasOwnProperty('explain') && typeof resp['explain'] === 'string' && resp['explain'].length > 0) {
        $explain.html(resp['explain'])
      }

      //
      // Message object is DEEP, and contains at least two levels
      // of object depth.
      //
      if (depth > 1) {
        try {
          const formatter = new JSONFormatter(messages, 0)    // Infinity for everything to be opened
          $scrollbox.html("<h6>Click below for more information...</h6>")
          $scrollbox.append(formatter.render())
        }
        catch (error) {
          $scrollbox.append(stim.dismissable(`JS> nqa#shoot#ack(${status}[${depth}]): ERROR ${error}`, 'danger'))
          total += 1
        }
      }
      //
      // Message object is FLAT - strings or arrays only.
      // These we should render into the scrollbox as simple dismissables.
      else if (depth == 1) {
        let keys = Object.keys(messages).sort().reverse()
        if (keys.length) {
          keys.forEach(function (n, i) {
            let message = messages[n]
            let msgary = Array.isArray(message) ? message.flat() : [ message ]

            //
            // Translate the key into one of bootstrap's theme names.
            let cat = ['danger', 'warning', 'info', 'success', 'light', 'dark'].includes(n) ? n : 'light'
            //
            // Account for the fact that our rails controllers do not always [ever!] work that way...
            cat = n === 'notice' ? 'info' : cat
            cat = n === 'alert' ? 'danger' : cat
            //
            // Check for blocking categories -- these will cancel timed dismissal
            all_ok = ['danger', 'warning'].includes(cat) ? false : all_ok

            for (let m in msgary) {
              let msg = msgary[m]

              $scrollbox.append(stim.dismissable(msg, cat))
              total += 1
            }
          })
        }
        else {
          $scrollbox.append(stim.dismissable('Nothing further at this time.', 'info'))
          total += 1
        }
      }
      //
      // Message object is EMPTY - nothing to see here, folks.
      else {
        debugger
        console.warn(`nqa#shoot#ack(${status}): Nothing to report`)
        $ack.modal('hide')
    }

      //
      // First we post any messages...

      //
      // If nothing bad was reported then put the modal on a timer, with
      // a progress bar until aoto-dismiss.
      if (!ctxt.wait && all_ok && (total > 0 && total < 5)) {
        let $progress = $('.progress-bar', $ack)
        let intvl = 5000 / 10
        let progress = 0
        let tracker = setInterval(function () {
          $('.progress', $ack).fadeIn()
          $progress.css('width', progress + '%').text(`${progress}%`)
          progress = progress + 10
          if (progress > 100) {
            clearInterval(tracker)
            $ack.modal('hide')
          }
        }, intvl)
      }
    }
    else {
      console.error(`nqa#shoot#ack(${status}): FAIL - ${resp ? 'no JSON response present' : ($ack.length ? "missing 'notify' or 'errors' property" : "Ack modal missing!")}`, stim, ctxt, resp)
      // debugger
      $ack.modal('hide')
    }

    return true
  }

  dismissable(msg, cat) {
    let html = `<div class="alert alert-dismissable fade show alert-${cat}" role="alert">` +
    `<span>${msg}</span>` +
    '<button class="close" type="button" data-dismiss="alert" aria-label="close">' +
    '<span aria-hidden="true"><i class="md-glyph">close</i></span></button></div>'

    return html
  }


} // End of module exports
