import * as shared_functions from "../shared/shared_functions";

export function handle_payments_message(data) {
  if ($("#action_cable_div").length > 0) {
    shared_functions.display_messages(data, "errors", "action_cable_div");

    shared_functions.display_messages(
      data,
      "messages",
      "action_cable_div",
      true
    );

    // check if the data object has a datatable_id key and
    // if it does attempt to reload the datatable
    if (data.hasOwnProperty("datatable_id")) {
      shared_functions.refresh_datatable(data.datatable_id);
    }
  }
}
