// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
// Stimulus-based interface to clipboard.js
import { Controller } from 'stimulus'
import Clipboard from 'clipboard'

export default class extends Controller {
    static targets = ['text', 'btn']

    initialize() {
        console.info("CLIP INITIALIZE")
    }

    connect() {
        console.info(`CLIP CONNECT: ext(${$(this.textTargets).length}), btn(${$(this.btnTargets).length})`)

        let stim = this
        let ctxt = { stim: stim, outlines: ['btn-outline-success', 'btn-outline-danger'] }
        let success_fn = function (stim) { return function (evt) { return stim.clipboard_success(evt, ctxt) } }
        let error_fn = function (stim) { return function (evt) { return stim.clipboard_error(evt, ctxt) } }

        $(this.textTargets)
            .addClass('clip-text')
            .on('change', ctxt, stim.clear)

        $(this.btnTargets)
            .addClass('clip-btn')
            .toggleClass(ctxt.outlines, false)
            .toggleClass('btn-outline', true)

        //
        // Create the clipboard instance, and attach it to our own
        // success/failure handlers. Those will be called with non-standard
        // event arguments, as produced by the clipboard module.
        this.clipboard = new Clipboard('button.clip-btn')
        this.clipboard
            .on('success', success_fn(stim))
            .on('error', error_fn(stim))
    }

    //
    //
    clear(svt) {
        let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
        let stim = (ctxt) ? ctxt.stim : this
        console.info('clip#clear:', svt, ctxt)
        $('.clip-btn')
            .toggleClass(ctxt.outlines, false)
            .toggleClass('btn-outline', true)
    }

    //
    //
    copy(evt) {

    }

    //
    // Clipboard event handlers.
    //
    // These handlers respond to non-standard events raised by the clipboard
    // widget to indicate success or failure. They are non-standard chiefly
    // because the event descriptor ('evt') is not a standard JQ event descriptor,
    // but something that the clipboard widget has produced itself.
    //
    // These handlers simply ensure that any selected text is de-selected, and
    // that the trigger buttons are classed to show which one succeeded or failed.
    clipboard_success(evt, ctxt) {
        console.info('Clipboard SUCCESS', evt, ctxt)
        evt.clearSelection()
        $('button.clip-btn').toggleClass(ctxt.outlines, false)
        $(evt.trigger).toggleClass('btn-outline-success', true)
    }

    clipboard_error(evt, ctxt) {
        console.error('Clipboard FAILURE', evt, ctxt)
        $('button.clip-btn').toggleClass(ctxt.outlines, false)
        $(evt.trigger).toggleClass('btn-outline-danger', true)
    }
}
