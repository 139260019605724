// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
/// Cluster multiselect js ///
import { Controller } from 'stimulus'
import 'jquery-ui'
import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/ui/disable-selection'
import 'jquery-ui-touch-punch'

export default class extends Controller {
    static targets = ['multi_select_form', 'multi_select_modal']

    connect() {
        //debugger
        console.info("Multi-select CONNECT")
        let stim = this

        $(stim.multi_select_modalTargets).each(function (f, modal) {
            console.info("Multi-select CONNECT modal")
            $(modal).on('hidden.bs.modal', stim, stim.refresh_form)
            $(modal).on('show.bs.modal', stim, stim.load_multi_form)
        })

    }

    set_up_multiselect(svt, form_container) {
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        // setup parent form
        let form = form_container.find('form')

        $(form).on('submit', function () {
            $('#hidden_inputs').empty()
            let selected_node_ids = []
            let selected_nodes_li = $('.selectedDropzone li')
            selected_nodes_li.map(function () {
                selected_node_ids.push($(this).data('fid'));
            });
            let hidden_input = $("<input type='hidden'/>")
                .attr("name", "selected_nodes")
                .val(JSON.stringify(selected_node_ids))
            $('#hidden_input_div').append(hidden_input);
        })

        let mainWrapper = '.dragSortableItems',
            in_available_fields = '.in_available_fields',
            selectedDropzone = '.selectedDropzone',
            input_name = 'name';

        const dragableMultiselect = $('.dragableMultiselect');

        dragableMultiselect.length && dragableMultiselect.each((index, value) => {
            const $this = $(value);

            const available_fields = $.extend({}, $this.data('options'));
            const selected_fields = $.extend([], $this.data('selected'));
            const $input_name = $this.attr(input_name);
            let fieldTitle = $this.data('field-title');
            let selectedTitle = $this.data('selected-title');

            let html = `<div class="row dragSortableItems dragSortableItem_${index}">
                <div class="col-sm-6">
                <div class="card">
                    <div class="card-header">${fieldTitle}</div>
                    <div class="card-body">
                    <ul class="in_available_fields custom-scrollbar sortable-list fixed-panel ui-sortable"></ul>
                    </div>
                </div>
                </div>
                <div class="col-sm-6">
                <div class="card primaryPanel">
                    <div class="card-header">${selectedTitle}</div>
                    <div class="card-body h-100">
                    <div class="alert alert-warning small text-center mb-0">No Nodes Selected</div>
                    <ul class="in_primary_fields sortable-list selectedDropzone fixed-panel h-100"></ul>
                    </div>
                </div>
                </div>
            </div>`


            $this.replaceWith(html);
            let $dragSortableItem;
            $dragSortableItem = $('.dragSortableItem_' + index);

            let $mainWrapper = $dragSortableItem.closest(mainWrapper),
                $in_available_fields = $mainWrapper.find(in_available_fields),
                $selectedDropzone = $mainWrapper.find(selectedDropzone);

            console.log('Multi')
            console.log(available_fields, selected_fields, $mainWrapper, $in_available_fields, $selectedDropzone, $input_name);

            Object.keys(available_fields).forEach(function (key) {
                var item = '<li class="sortable-item allowPrimary sortable-item-' + key + '" data-fid="' + key + '">'
                    + '<span class="icon-drag fas fa-grip-vertical mr-2"></span>'
                    + '<input type="checkbox" name="' + $input_name + '" class="sortable-item-input"/>'
                    + available_fields[key]
                    + '</li>';
                $in_available_fields.append(item);
            });

            selected_fields.map(function (index) {
                var item = $in_available_fields.find('.sortable-item-' + index);
                item.find('.sortable-item-input').prop('checked', true);
                $selectedDropzone.append(item);
            });
            checkFields($mainWrapper);

            // Set up our dropzone
            $in_available_fields.sortable({
                connectWith: '.sortable-list',
                placeholder: 'placeholder',
                start: function (event, ui) {
                    if (!$(ui.item).hasClass("allowPrimary")) {
                        $mainWrapper.find(".primaryPanel").removeClass('panel-primary').addClass("panel-danger");
                    }
                    checkFields($mainWrapper)
                },
                receive: function (event, ui) {
                    $(ui.item).find('.sortable-item-input').prop('checked', false);
                },
                stop: function (event, ui) {
                    if (!$(ui.item).hasClass("allowPrimary")) {
                        $mainWrapper.find(".primaryPanel").removeClass("panel-danger").addClass('panel-primary');
                    }
                },
                change: function (event, ui) {
                    checkFields($mainWrapper);
                },
                update: function (event, ui) {
                    checkFields($mainWrapper);
                },
                out: function (event, ui) {
                    checkFields($mainWrapper);
                }
            }).disableSelection();

            // Enable dropzone for primary fields
            $selectedDropzone.sortable({
                connectWith: '.sortable-list',
                placeholder: 'placeholder',
                receive: function (event, ui) {
                    // If we dont allow primary fields here, cancel
                    if (!$(ui.item).hasClass("allowPrimary")) {
                        $(ui.placeholder).css('display', 'none');
                        $(ui.sender).sortable("cancel");
                    } else {
                        $(ui.item).find('.sortable-item-input').prop('checked', true);
                    }
                },
                over: function (event, ui) {
                    if (!$(ui.item).hasClass("allowPrimary")) {
                        $(ui.placeholder).css('display', 'none');
                    } else {
                        $(ui.placeholder).css('display', '');
                    }
                },
                start: function (event, ui) {
                    checkFields($mainWrapper)
                },
                change: function (event, ui) {
                    checkFields($mainWrapper);
                },
                update: function (event, ui) {
                    checkFields($mainWrapper);
                },
                out: function (event, ui) {
                    checkFields($mainWrapper);
                }
            }).disableSelection();
        });

        // Checks to see if the fields section has fields selected. If not, shows a placeholder
        function checkFields($this) {
            if ($this.find(selectedDropzone).find('li').length >= 1) {
                $this.find('.primaryPanel').find('.alert').hide();
            } else {
                $this.find('.primaryPanel').find('.alert').show();
            }
        }
    }



    refresh_form(svt) {
        let key_name = $(this).data('key')
        let key = $(`select[name*="${key_name}"]`)
        key.trigger('change')
    }

    load_multi_form(svt) {
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        let key_name = $(this).data('key')
        let key = $(`select[name*="${key_name}"]`)
        let multi_select_url = $(this).data('multiselect-url')
        let form_container = $(this).find('.modal-body')
        $.ajax({
            type: 'POST',
            url: multi_select_url,
            data: { 'multi_key_id': key.val() },
            success: function (data, status, xhr) {
                form_container.html(data)
                stim.set_up_multiselect(stim, form_container)
            },
            error: function (data, status, xhr) {
                alert("Error please contact our offices!!!")
            }
        })

    }

}