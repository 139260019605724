// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Warning - display modal warning.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['what']

  connect() {
    let stim = this
    console.info("WARNING CONNECT", stim)
  }

  warn(event) {
    let $input = $(this.whatTargets)
    let $val = $input.val()
    console.log("Warning event", $val)
    let found = $val.find(elem => elem === "resident_services")
    console.log("Warning event, found: ", found === 'resident_services')
    if (!found) {
      let warning_modal = $('#warningModal')
      warning_modal.modal('show')
    }
  }
}
