// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side Wi-Fi Password (DPSK) Editor
//
// This stimulus controller deals with the tricksy problem of a resident editing their
// Wi-Fi password. The 'complexity' arises from the fact that legitimate Wi-Fi passwords
// were composed of a fixed and invariant prefix followed by a user-defined passphrase,
// but now are composed of by a user-defined passpharase followed by a fixed suffix.
//
// That means that new password inputs must be split into those two parts and users permitted
// to change only the first part. But they must also be shown the full composite password
// elsewhere, updated as they type, so that they are fully aware of the final password form
//
// I apologize for the decoherence of this module. It was the first stimulus controller
// that I wrote from scratch, and I really did not know how things were meant to hang together.
// Too much of a PITA to rewrite though - maybe some day.
//
import { Controller } from 'stimulus'

//
// Regular expression of unacceptable password characters.
// Defined in terms of containing any characters NOT in the legitimate alphabet.
const reBADC = /[^A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{\|}~\s]/    // characters disallowed in DPSK
const reBADS = /\s{2,}/

export default class extends Controller {
    static targets = ['modal', 'form', 'prefix', 'suffix', 'show_password', 'hidden_dpsk', 'hidden_password', 'submitter', 'notify', 'message', 'hidden_excluded']

    initialize() {
        console.info("DPSK INITIALIZE")
        $(this.modalTargets).on('shown.bs.modal', this, this.shown)
    }

    //
    // update: called in response to changes to password input
    //
    // Its purpose is to strip extraneous whitespace from the user phrase,
    // to determine whether the composite password is valid or otherwise, and to
    // rewrite composite password into notification sites.
    update(svt) {
        // console.info("dpsk#update", svt)
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        let $modal = $(svt.currentTarget).parents('div[data-target~="dpsk.modal"]')
        if (!$modal.length) {
            $modal = $(svt.currentTarget)
        }
        let $show = $('[data-target~="dpsk.show_password"]', $modal)
        let $passwd = $('[data-target~="dpsk.hidden_password"]', $modal)
        let $notify = $('[data-target~="dpsk.notify"]', $modal)
        let $message = $('[data-target~="dpsk.message"]', $modal)
        // token_suffix is the new suffix pin/token suffix that's not editable by resident
        let $token_suffix = $('[data-target~="dpsk.token_suffix"]', $modal)
        let $passwd_prefix = $('[data-target~="dpsk.passwd_prefix"]', $modal)
        // debugger

        // Token suffix can be the pin_code or a longer Vault token
        // debugger
        let raw1 = $passwd_prefix.val()
        let cut1 = raw1.replace(/\s{2,}/g, ' ')     // stripped of whitespace while typing
        let word1 = cut1.trimRight()                    // stripped of trailing whitespace
        let word2 = $token_suffix.html()
        let new_password = word1 + word2
        let is_valid = (new_password.length >= 8 && new_password.length <= 62 && !reBADC.test(new_password) && !reBADS.test(new_password))              // Valid if 8 or more chars
        if (cut1 != raw1 && svt.type !== 'input') {
            $passwd_prefix.val(cut1)
        }
        $passwd_prefix.toggleClass('is-valid', is_valid).toggleClass('is-invalid', !is_valid)

        $show.html(new_password)
        $passwd.val(new_password)
        $notify.html(new_password)
        $message.empty()
    }

    //
    // validate: called when a password form is hit with 'submit'
    //
    // Its purpose is to validate the new password, and either allow form
    // submission to proceed or prevent submission in the event of failure.
    validate(svt) {
        // console.info('dpsk#validate', svt)
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        let $modal = $(svt.currentTarget).parents('[data-target~="dpsk.modal"]')

        let $user_passwd = $('[data-target~="dpsk.passwd_prefix"]', $modal)
        let $suffix = $('[data-target~="dpsk.token_suffix"]', $modal)
        let $message = $('[data-target~="dpsk.message"]', $modal)
        // list of wifi passwords that's excluded/not-allowed
        let $excluded = $('[data-target~="dpsk.hidden_excluded"]', $modal)

        //
        // All tests must apply to the trimmed user component.
        // We do not permit leading, trailing, or consecutive spaces!
        let user_part = $user_passwd.val().trim()

        let len = user_part.length
        let full_len = len + $suffix.text().length

        //
        // Callback function for Array.filter() that will exclude duplicates.
        // Used to produce a list of characters in a DPSK that do not satisfy
        // ruckus criteria.
        function uniq(value, index, self) {
            return self.indexOf(value) === index
        }

        if (full_len < 8 || full_len > 62) {
            svt.stopImmediatePropagation()
            svt.preventDefault()

            $user_passwd.removeClass('is-valid').addClass('is-invalid')
            $message
                .removeClass('valid-feedback')
                .addClass('invalid-feedback')
                .text(len < 8 ? gon.dpsk_controller.short_password_validation : gon.dpsk_controller.long_password_validation)
            return false
        }

        if ($excluded.val().includes(user_part)) {
            svt.stopImmediatePropagation()
            svt.preventDefault()

            $user_passwd.removeClass('is-valid').addClass('is-invalid')
            $message
                .removeClass('valid-feedback')
                .addClass('invalid-feedback')
                .text(gon.dpsk_controller.common_no_password_reuse)
            return false
        }

        //
        // Content validation:
        //
        if (reBADC.test(user_part)) {
            //
            // Create a new, GLOBAL, copy of reBADC so that we can safely matchAll
            // and report a de-duplicated list of offending characters
            //
            // If we had simply assigned /g to reBADC then we would have been forced
            // to reset the regular expression (reBADC.lastIndex = 0) prior to using it.
            // Because global regular expressions are stateful, and that is no good
            // when mixing test with matchAll.
            let greBADC = new RegExp(reBADC, 'g')  // Global variant of same
            let matched = [...user_part.matchAll(greBADC)].map(x => x[0]).filter(uniq)

            // console.log(`dpsk#validate(${user_part}): Failed for ${matched}`)

            svt.stopImmediatePropagation()
            svt.preventDefault()
            $user_passwd.removeClass('is-valid').addClass('is-invalid')
            $message
                .removeClass('valid-feedback')
                .addClass('invalid-feedback')
                .html(`<span>${gon.dpsk_controller.bad_password_validation} <b>${matched.join(', ')}</b></span>`)
            return false
        }

        return true
    }

    //
    // submit:
    //
    // This handler is invoked when a 'submit' button is pressed which isn't
    // really a 'submit' button, but a modal 'OK' button. It triggers a form
    // submission event that will cause submit to occur.
    submit(svt) {
        // console.info('dpsk#submit', svt)
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        // debugger
        if (this.validate(svt)) {
            let $modal = $(svt.currentTarget).parents('[data-target~="dpsk.modal"]')
            let $next = $($modal.data('dpsk-next'))
            let $submitter = $('[data-target~="dpsk.submitter"]', $modal)
            let $form = $('form', $modal)
            let cpasswd = $('input[name="current_password"]', $form).val()
            let npasswd = $('input[name="password"]', $form).val()
            if (cpasswd !== npasswd) {
                // console.log(`CHANGE from '${cpasswd}' to '${npasswd}'`)
                $submitter.trigger("click")
                $next.modal('show')
            }
        }
    }

    //
    // JQ event handler for show.bs.modal, invoked when a modal target
    // has now been shown, and all CSS events are completed.
    //
    // This is a very good opportunity to reset our form inputs so that
    // they match the current state of the DPSK.
    shown(jvt) {
        // console.info('dpsk#shown', jvt)
        let stim = (jvt && jvt instanceof jQuery.Event) ? jvt.data : this
        let $modal = $(jvt.currentTarget)
        let $forms = $('[data-target~="dpsk.form"]', $modal)
        $forms.each(function (f, form) { stim.clear_form($(form), jvt) })
        $('input[data-target~="dpsk.user_passwd"]', $forms).focus()
        return true
    }

    //
    // clear_form:
    //
    // Probably misnamed. It's real purpose is to split the true password into a prefix
    // and a suffix, and to prep the form to show those values correctly.
    //
    // Made more complicated by RS2273, in which password prefixes changed from being unit
    // name to being a random pin code. We must now account for the presence two possible
    // prefixes in the original password, but choose the pin code [the first of two] as
    // the prefix going forward.
    // Made even more complicated by RS2422, part of which involves moving the pin_code to
    // be a suffix of the form user_password.pin_code (for SmartZone and Cloudpath), or
    // user_password.sub_token for Vault.
    clear_form($form, jvt) {
        // console.info('dpsk#clear_form', jvt)
        let stim = (jvt && jvt instanceof jQuery.Event) ? jvt.data : this
        let dpsk = $('[data-target~="dpsk.hidden_dpsk"]', $form).last().val()
        let $token_suffix = $('[data-target~="dpsk.token_suffix"]', $form).last()
        //
        // This data attribute is an array of possible prefixes, of which the first is
        // the one to be used going forward. But we now need to construct a RegExp to
        // use when splitting the original. It will contain patterns for the current prefix,
        // the unit name, and any previous 4-character randomized prefix that may have changed
        // on the unit since the DPSK was last modified.
        let matches = $token_suffix.data('dpsk-match')
        let reP = new RegExp(`(${matches.join('|')})$`)
        // console.log('--- matchset:', matches, reP)
        let suffix = $token_suffix.html()
        if (dpsk.length && suffix.length) {
            let words = dpsk.split(reP)
            // console.log(`--- split: '${words.toString()}' = '${dpsk}'.split(${reP})`)
            let prefix = words[0]
            $('[data-target~="dpsk.user_passwd"]', $form).first().val(prefix)
        }
        stim.update(jvt)
    }
}
