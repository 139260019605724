// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side subscription form handler.
//
// This stimulus controller provides all kinds of useful support for Wi-Fi subscription
// forms: those forms that are used to create or update Wi-Fi SUBSCRIPTIONS and the user
// PROFILES that are associated with them.
//
//
// By convention: stimulus event handlers accept stimulus event arguments ('svt')
// whereas JQ event handlers accept JQ event arguments ('jvt')
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'form',           // A form for a subscription, and perhaps an embedded profile
    'notify',         // A container for post-submit notifications
    'datepicker',     // A form input that must be mapped to a datepicker widget
    'query',          // A form input that queries the server for autoselect options
    'wifi_select',    // A container for a <select> element that chooses Wi-Fi unit
    'wifi_dpsk',      // A form input containing a Wi-Fi DPSK
    'wifi_uname',     // A form input containing a Wi-Fi username
    'email',
    'print',
    'start_service',
    'stop_service']

  connect() {
    let stim = this

    //
    //
    $(stim.formTargets)
      .not('[data-target*="ajax.form"]')
      .on('change input', stim, stim.reveal)
      .on('submit', stim, stim.submit)
      .on('reset', stim, stim.clear)
      .find('div.form-feedback').remove()

    //
    // Query targets: these are form inputs whose changing values can query the database
    // for possible matches or autofill suggestions.
    //
    // We use a devbridge autocomplete widget, with callbacks, to manage this for us.
    $(stim.queryTargets)
      .filter('input[data-query-url]')
      .each(function (q, query) {
        let ctx_invalidate = { stim: stim, query: query, fn: stim.queryInvalidate }
        let ctx_select = { stim: stim, query: query, fn: stim.querySelect }

        $(query).attr('autocomplete', 'new-password').autocomplete({
          serviceUrl: $(query).data('query-url'),
          dataType: 'json',
          noCache: true,
          onInvalidateSelection: ctx_invalidate.fn.bind(ctx_invalidate),
          onSelect: ctx_select.fn.bind(ctx_select),
          deferRequestBy: 350,
          minChars: 1
        })
      })

    //
    // Datepicker targets are inputs that hold a date
    // chosen from a date-picker widget.
    $(stim.datepickerTargets).each(function (i, elem) {
      let $elem = $(elem)
      //
      // Compute today's date, and a string denoting our timezone.
      let today = new Date()
      let tz = 'GMT' + ((-1 * today.getTimezoneOffset()) / 60)

      //
      // Read the stored date value and add midnight and time-zone components
      // to it: but only if a value has indeed been specified.
      let cval = $elem.val() || ''
      cval = cval.trim().length ? cval.trim() + ' 00:00:00 ' + tz : cval

      //
      // Convert the stored date into a JS Date() object. Use today's date
      // if the field was empty.
      let cdate = cval.length ? new Date(cval) : new Date()

      //
      // Compare stored Date() against today's Date(): the lesser of these
      // defines the minimum date that the calendar widget should allow to
      // be picked.
      let limit = cdate.valueOf() < today.valueOf() ? cdate : today
      let min = limit.getFullYear() + '-' + ('0' + (limit.getMonth() + 1)).slice(-2) + '-' + ('0' + limit.getDate()).slice(-2)

      //
      // Create and configure a calendar widget around our input element.
      let api = $elem.flatpickr({
        altFormat: 'm-d-Y',
        altInput: true,
        minDate: min
      })

      //
      // The widgets are accompanied by an external calendar button.
      // It must be hand-hacked to toggle the widget when pressed.
      $elem.parent().find('div.input-group-append')
        .attr('style', 'cursor: pointer')
        .attr('tabindex', '-1')
        .on('click', api, stim.jq_open)

    })

    //
    //
    //
    $(stim.wifi_selectTargets)
      .find('select')
      .first()
      .on('change', stim, stim.change_wifi)

    $(stim.wifi_dpskTargets).each(function(i, elem){
      let $elem = $(elem)
      $elem.on('keypress', function(e){
        if (e.key === 'Enter'){
          $elem.trigger('blur')
        }
      })
      $elem.on('blur', stim, stim.append_suffix)
      })
  }

  new() {
    //  link to the data-url
    //console.info(this.data.get('url'))
    //fetch(this.data.get('url'))
    //  .then(html => {
    //    console.info('LOADED')
    //  })
  }

  edit() {
    //  edit the data-url
    //fetch(this.data.get('url'))
    //  .then(html => {
    //    console.info('LOADED')
    //  })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  email() {
    console.info('EMAIL SUBSCRIPTION')
    var url = $(this.emailTargets).data("url")
    $.get({ url: url })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  print() {
    console.info('PRINT SUBSCRIPTION')
    var url = $(this.printTargets).data("url")
    $.get({ url: url })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  start_service() {
    console.info('STARTING SERVICE')
    var url = $(this.start_serviceTargets).data("url")
    $.post({ url: url })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  stop_service() {
    console.info('STOPPING SERVICE')
    var url = $(this.stop_serviceTargets).data("url")
    $.post({ url: url })
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // Reveal submit buttons when a form input changes
  reveal(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    $('.special-btn.collapse', $form).not('show').collapse('show')
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // Full form submission via JSON
  submit(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    let $inputs = $(':input[name]', $form).toggleClass('is-valid', false).toggleClass('is-invalid', false)
    let postdata = {}


    // Start
    // Find submit button and disable until we get a response
    let $submit_trigger = null
    let original_submit_value = null
    try {
      $submit_trigger = $form.find(':submit')
      original_submit_value = $submit_trigger.val()
      $submit_trigger.prop('disabled', true)
      $submit_trigger.val('.....')
    }
    catch (err) {
      console.log('Error trying to disable submit on form!!!')
      console.log(err)
    }
    // End



    console.log('subscription#submit', stim, $form)
    $form.find('div.form-feedback,div.alert-dismissable').remove()
    $inputs.each(function (i, elem) {
      let $input = $(elem)
      //
      // Ensure any checkboxes are set to '0' or '1' by 'checked'
      if ($input.attr('type') === 'checkbox') $input.val($input.prop('checked') ? '1' : '0')
      postdata[$input.attr('name')] = $input.val()
    })

    //
    ///////////////////////////////////////////////////////////////////////////////////////
    // Ajax Asynchronous Response Closures
    //
    // Here we create two closures to handle asynchronous 'done' and 'fail' callbacks from
    // Ajax. We use closures so that the handlers can access the stimulus controller and
    // the form JQ object.
    //
    // Submit Success:
    //
    // The success response from the controller will be empty. Clean-up the form and post
    // a smiley.
    //
    let done_fn = function (stim, $form) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        console.log('subscription#submit#done', xhr, data)
        // debugger
        if (resp.hasOwnProperty('reload') && resp.reload.length) {
          location = resp.reload
        }
        if (resp.hasOwnProperty('html')) {
          let $html = $(resp.html)
          $form.html($html.html())
        }
        stim.dismissable($form, 'All done.', 'success')
        enable_submit($submit_trigger, original_submit_value)
      }
    }

    //
    // Submit Failure:
    //
    // The response will be a hash of input names to errors. Use those
    // here to to apply the necessary feedback messages.
    let fail_fn = function (stim, $form) {
      return function (xhr, status, error) {
        let resp = xhr.responseJSON
        console.log('subscription#submit#fail', xhr, error)
        // debugger
        for (let f in resp) {
          for (let n in resp[f]) {
            let names = n.replace(/[.]/g, '_attributes.').split('.')
            let name = `${f}[${names.join('][')}]`
            let messages = [resp[f][n]]
            let $input = $(`:input[name$="${name}"]`, $form)
            if ($input.length) {
              if ($input.hasClass('flatpickr-input')) {
                let $sib = $input.siblings('.form-control.input')
                if ($sib.length) $input = $sib
              }
              $input.toggleClass('is-invalid', true)
              stim.feedback($input, messages, 'invalid')
            }
            else {
              stim.dismissable($form, messages, n)
            }
          }
        }
        enable_submit($submit_trigger, original_submit_value)
      }
    }

    let enable_submit = function (submit_trigger, original_submit_value) {
      try {
        submit_trigger.prop('disabled', false)
        submit_trigger.val(original_submit_value)
      }
      catch (err) {
        console.log('Error trying to enable submit button!!!')
        console.log(err)
      }
    }


    svt.stopImmediatePropagation()
    svt.preventDefault()

    $.ajax({
      type: 'POST',
      url: $form.attr('action'),
      data: $.param(postdata),
      dataType: 'json'
    }).then(done_fn(stim, $form), fail_fn(stim, $form))

  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  clear(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    stim.clearFeedback($form)
    return true
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  //
  // This JQ event handler is attached to calendar icons click events.
  // It simply causes the associated flatpickr (whose instance is recorded
  // in the event 'data' field) to open.
  jq_open(evt) {
    if (evt.data) {
      evt.data.toggle()
    }
    return true
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Query Interface
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // querySelect: Select an autocomplete suggestion
  //
  // This function is a devbridge autocomplete callback that is called when
  // the user has selected one of the suggestions on offer.
  //
  // The callback context has been shaped by Function.prototype.bind()
  //
  querySelect(suggestion) {
    let ctxt = this
    let $query = $(ctxt.query)

    //
    // If the suggestion resolves to a specific profile_id, then submit a new
    // query to fetch the full profile.
    if (suggestion.data.profile_id) {
      let $form = $query.parents('form').first()
      let qdata = { query: suggestion.data.email }

      //
      // Query response handler: check that a profile is defined, and
      // use its fields to populate the form.
      let done_fn = function (ctxt) {
        return function (data, status, xhr) {
          let $query = $(ctxt.query)
          let $form = $query.parents('form').first()
          let resp = xhr.responseJSON

          if (resp.profile) {
            ctxt.stim.populateProfile($form, $query, resp.profile)
          }
        }
      }
      let fail_fn = function (ctxt) {
        return function (xhr, status, error) {
          // debugger
        }
      }

      $.ajax({
        type: 'GET',
        url: $query.data('query-url'),
        data: $.param(qdata),
        dataType: 'json'
      }).then(done_fn(ctxt), fail_fn(ctxt))

    }

  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  queryInvalidate() {
    let ctxt = this
    let $query = $(ctxt.query)
    let $form = $query.parents('form').first()
    ctxt.stim.depopulateProfile($form, $query)
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Wi-Fi Interface
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // change_wifi: Request a new Wi-Fi password and username from server, and use the result
  //              to update the corresponding fields in the form.
  //
  change_unlocked_wifi(svt) {
    return this.change_wifi(svt, true)
  }

  append_suffix(svt){
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $origin = $(svt.currentTarget)
    let suffix = null

    if ($origin.attr('data-suffix')){
      suffix = $origin.data('suffix')
    }else{
      let unit_control = $origin.closest('form').find("[name$='unit_id]']")
      if ((unit_control.length > 0) && (unit_control.attr('data-suffix'))){
        suffix = unit_control.data('suffix')
      }else if ((unit_control.length > 0) && (unit_control.find(':selected').length > 0)) {
         suffix = unit_control.find(':selected').data('suffix')
      }
    }

    let current_value = $origin.val()
    let suffix_begining  = current_value.lastIndexOf(".")
    if (suffix){
      if (suffix_begining > 0){
        let completion_string = ''
        let current_suffix = current_value.substring(suffix_begining)
        if (current_suffix.length < suffix.length){
          let complete = true
          for (let i = 0; i < suffix.length; i++){
            if (!(current_suffix[i]) && (suffix[i])  ){
              completion_string += suffix[i]
            }else{
              if (current_suffix[i] != suffix[i]){
                break
              }
            }
          }
        }
        if(completion_string){
          current_value += completion_string
          $origin.val(current_value)
        }
      }

      if ((current_value) && !current_value.endsWith(suffix)){
        $origin.val(current_value+suffix)
      }
    }
  }

  change_wifi(svt, check_disabled = false) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $origin = $(svt.currentTarget)
    console.log("subscription#change_wifi---:", svt)
    let $form = $(svt.currentTarget).parents('form[data-target*="subscription.form"]').first()
    if ($form) {
      //
      // RDE3944: Lou changed the hidden input name from 'subscription' to valid_subscription'
      // So use the '$=' operator on the following selector ;-)
      let $unit = $(':input[name$="subscription[unit_id]"]', $form)
      let $dpsk = $(':input[data-target*="subscription.wifi_dpsk"]', $form)
      let $uname = $(':input[data-target*="subscription.wifi_uname"]', $form)

      if ($unit.length && ($dpsk.length || $uname.length)) {
        //
        // Short-haul: look for a simple substitution.
        // If the event origin has fix-from and fix-to data attributes, and if
        // the current setting starts with the fix-from string, then substitute for fix-to
        // and return immediately with the changed value.
        //
        // This is a quick way to fix a password that starts with a unit name to a
        // password that starts with a proper pin code.
        let fix_from = $origin.data('fix-from') || ''
        let fix_to = $origin.data('fix-to') || ''
        // debugger
        if (fix_from.length && fix_to.length && $dpsk.val().startsWith(fix_from)) {
          let fixed = $dpsk.val().substring(fix_from.length) + fix_to
          if (!check_disabled || !$dpsk.prop('disabled')) {
            $dpsk.val(fixed).trigger('change')
          }
          return true
        }

        //
        // In for the long haul - setup an ajax call to fetch some random passwords
        let ctxt = {
          stim: stim,
          form: $form,
          check: check_disabled,
        }
        //
        // RDE3816: any other parameters, such as subscription_id or profile_id, should
        // be directly embedded in the URL via its path. Just to be sure.
        let qdata = {
          unit_id: $unit.val(),
          resident_wifi_dpsk: $dpsk.val(),
          resident_wifi_username: $uname.val(),
          force: check_disabled
        }
        let done_fn = function (ctxt) {
          return function (data, status, xhr) {
            let resp = xhr.responseJSON
            // debugger
            if (resp) {
              let $form = ctxt.form
              for (let f in resp) {
                let $input = $(`:input[name$="[${f}]"]`, $form)
                if (!ctxt.check || !$input.prop('disabled')) {
                  $input.val(resp[f])
                }
              }
            }
          }
        }

        let fail_fn = function (ctxt) {
          return function (xhr, status, error) {
            let resp = xhr.responseJSON
            // debugger
          }
        }

        $form.trigger('change')
        $.ajax({
          type: 'GET',
          url: $form.data('wifi-url'),
          data: $.param(qdata),
          dataType: 'json'
        }).then(done_fn(ctxt), fail_fn(ctxt))
      }
    }
    return true
  }


  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Local support functions
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // populateProfile: populate form profile fields using a profile specification returned
  // by an exact-match query.
  //
  populateProfile($form, $query, profile) {
    let stim = this
    console.info('Populate form with profile', profile)
    stim.clearFeedback($form)
    $query.toggleClass('is-valid', true)
    let qname = $query.attr('name')
    //
    // Run through the profile parameters....
    for (let n in profile) {
      let name = `[profile_attributes][${n}]`
      //
      //
      if (!qname.endsWith(name)) {
        let $input = $(`:input[name$="${name}"]`, $form)
        if ($input.hasClass('flatpickr-input')) {
          $input = $input.siblings('.form-control.input')
        }

        if ($input.length) {
          $input.last().val(profile[n])
          if ($input.last().attr('type') === 'checkbox') {
            $input.last().prop('checked', profile[n] !== '0')
          }
        }
      }
    }

  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // De-populate form profile fields after query changes away from an exact match.
  //
  depopulateProfile($form, $query) {
    let stim = this
    console.info('De-populate form profile because', $query.val())
    if ($query.hasClass('is-valid')) {
      let exclude = $query.attr('name')
      let family = $query.data('query-family') || ''
      let $family = $(`:input[name*="${family}"]`, $form).not(`[name="${exclude}"]`)
      $family.val(null)
      $query.toggleClass('is-valid', false)
    }
    stim.clearFeedback($form)
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // feedback: append bootstrap form feedback message
  feedback($for, messages = [], cat = 'invalid') {
    let $parent = $for.parents('div').first()
    $parent.find('div.form-feedback').remove()
    //
    // Need messages to be an array.
    if (!Array.isArray(messages)) {
      messages = [messages]
    }
    //
    // But not an array within an array. Eesh.
    if (messages.length == 1 && Array.isArray(messages[0])) {
      messages = messages[0]
    }

    if (messages.length && ['invalid', 'valid'].includes(cat)) {
      $parent.append('<div class="form-feedback ' + cat + '-feedback">' + messages.join(', ') + '</div>')
    }
    return messages.length
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  clearFeedback($form) {
    $form.find('div.form-feedback,div.alert-dismissable').remove()
    $(':input[name]', $form).toggleClass('is-valid', false).toggleClass('is-invalid', false)
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // dismissable: generate a dismissable bootstrap alert inside a 'notify' container.
  dismissable($form, message, field) {
    let cat = ['danger', 'warning', 'info', 'success'].includes(field) ? field : 'danger'
    $('div[data-target="subscription.notify"],div[data-target="ajax.notify"]', $form)
      .append(`<div class="alert alert-dismissable fade show alert-${cat}" role="alert">` +
        `<span>${field}: ${message}</span>` +
        '<button class="close" type="button" data-dismiss="alert" aria-label="close">' +
        '<span aria-hidden="true"><i class="md-glyph">close</i></span></button></div>')
  }

}
