import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['main', 'childcheckbox']

    /*
     little stim controller to drive th values of child checkboxes based on the
     value of the main checkbox
     - main_checkbox will be the value that the child checkboxes will be set
     from. it will have a data-main-id that the children will have also.  The
     id will allow multiple main check boxes and children if needed

     - child_checkbox will be the children that will be set based on the main
     checkbox
     */


    initialize(){
        let stim = this;
        window.child_checkboxes = $(stim.childcheckboxTargets)
        $(stim.mainTargets).each(function (i, maincheckboxTarget) {
            $(maincheckboxTarget).on('change', this, function(){
                let main_check = $(this)
                let main_id =  main_check.data('mainId')
                let is_checked = main_check.prop('checked')
                $(window.child_checkboxes).filter(function(){ return $(this).data('mainId') == main_id}).each(function(i, cb){
                    $(cb).prop('checked', is_checked)
                })

            })
        })
    }
}