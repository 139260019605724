// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side null-valued input placeholder substitution.
//
// This stimulus controller allows for a null-valued input to be assigned its placeholder
// as its temporary value when focused. This allows the user to edit the field as though
// its placeholder were its initial value. The null will be restored when the input is
// blurred, unless the user has made some change.
//
// It is intended for use with inputs whose placeholder shows its default value.
// The swap action means that users are not confused when they try to edit such fields.
//
import { Controller } from 'stimulus'
import Clipboard from 'clipboard'

export default class extends Controller {
  static targets = ['switch']

  connect() {
    //
    //
    let stim = this
    console.info("SWAPHOLD CONNECT:", stim)
    $(this.switchTargets)
      .filter('[placeholder]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let $form = $btn.closest('form')
        let data = $btn.data('swaphold') || {}
        let $from = data['from'] ? $(data['from'], $form) : null
        let ctxt = { stim: stim, form: $form, btn: $btn, data: data, from: $from }
        if ($from) {
          $btn.attr('placeholder', $from.val())
        }
        $btn
          .on('focus', ctxt, stim.fill)
          .on('blur', ctxt, stim.empty)

        $form.on('loaded lookup:complete', ctxt, stim.replace)
        // console.log(`--> ${$btn.attr('name')}`)
      })
  }

  //
  // Fill an empty input with its placeholder when focused.
  // This allows the user to edit whatever value placeholder suggests
  // is present.
  fill(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $input = $(svt.currentTarget)
    let $from = ctxt.from
    let value = $input.val().trim()
    let holder = $from ? $from.val().trim() : $input.attr('placeholder').trim()
    // console.log(`--- '${value} ' vs. '${holder} ':`, value.length < 1)
    if (holder.length > 0 && value.length < 1) {
      $input.toggleClass('swapheld', true).val(holder)
    }
    return true
  }

  //
  // Empty an input on blur if no change was made to it.
  // This will restore its placeholder visibility.
  empty(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    // console.info('swaphold#empty:', svt, ctxt)
    let $input = $(svt.currentTarget)
    let $from = ctxt.from
    let value = $input.val().trim()
    let holder = $from ? $from.val().trim() : $input.attr('placeholder').trim()
    // console.log(`--- '${value}' vs. '${holder} ':`, value === holder)
    if (holder.length > 0 && value === holder) {
      $input.filter('.swapheld').val('')
    }
    $input.toggleClass('swapheld', false)
    return true
  }

  replace(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    // console.info('swaphold#replace:', svt, ctxt)
    let $btn = ctxt.btn
    let $from = ctxt.from
    if ($from) {
      $btn.attr('placeholder', $from.val().trim())
    }
    return true
  }

}
