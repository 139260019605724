// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side Stripe payment form interface.
//
// This stimulus controller provides all kinds of useful support for Wi-Fi subscription
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["stripe_form", "stripe_submit_button"];

  tries = 1; // number of tries for an async call start with 1

  pvals = {}; //variable to store payment vals
  pobjs = {}; // varaiable to store payment objs

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  connect() {
    let stim = this
    let ctxt = { stim: this, sub_id: null }
    console.log('STRIPE: CONNECT', stim)

    // get a payments-config for the public key needed for stripe using an
    // async function that will await before continuing.  If successful it will
    // setup each form
    stim.sendPaymentConfigAndSetup(ctxt)
    //////////////////////////////////
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  // This first call initializes our underlying connection with Stripe, collecting and
  // storing the information we need to initiate payments elsewhere.
  //
  // Upon successful initialization it will then cue async calls for each target form,
  // so that each form is ready to proceed.
  //
  async sendPaymentConfigAndSetup(ctxt) {
    let me = `stripe#setup:`
    let stim = ctxt.stim
    console.log(me, ctxt)
    // get a payments-config for the public key needed for stripe using an
    // async function that will await before continuing.  If successful it will
    // setup each form
    try {
      let r = await $.ajax({
        method: "POST",
        url: stim.apiConfigUrl(),
        data: JSON.stringify({
          sor_key: stim.sor(),
        }),
        dataType: "json",
        headers: {
          "Content-Type": "application/json",
          "x-Public-Key": stim.pubkey(),
        },
      })
      // the config is not specific to each subscription like the
      // intent is
      stim.pvals.config = r
      stim.tries = 1
      //
      ////////////////////////////////////////////////////////////////////////////////////
      // Create a separate, dedicated 'payment intent' for each subscription bloc.
      // Each call is an async call.
      $(stim.stripe_formTargets).each(function (i, stripe_form) {
        // get a payment intent from GRT and save in object pvals
        // the remainder of the setup code will be called from the sendTestPayment
        // setup code
        stim.sendTestPaymentAndContinue(Object.assign(ctxt, { stripe_form: stripe_form }))
      })

    } catch (error) {
      // lets retry each api call up to 5 times on a timeout
      // 408 status
      if (error.status == 408 && stim.tries < 6) {
        console.log(`Timeout on config call attempt ${stim.tries}`)
        stim.tries++
        let f = stim.sendPaymentConfigAndSetup
        setTimeout(function () {
          f(ctxt)
        }, 500)
      } else {
        console.log("Error in config call!!!!!", error)
        this.report_problem(stim, me, error)
      }
    }
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  async sendTestPaymentAndContinue(ctxt) {
    let stim = ctxt.stim
    let stripe_form = ctxt.stripe_form
    let sub_id = $(stripe_form).data("subId")
    let me = `stripe#initiate[${sub_id}]:`
    console.log(me, ctxt)

    // Lets get a payment intent going with a token..  We will also instantiate
    // a stripe instance and element for each form
    try {
      //console.log(stim.trans_payment_request());
      //console.log(JSON.stringify(stim.trans_payment_request()));
      let r = await $.ajax({
        method: "POST",
        url: stim.apiTransactionUrl(),
        data: JSON.stringify(stim.trans_payment_request(stripe_form)),
        dataType: "json",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": stim.clientApiKey(),
        },
      })
      stim.pvals[sub_id] = {}
      stim.pobjs[sub_id] = {}
      stim.pvals[sub_id].intent = r
      let r_ctxt = Object.assign(ctxt, { sub_id: sub_id })
      let r_vals = stim.initStripeRoutine(r_ctxt)
      //this.setupStripeFormEvents(r_vals.stripe_instance, r_vals.stripe_elements)
      stim.pobjs[sub_id].stripe_instance = r_vals.stripe_instance
      stim.pobjs[sub_id].stripe_elements = r_vals.stripe_elements
      // We need to setup the event for each button that will submit the form
      stim.tries = 1
      stim.setupStripeFormValidateEvents(r_ctxt)
    } catch (error) {
      // lets retry each api call up to 5 times on a timeout
      // 408 status
      if (error.status == 408 && stim.tries < 6) {
        console.log(`Timeout on payment call attempt ${stim.tries}`)
        stim.tries++
        let f = stim.sendTestPaymentAndContinue
        setTimeout(function () {
          f(ctxt)
        }, 500)
      } else {
        console.log("Error in payment call!!!!!", error)
        this.report_problem(stim, me, error, sub_id)
      }
    }
  }


  //
  ////////////////////////////////////////////////////////////////////////////////////////
  initStripeRoutine(ctxt) {
    let stim = ctxt.stim
    let sub_id = ctxt.sub_id
    let me = `stripe#init(${sub_id}):`
    console.log(me, ctxt)
    // on each form lets get some stripe objects that we can use
    // to authorize the payment
    try {
      let stripe_instance = window.Stripe(stim.pvals.config.card.public_key, {
        stripeAccount: stim.pvals[sub_id].intent.stripeAccount,
        locale: gon.npg_vars.locale,
      })

      let stripe_options = {
        clientSecret: stim.pvals[sub_id].intent.paymentIntentSecret,
        // Fully customizable with appearance API.
        appearance: {
          theme: "flat",
        },
      }

      let stripe_elements = stripe_instance.elements(stripe_options)

      let stripe_card_element = stripe_elements.create("payment", {
        wallets: {
          applePay: "never",
          googlePay: "never",
        },
      })

      stripe_card_element.mount(`#card-element-${sub_id}`)
      stim.tries = 1

      return {
        stripe_instance: stripe_instance,
        stripe_elements: stripe_elements,
      }
    } catch (error) {
      if (error.status == 408 && stim.tries < 6) {
        console.log(`Timeout on Stripe init call attempt ${stim.tries}`)
        stim.tries++
        let f = stim.initStripeRoutine
        setTimeout(function () {
          f(ctxt)
        }, 500)
      }
      else {
        console.log("Error in Stripe init call!!!!!", error)
        this.report_problem(stim, me, error, sub_id)
      }
    }
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  setupStripeFormValidateEvents(ctxt) {
    let stim = ctxt.stim
    let sub_id = ctxt.sub_id
    let me = `stripe#validate(${sub_id}):`
    console.log(me, ctxt)

    // Setup the events for the button that will basically
    // authorize the information entered on the form
    try {
      let stripe_form_button = $(`#card-button-${sub_id}`)
      let r_ctxt = Object.assign(ctxt, { button: stripe_form_button })
      $(stripe_form_button).on("click", this, function (e) {
        $(this).prop("disabled", true)
        stim.confirmMyStripePayment(r_ctxt)
      })
    } catch (error) {
      console.log("Error in Stripe Form init call!!!!!", error)
      this.report_problem(stim, me, error, sub_id)
    }
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  async confirmMyStripePayment(ctxt) {
    let stim = ctxt.stim
    let sub_id = ctxt.sub_id
    let button = ctxt.button
    let me = `stripe#confirm(${sub_id}):`
    console.log(me, ctxt)
    // This function will perform the actual authorization of the CC information
    // if a plan has been chosen
    try {
      let formMessages = $(`#card-element-messages-${sub_id}`)

      let render_partial = $(button).data("renderPartial")
        ? $(button).data("renderPartial")
        : null

      if (stim.must_verify_tier_selection(stim, sub_id)) {
        // need to localize these messages
        let bs_alert = stim.get_bootstrap_message(
          gon.npg_vars.selection_needed_label,
          "danger"
        )
        formMessages.html(bs_alert)
        $(button).prop("disabled", false)
        return
      }

      formMessages.html("")
      let r = await stim.pobjs[sub_id]["stripe_instance"].confirmPayment({
        elements: stim.pobjs[sub_id]["stripe_elements"],
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      })
      //console.log(r);
      stim.tries = 1
      let error_on_transaction = r.hasOwnProperty("error")
      let message = ""
      let type = error_on_transaction ? "danger" : "success"

      if (error_on_transaction) {
        // This error should be localized directly from stripe
        message = r.error.message
      } else {
        // This was used for debug purposes we dont need to localize
        message = gon.npg_vars.payment_update //`You have validated this token #: ${stim.pvals[sub_id]["intent"]["token"]}`;
      }
      let bs_alert = stim.get_bootstrap_message(message, type)

      if (!error_on_transaction) {
        // update token on subscription
        // use async await
        stim.update_my_subscription_token(
          ctxt,
          sub_id,
          bs_alert,
          stim,
          button,
          render_partial
        )
      } else {
        formMessages.html(bs_alert)
        $(button).prop("disabled", false)
      }
    } catch (error) {
      if (error.status == 408 && stim.tries < 6) {
        console.log(`Timeout on payment confirm call attempt ${stim.tries}`)
        stim.tries++
        let f = stim.confirmMyStripePayment
        setTimeout(function () {
          f(ctxt)
        }, 500)
      } else {
        console.log("Error in payment confirm call!!!!!", error)
        this.report_problem(stim, me, error, sub_id)
        $(button).prop("disabled", false)
        // Im not sure we really want to hide the div here so Im adding
        // as a comment for the moment
        ////////////////////////////
        // Lets display the error in the div for this subscription form
        //stim.generic_error_display(sub_id)
      }
    }
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  // Send intent token to MTU and update subscription plan selection parameters.
  //
  // This is the end of the chain: it is what happens after everything else completes
  // successfully.
  //
  async update_my_subscription_token(ctxt, sub_id, bs_alert, stim, button, render_partial) {
    let me = `stripe#token(${sub_id}):`
    console.log(me, ctxt, stim, bs_alert, button, render_partial)
    // function will update the subscription on the MTU, set the payment_type
    // and change the service tier ( if applicable)
    let formMessages = $(`#card-element-messages-${sub_id}`)
    let service_tier_select = stim.selected_tier_option(sub_id)
    let service_tier_selection =
      service_tier_select.length > 0 ? service_tier_select.val() : ""

    try {
      //
      ////////////////////////////////////////////////////////////////////////////////////
      // The MTU portal URL that this request is to be delivered is subject to override
      // by options in the form data attributes. We check here for data-opts in the form
      // and incorporate any 'path' or 'params' properties it might contain into our $.ajax
      // request.
      let $form = $(ctxt.stripe_form)
      let r_opts = $form.data("opts")
      let r_params = r_opts.hasOwnProperty("params") ? r_opts.params : {}

      //
      ////////////////////////////////////////////////////////////////////////////////////
      // Build the data bloc (parameter set) that we want to send to the recipient
      let r_data = Object.assign(
        {
          token: stim.pvals[sub_id]["intent"]["token"],
          service_tier_id: service_tier_selection,
          render_partial: render_partial,
        },
        r_params
      )

      //
      ////////////////////////////////////////////////////////////////////////////////////
      // build the request descriptor for ajax...
      let r_ajax = {
        method: "POST",
        dataType: "json",
        url: stim.subscription_token_update_url(ctxt, sub_id),
        data: r_data,
      }

      //
      ////////////////////////////////////////////////////////////////////////////////////
      // Send the request and wait for it to finish.
      // debugger
      let r = await $.ajax(r_ajax)

      // debugger

      stim.tries = 1
      formMessages.html(bs_alert)
      if ((r === undefined) || (r.message !== "success")) {
        // enabling the button just causes issues lets just keep it disabled
        $(button).prop("disabled", false)
      }
      // check if we have a data attribute on the button called
      // modal which would be the id of the modal which is displaying the
      // stripe form
      let modal_to_hide_id = $(button).data("modalToHideId")

      if (render_partial) {
        $(`#change-your-payment-div-${sub_id}`).html(r.partial)
      } else if (modal_to_hide_id) {
        $(`#${modal_to_hide_id}`).modal("hide")
      } else {
        window.location.reload()
      }
    } catch (error) {
      if (error.status == 408 && stim.tries < 6) {
        console.log(
          `Timeout on MTU subscription update call attempt ${stim.tries}`
        )
        stim.tries++
        let f = stim.update_my_subscription_token
        setTimeout(function () {
          f(ctxt, sub_id, bs_alert, stim, button, render_partial)
        }, 500)
      } else {
        console.log("Error in MTU subscription update call!!!!!", error)
        this.report_problem(stim, me, error, sub_id)
        $(button).prop("disabled", false)
      }
    }
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  apiTransactionUrl() {
    // Intent endpoint
    return `${gon.npg_vars.base_url}/transactions`
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  apiConfigUrl() {
    // payments config endpoint
    return `${gon.npg_vars.base_url}/payments-config`
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  // MTU endpoint for updating payment info on a sub
  //
  // The default MTU endpoint for delivering token updates can be overridden by opts.path
  // values embedded as data in the form element. This function takes any such data into
  // account when determining the exact URL (really, _path_) where token updates should
  // be sent.
  subscription_token_update_url(ctxt, sub_id) {
    let $form = $(ctxt.stripe_form)
    let options = $form.data('opts')

    //
    ////////////////////////////////////////////////////////////////////////////////////
    // Fetch options from form data-opts, and extract any embedded 'path'.
    // If nonesuch exists then we can fall back on the original resident-based path.
    let path = (options && options.hasOwnProperty('path') && options.path !== null && options.path.length) ?
      options.path :
      '/residents/subscriptions/${sub_id}/sub_payment_update'

    //
    ////////////////////////////////////////////////////////////////////////////////////
    // Perform manual interpolation of any ${sub_id} term remaining in the path string,
    // and return the resultant string.
    let url = path.replace(/[#$]?[{]sub_id[}]/, sub_id)
    return url
  }

  clientApiKey() {
    // Client API key
    return gon.npg_vars.npg_client_key
  }

  sor(form = null) {
    // The SOR key is stored in every payment intent transaction
    // Seems pointless to send through the gon..  Lets just
    // get the sor key from the intent transaction
    let trans = this.trans_payment_request(form)
    return trans.sor_key
  }

  pubkey() {
    // Public key
    return gon.npg_vars.npg_public_key
  }

  trans_payment_request(form = null) {
    if (form == null) {
      // if we are here without a form it means that we are just looking
      // for an sor key and the first form data-paygay-data would suffice
      // for this
      form = this.stripe_formTargets[0]
    }
    return $(form).data("paygayData")
  }

  get_bootstrap_message(message = '', type = '') {
    // function to return a bootstrap alert
    let dismiss_button = $(
      "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
    )
    let div_html = $(`<strong>${message}</strong>`)
    let new_message_alert = $("<div></div>")
      .addClass(`alert alert-${type} alert-dismissable fade show`)
      .html(div_html)
    new_message_alert.append(dismiss_button)
    return new_message_alert
  }

  must_verify_tier_selection(stim, sub_id) {
    let me = `stripe#verify(${sub_id}):`
    console.log(me, stim)
    // Lets put a check here to see if we need to verify the selection of
    // a service tier by using a hidden input.  This form will be reused
    // for just a token refresh....
    let verify_tier = false
    let verify_tier_selection_input = $(`#service-tier-force-${sub_id}`)
    if (verify_tier_selection_input.length > 0 && verify_tier_selection_input.val() == 'true') {
      verify_tier = stim.selected_tier_option(sub_id).length == 0
    }
    return verify_tier
  }

  selected_tier_option(sub_id) {
    return $(`#pick-your-plan-select-${sub_id}`).find(":selected")
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //
  // Debuggering
  //
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  generic_error_display(sub_id = null) {
    // if we encounter an error due to GRT, Stripe or whatever issue
    // Lets hide any artifacts and display a message in the div in lieu
    // of showing any anomalies
    let pick_your_plan_selector = (sub_id) ? $(`#pick-your-plan-${sub_id}`) : $('[id^=pick-your-plan-]')
    pick_your_plan_selector.html("")
    pick_your_plan_selector.html(`<h3 class="p-5">${gon.npg_vars.alert_error}</h3>`)
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  // Post gon.npg_vars on the console
  trace_gon() {
    console.log('--- npg_vars:')
    for (const property in gon.npg_vars) {
      console.log(`.... ${property}: ${gon.npg_vars[property]}`)
    }
  }

  //
  ////////////////////////////////////////////////////////////////////////////////////////
  // Raise an alert, with some context.
  report_problem(stim, who, problem, sub_id = null) {
    console.warn(`stripe#problem:`, stim, who, problem, sub_id)
    let report = [
      `Problem report from ${who}`,
      `subscription: ${sub_id || "unspecified"}`,
      `captured as: ${typeof problem}`,
    ]

    //
    //////////////////////////////////////////////////////////////////////////////////////
    // An exception of some kind?
    if (problem instanceof Error) {
      report.push("-------------------------------------")
      report.push(`Exception: ${problem.name} - ${problem.message}`)
      report.push(`cause: ${problem.cause}`)
      if (problem.hasOwnProperty('fileName')) {
        messages.push(`at: ${problem.fileName}:${problem.lineNumber}.${problem.columnNumber}`)
      }
    }

    //
    //////////////////////////////////////////////////////////////////////////////////////
    // Status fields
    if (problem.hasOwnProperty("status")) {
      report.push("-------------------------------------")
      report.push(`status: ${problem.status} - ${problem.statusText}`)
    }

    //
    //////////////////////////////////////////////////////////////////////////////////////
    // NPG variables passed in via gon...
    if (false) {
      report.push("-------------------------------------")
      report.push("NPG parameters:")
      for (const property in gon.npg_vars) {
        if (["base_uri", "npg_client_key", "npg_public_key"].includes(property)) {
          report.push(`${property}: ${gon.npg_vars[property]}`)
        }
      }
    }

    //
    //////////////////////////////////////////////////////////////////////////////////////
    // Look for a responseJSON property...
    if (problem.hasOwnProperty('responseJSON') && problem.responseJSON !== 'undefined') {
      report.push("-------------------------------------")
      report.push('responseJSON:')
      report.push(JSON.stringify(problem.responseJSON))
    }
    // debugger
    alert(report.join('\n'))
  }

}
