// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['checkbox']

    /*
    This controller adds hide show functionality to a div based on
    a checkbox state.
    Assumed:
    - Checkbox with target 'checkbox' driven by this controller with
     a data attribute 'hidden_div_id'.  The data attribute is the id of the
     div that will house the input.
    - Div with a id referenced on the checbox.
    */

    connect() {
        console.info("EMAIL_DOMAIN_OVERRIDE_INIT")
        let stim = this
        $(stim.checkboxTargets).each(function (i, checkbox) {
            let $checkbox = $(checkbox)
            $checkbox.on('change', stim, stim.hide_show_div)
        })
    }

    hide_show_div(svt) {
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        let $checkbox = $(svt.currentTarget)
        let controlled_div = $checkbox.data('hidden-div-id')
        if ($checkbox.prop('checked')) {
            $(controlled_div).prop('hidden', false)
        } else {
            $(controlled_div).find('input').val('')
            $(controlled_div).prop('hidden', true)
        }
    }
}