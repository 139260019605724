// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Modal - stupid pedantic manouevres around modal dialogs.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['reloader', 'drop_wifi', 'drop_bldg']   // A modal that should reload the page after it closes

  connect() {
    let stim = this
    console.info("MODAL CONNECT", stim)

    //
    // Reloaders:
    $(stim.reloaderTargets)
      .on('hidden.bs.modal', stim, stim.reload)

    //
    // Drop WiFi targets prime and trigger Gateway/Wifi drop/replace modals in
    // service provider property build-out pages.
    //
    // These targets are typically buttons or button-like elements, and are required
    // to provide additional information in data[drop-wifi].
    $(stim.drop_wifiTargets)
      .filter('[data-drop-wifi]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let ctxt = { stim: stim, drop: $btn.data('drop-wifi') }
        $btn.on('click', ctxt, stim.drop_wifi_modal)
      })

  }

  //
  // Reload page after a modal has hidden.
  reload(svt) {
    console.log('modal#reload:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    window.location.reload()
  }

  //
  // Drop and replace SPPG or ZWPS in a service provider property.
  drop_wifi_modal(svt) {
    console.log('modal#drop_wifi_modal:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $btn = $(svt.currentTarget)
    let $modal = $(ctxt.drop.target)
    let $rmsel = $('select[name$="[remove_id]"]', $modal)
    let $repsel = $('select[name$="[replace_with_id]"]', $modal)
    let $delete = $('input[type="checkbox"][name$="[delete_if]"]')
    let alt = []

    //
    //
    $('h5', $modal).first().text($btn.attr('title'))
    $modal.find('div.form-feedback,div.alert-dismissable').remove()

    //
    // Set the 'remove_id' select value to the specified identifier...
    $rmsel.val(ctxt.drop.selected)

    //
    // Choose an alternative, provided there is one.
    $('option', $repsel).each(function (o, opt) { if ($(opt).val() != ctxt.drop.selected) { $repsel.val($(opt).val()) } })

    //
    // Uncheck the delete-if switch by default
    // $delete.prop('checked', false)

    //
    // Open the modal on screen
    $modal.modal('show')

  }

}
