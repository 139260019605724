// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import {Controller} from "stimulus";
// little js to be able to include stimulus
// events to newly introduced objects using data-action
// example: data-action="obj-connected->spinner#spinnerConnected"
export default class extends Controller {
    connect() {
        const event = document.createEvent("CustomEvent")
        event.initCustomEvent("obj-connected", true, true, null)
        this.element.dispatchEvent(event)
    }
}