// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
// Stylesheets
import 'flatpickr/dist/themes/airbnb.css'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'intl-tel-input/build/css/intlTelInput.min.css'
import 'trix/dist/trix.css'
import 'bootstrap4-toggle/css/bootstrap4-toggle.min.css'
// import 'stylesheets'

// Images
import 'images'

// Javascript
require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require("trix")
// Temporary kludge until actiontext gets fixed
import { AttachmentUpload } from "@rails/actiontext/app/javascript/actiontext/attachment_upload.js"

addEventListener("trix-attachment-add", (event) => {
  const { attachment, target } = event

  if (attachment.file) {
    const upload = new AttachmentUpload(attachment, target)
    upload.start()
  }
})
// require("@rails/actiontext")
// end kludge

require("bootstrap-loader")

import "gems"
import "bootstrap"
import "bootstrap4-toggle"
import flatpickr from "flatpickr"
import "datatables.net"
import "datatables.net-responsive"
import "datatables.net-rowreorder-bs"
import "datatables.net-buttons"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css"
//
// Datatables plugins should be loaded last, apparently, or they don't work quite right.
import "datatables.net-plugins/sorting/natural.js"

import "devbridge-autocomplete/dist/jquery.autocomplete.min.js"

import "./libs/pagy.js.erb"
import "./libs/trix-editor-overrides.js.erb"
import "@simonwep/pickr/dist/themes/classic.min.css"
import "chartkick/chart.js"

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
window.stimulus = application

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery
