// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['table']

  initialize () {
    $(this.tableTarget).DataTable({
      scrollY: '66vh',
      scrollCollapse: true,
      paging: false,
      stateSave: true,
      stateDuration: 60 * 60 * 365
    })
  }
}
