// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['table']

    // Dtops has the ability to now receive callbacks for datatables
    // the callback must exist in a key named 'callbacks' and the string value
    // will be converted into a function

    connect() {
        let stim = this

        $(stim.tableTargets).each(function (i, table) {
            let $table = $(table)
            let dflt = {
                scrollY: "66vh",
                scrollCollapse: true,
                paging: true,
                deferRender: true,
                searchDelay: 500,
                lengthMenu: [10, 25, 50, 100, 500],
                stateSave: true,
                stateDuration: 60 * 60 * 365,
                responsive: true,
                buttons: ["colvis"],
                rowReorder: false,
                columnDefs: [{type: "natural-nohtml", targets: "_all"}],
                // columnDefs: [{ type: "natural-nohtml", targets: "0" }],
                drawCallback: function (settings) {
                    var dt = this.api()
                    dt.columns.adjust()
                },
            }
            let extra = $table.data('dtopts')

            // lets parse our dtopts callback so we can use them
            // DTopts accepts a key named callbacks which will be removed after
            // it is parsed and its string values changed to functions
            if ((extra) && ('callbacks' in extra)) {
                for (var key in extra.callbacks) {
                    const this_func = eval(extra.callbacks[key])
                    extra[key] = this_func //eval(extra.callbacks[key])
                }
                delete extra.callbacks
            }
            let options = {...dflt, ...extra}
            let api = $table.DataTable(options)

            var dtable = api

            // Lets do some magic here.  If we set this data attribute to true
            // data-delay-search we are going to unbind the default bindings
            // and search if over 3 or more characters are entered or enter/backspace is
            // pressed
            if ($table.data('delaySearch') && $table.data('delaySearch') == true) {
                $(".dataTables_filter input")
                    .unbind() // Unbind previous default bindings
                    .bind("input keyup", function (e) { // Bind our desired behavior
                        // If the length is 3 or more characters, or the user pressed ENTER, search
                        if (this.value.length >= 3 || e.keyCode == 13 || e.keyCode == 8) {
                            // Call the API search function
                            dtable.search(this.value).draw()
                        }
                        // Ensure we clear the search if they backspace far enough
                        if (this.value == "") {
                            dtable.search("").draw()
                        }
                        return
                    })
            }


            if (extra && Object.keys(extra).length > 0) {
                console.log(`dataTable options:`, table, options)
            }


            //
            // Cue column-adjust actions whenever a collapsable panel or tab has opened.
            $('div.ez-interior')
                .on('shown.bs.collapse', api, stim.adjust)
                .on('shown.bs.tab', api, stim.adjust)
            $(window).on('pageshow', api, stim.adjust)
        })

    }

    //
    // This handler is called to adjust table columns after a tab or collapsable panel
    // has opened. Without this, nothing lines up.
    adjust(evt) {
        // console.log('dt-embed#adjust:', evt)
        evt.data.columns.adjust()
        return true
    }
}
