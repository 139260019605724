// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['table']

  initialize () {
    localStorage.clear()
  }
}
