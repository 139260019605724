// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side datepicker widget handler
//
//
import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['picker', 'reset']

  connect() {
    let stim = this

    //
    // Datepicker 'picker' targets are inputs that hold a date
    // chosen from a date-picker widget.
    $(stim.pickerTargets).each(function (i, elem) {
      let $elem = $(elem)
      let $timeline = $elem.parents('[data-timeline]').first()

      //
      // Compute today's date, and a string denoting our timezone.
      let today = new Date()
      let tz = 'GMT' + ((-1 * today.getTimezoneOffset()) / 60)
      let mark = $elem.data('date-mark')
      let windowed = $elem.data('date-windowed') || false

      //
      // This part, calculate separate midnight for start/end, we'll keep in-hand for later.
      let midnight = (mark == 'end' && false) ? '23:59:59' : '00:00:00'

      //
      // Convert a date into midnight in the current timezone, then back
      // into a date again.
      function timecast(date) {
        let x = date && date !== '0' ? date : ''
        // let v = x.trim().length ? `${x.trim()} ${midnight} GMT` : x
        let v = x.trim().length ? `${x.trim()} ${midnight} ${tz}` : x
        let z = v.length ? new Date(v) : new Date()
        // FireFox is strict with date function to be honest its just chrome and other browsers that
        // are lenient.
        // Standard :
        // http://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.15
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
        if (isNaN(z)) {
          z = new Date(v.split(' ').slice(0, -1).join('T'))
        }
        // console.log(`date#timecast ${date} => x(${x}) => v(${v}) =>`, z)
        return z
      }

      //
      // Function that returns date formatted as 'YYYY-MM-dd', which is required for min' and 'max'
      // attributes. Honestly - JS can be such a pain sometimes.
      function ymd(date) {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
      }

      //
      // Read the stored date value and add midnight and time-zone components
      // to it: but only if a value has indeed been specified.
      let cdate = timecast($elem.val())

      //
      // Compare stored Date() against today's Date(): the lesser of these
      // defines the minimum date that the calendar widget should allow to
      // be picked.
      let limit = cdate.valueOf() < today.valueOf() ? cdate : today
      let min = ymd(limit)

      //
      // Create and configure a calendar widget around our input element.
      let options = {
        // altFormat: 'Y-m-d',
        // altInput: true,
        allowInput: true,
        minDate: min,
        disable: []
      }

      //
      // Look for disabled dates
      let block = $elem.data('date-block')
      // debugger
      if (block && block.length) {
        options.disable = block
      }

      //
      // Fit to timeline
      if ($timeline.length && windowed) {
        let x = $timeline.data('timeline').split(',')
        // console.log("--- timeline:", x, $elem.data('date-mark'))
        if (['o', 'x'].includes(x[0])) {
          let tmin = timecast(x[1])
          let tmax = x[2].length && x[2] !== '0' ? timecast(x[2]) : null

          if (x[0] === 'x') {
            let $ante = $timeline.prev('[data-timeline]')
            let $post = $timeline.next('[data-timeline]')
            let y = $ante.length ? $ante.data('timeline').split(',') : ['x', '', '']
            let z = $post.length ? $post.data('timeline').split(',') : ['x', '', '']

            //
            // Edge case in 'y' uses today as limit if no prior vacancy on timeline.
            tmin = y[0] === 'o' ? timecast(y[1]) : y[1].length ? tmin : limit
            // tmax = z[0] === 'o' && z[2].length && z[2] !== '0' ? timecast(z[2]) : tmax
            tmax = z[0] === 'o' ? (z[2].length && z[2] !== '0' ? timecast(z[2]) : null) : tmax
          }

          if (tmin.valueOf() > limit.valueOf()) {
            options.minDate = ymd(tmin)
          }

          if (tmax) {
            options.maxDate = ymd(tmax)
          }
        }
        // debugger
      }

      //
      //
      // console.log(`--- daterange:, ${ymd(limit)} / ${options.minDate || 'nil'} / ${$elem.val()} / ${options.maxDate || 'nil'}`)
      // console.log(`--> ${mark} min:`, options.minDate)
      // console.log(`--> ${mark} max:`, options.maxDate)
      //
      // Set date minima and maxima on the native 'date' input itself.
      $elem
        .attr('min', options.minDate)
        .attr('max', options.maxDate || null)

      //
      // Ay-yi-yi.
      // Some browsers show blank when the programmed date lies further back
      // than the widget is supposed to allow.
      // So yet another stupid edge-case hack, and all because of control-freaking dabblers
      // who will just never get it.
      if ($elem.prop('disabled')) {
        options.minDate = $elem.val()
      }

      //
      // Build the API
      let api = $elem.flatpickr(options)

      //
      // The widgets are accompanied by an external calendar button.
      // It must be hand-hacked to toggle the widget when pressed.
      $elem.parent().find('div.input-group-append')
        .attr('style', 'cursor: pointer')
        .attr('tabindex', '-1')
        .on('click', api, stim.jq_open)

    })

    //
    // Date reset button targets are date inputs that should
    // be reset whenever a particular button is pressed.
    // For 'button' read any element that can be found inside
    // an input group. The associated date-reset-btn data attribute
    // provides a CSS selector that will be used to find the button.
    $(stim.resetTargets)
      .filter('[data-date-reset-btn]')
      .each(function (i, elem) {
        let $elem = $(elem)
        let $form = $elem.parents('form')
        let $container = $elem.parents('.input-group')
        let selector = $elem.data('date-reset-btn')
        let $input = $('input.form-control.input', $container)
        let $btn = $(selector, $container)
        $btn
          .attr('style', 'cursor: pointer')
          .attr('title', 'Click here to ensure open-ended!')
          .on('click', function () {$elem.val('none'); $form.trigger('change')})
      })


  }


  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  //
  // This JQ event handler is attached to calendar icons click events.
  // It simply causes the associated flatpickr (whose instance is recorded
  // in the event 'data' field) to open.
  jq_open(evt) {
    if (evt.data) {
      evt.data.toggle()
    }
    return true
  }


}
