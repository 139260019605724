// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side subscription form handler.
//
// This stimulus controller provides all kinds of useful support for Wi-Fi subscription
// forms: those forms that are used to create or update Wi-Fi SUBSCRIPTIONS and the user
// PROFILES that are associated with them.
//
//
// By convention: stimulus event handlers accept stimulus event arguments ('svt')
// whereas JQ event handlers accept JQ event arguments ('jvt')
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'form',           // A form for a subscription, and perhaps an embedded profile
    'dpsk',      // A form input containing a Wi-Fi DPSK
]

  connect() {
    let stim = this


  }





  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Wi-Fi Interface
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // change_wifi: Request a new Wi-Fi password and username from server, and use the result
  //              to update the corresponding fields in the form.
  //
  change_unlocked_wifi(svt) {
    return this.change_wifi(svt, true)
  }

  change_wifi(svt, check_disabled = false) {
    //debugger
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $origin = $(svt.currentTarget)
    console.log("guest_network#change_wifi---:", svt)
    let $form = $(svt.currentTarget).parents('form[data-target*="guest-network.form"]').first()
    if ($form) {
      let $dpsk = $(':input[data-target*="guest-network.dpsk"]', $form)
      let $guest_network = $(':input[name$="guest_network_attributes][id]"]', $form)
      if ($dpsk.length && $guest_network.length) {
        let ctxt = {
          stim: stim,
          form: $form,
          check: check_disabled,
        }
        let qdata = {
          guest_network_id: $guest_network.val(),
          dpsk: $dpsk.val(),
          force: check_disabled
        }
        let done_fn = function (ctxt) {
          return function (data, status, xhr) {
            let resp = xhr.responseJSON
            // debugger
            if (resp) {
              let $form = ctxt.form
              for (let f in resp) {
                let $input = $(`:input[name$="[${f}]"]`, $form)
                $input.val(resp[f])
              }
            }
          }
        }

        let fail_fn = function (ctxt) {
          return function (xhr, status, error) {
            let resp = xhr.responseJSON
            // debugger
          }
        }

        $form.trigger('change')
        $.ajax({
          type: 'GET',
          url: $form.data('wifi-url'),
          data: $.param(qdata),
          dataType: 'json'
        }).then(done_fn(ctxt), fail_fn(ctxt))
      }
    }
    return true
  }

}
