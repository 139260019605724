// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// SPPG - Service Provider Properties Gateways
//
// This controller provides the ability to select between different service provider 
// property gateways, and to update form fields to reflect the current choice.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'cue']

  connect() {
    console.info("SPPG CONNECT")
    let stim = this

    $(stim.selectTargets)
      .filter('select[data-sppg]')
      .each(function (s, select) {
        let $select = $(select)
        let $form = $select.parents('form').first()
        let ctxt = { stim: stim, sppg: $select.data('sppg'), select: select, form: $form.get() }
        $select.on('change', ctxt, stim.fetch)
      })

  }

  //
  // Fetch:
  //
  // Fetch a policy from the server, write its text into a modal dialog, and cue the modal
  // to shoot an acceptance when the user clicks 'I AGREE'.
  //
  fetch(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    console.log('sppg#fetch:', svt, ctxt)

    //
    // Callback with gateway details fetched from the server.
    // This function is called after the ajax request for gateway details
    // completes successfully. 
    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        if (resp) {
          console.log('sppg#fetch#done', xhr, data, resp, ctxt)
          let $form = $(ctxt.form)
          let action = $form.attr('action').replace(/\d+$/, resp.sppg.service_provider_property_gateway_id)
          $form.attr('action', action)
          for (let f in resp.gateway) {
            let name = `[${f}]`
            let $input = $(`:input[name$="${name}"]`, $form)
            $input.val(resp.gateway[f])
          }
          $(':input', $form)
          .not('[name$="[service_provider_properties_gateway_id]"]')
          .prop('disabled', resp.readonly)
        }
      }
    }
    let fail_fn = function (stim, ctxt) {
      return function (xhr, status, error) {
        let resp = xhr.responseJSON
        console.log('sppg#fetch#fail', xhr, error)
      }
    }

    svt.stopImmediatePropagation()
    svt.preventDefault()

    //
    // Request SPPG details from the server and cue the modal dialog
    // when results are retrieved.
    let $select = $(svt.currentTarget)
    let $form = $select.parents('form').first()
    let sppg = $select.val()
    let url = $form.attr('action').replace(/\d+$/, sppg)
    $.get({ url: url })
      .done(done_fn(stim, ctxt))
      .fail(fail_fn(stim, ctxt))

    return false
  }

}
