// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// File upload UI controller.
//
// This stim controller provides UI support for file upload inputs: those which select
// files to be uploaded to the cloud, one way or another.
//
// It does NOT have anything at all to do with actual file uploading: it is entirely
// concerned with tweaking UI elements, and perhaps metering upload progress.
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  initialize(svt) {
    let stim = this
    console.log('UPLOAD initialize:', stim)
    $(stim.formTargets).each(function (i, form) {
      let $form = $(form)
      let form_ctxt = { stim: stim, form: form }

      //
      // For each file input container in the form, set up a handler
      // for any 'change' events it receives from within.
      $('div.custom-file', $form).each(function (c, container) {
        let $div = $(container)
        let ctxt = { stim: stim, form: form, container: container }
        $div.on('change', ctxt, stim.file_changed)
      })

      $form
        .on('direct-uploads:start', form_ctxt, stim.uploads_start)
        .on('direct-uploads:end', form_ctxt, stim.uploads_end)
    })
  }

  connect(svt) {
    let stim = this
    console.log('UPLOAD connect:', stim)
    $(stim.formTargets).each(function (i, form) {
      let $form = $(form)
      $(':input[data-direct-upload-url]', $form).each(function (i, uploader) {
        let $uploader = $(uploader)
        let ctxt = { stim: stim, form: form, uploader: uploader }
        $uploader
          .on('direct-upload:initialize', ctxt, stim.upload_init)
          .on('direct-upload:start', ctxt, stim.upload_start)
          .on('direct-upload:before-blob-request', ctxt, stim.upload_before_blob)
          .on('direct-upload:before-storage-request', ctxt, stim.upload_before_storage)
          .on('direct-upload:progress', ctxt, stim.upload_progress)
          .on('direct-upload:error', ctxt, stim.upload_error)
          .on('direct-upload:end', ctxt, stim.upload_end)
      })

    })
  }

  //
  // file_changed: JQ 'change' event handler for file input containers.
  // This handler updates the label associated with the input to show the name of
  // the file that has been selected for upload.
  file_changed(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    if (ctxt && ctxt.hasOwnProperty('stim')) {
      let $container = $(ctxt.container)
      let inputs = $('input.custom-file-input', $container).get()
      let files = inputs[0].files
      $('label.custom-file-label', $container).html(files[0].name)
    }

    return true
  }

  //
  // Collective uploads tracking
  uploads_start(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#uploads_start:", svt)
    // debugger
    return true
  }

  uploads_end(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#uploads_end:", svt)
    // debugger
    return true
  }

  //
  // Individual upload tracking
  upload_init(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#upload_init:", svt, svt.detail)
    return true
  }
  upload_start(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#upload_start:", svt, svt.detail)
    return true
  }
  upload_before_blob(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#upload_before_blob:", svt, svt.detail)
    return true
  }
  upload_before_storage(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#upload_before_storage:", svt, svt.detail)
    return true
  }
  upload_progress(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#upload_progress:", svt, svt.detail)
    return true
  }
  upload_error(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#upload_rror:", svt, svt.detail)
    return true
  }
  upload_end(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log("upload#upload_end:", svt, svt.detail)
    return true
  }

}
