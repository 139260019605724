// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
import {Controller} from "stimulus";

// This controller will contain functions needed
// for the multiple endpoint UI/UX
// add target and remove target

export default class extends Controller {
    static targets = ['add_target','remove_target', 'revert_target']

    connect() {
        let stim = this
        $(stim.add_targetTargets).each(function (f, link) {
            let add_link = $(link)
            add_link.on('click', stim, stim.addAField)
        })

        $(stim.remove_targetTargets).each(function (f, link) {
            let remove_link = $(link)
            remove_link.on('click', stim, stim.removeAField)
        })

        $(stim.revert_targetTargets).each(function (f, link) {
            let revert_link = $(link)
            revert_link.on('click', stim, stim.revertAField)
        })
    }

    addAField(svt){
        svt.stopImmediatePropagation()
        svt.preventDefault()
        let current_target = svt.currentTarget
        // check if there is a data-empty_selector and if there is see if there
        // are any empty inputs before adding more
        let empty_selector = $(current_target).data('emptySelector')
        if(empty_selector != undefined){
            if($(empty_selector).filter(function() {
                return $(this).val().trim() == ""; }).length > 0){
                return true
            }
        }
        let add_container_div = $('#'+$(current_target).data('container'))
        let time = new Date().getTime();
        let regexp = new RegExp($(current_target).data('id'), 'g')

        let fieldSet = document.createElement('fieldset');
        fieldSet.innerHTML = $(current_target).data('fields').replace(regexp, time)
        $(add_container_div).prepend(fieldSet);
    }

    removeAField(svt){
        svt.stopImmediatePropagation()
        svt.preventDefault()
        let current_target = svt.currentTarget
        $(current_target).parent().find('input[type=hidden]').val('1')
        //$(current_target).parents('fieldset').hide()
        let text_field = $(current_target).parents('fieldset').find('input[type=text]')
        if($(text_field).val().trim() == ''){
            $(current_target).parents('fieldset').hide()
        }else{
            $(text_field).addClass('deleted')
            $(text_field).attr('disabled', 'disabled')
            $(current_target).addClass('invisible')
            $(current_target).siblings('.revert_fields').removeClass('invisible')
        }
    }

    revertAField(svt){
        svt.stopImmediatePropagation()
        svt.preventDefault()
        let current_target = svt.currentTarget
        $(current_target).parent().find('input[type=hidden]').val('0')
        //$(current_target).parents('fieldset').hide()
        let text_field = $(current_target).parents('fieldset').find('input[type=text]')
        $(text_field).removeClass('deleted')
        $(text_field).removeAttr('disabled')
        $(current_target).addClass('invisible')
        $(current_target).siblings('.remove_fields').removeClass('invisible')
    }

}