// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// AUP - Acceptable Use Policies
//
// This controller provides the ability to display acceptable use policies in reactive
// modal dialogs, and to submit user acceptance of terms.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['shoot', 'cue']

  connect() {
    console.info("AUP CONNECT")
    let stim = this

    $(stim.cueTargets)
      .filter('[data-aup]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let ctxt = { stim: stim, aup: $btn.data('aup') }
        $btn.on('click', ctxt, stim.cue)
      })

    $(stim.shootTargets)
      .filter('[data-aup]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let ctxt = { stim: stim, aup: $btn.data('aup') }
        $btn.on('click', ctxt, stim.shoot)
      })
  }

  //
  // Cue:
  //
  // Fetch a policy from the server, write its text into a modal dialog, and cue the modal
  // to shoot an acceptance when the user clicks 'I AGREE'.
  //
  cue(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    console.log('aup#cue:', svt, ctxt)

    //
    // Callback with policy details fetched from the server.
    // This function is called after the ajax request for policy details
    // completes successfully. The content of one or more policies is
    // enshrined as JSON in the response.
    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        if (resp) {
          console.log('aup#cue#done', xhr, data, resp, ctxt)
          let policy = resp[ctxt.aup.policy]
          if (policy && policy.hasOwnProperty('sectext')) {
            let $modal = $(ctxt.aup.modal)
            $('div.aup-content', $modal).html(policy.sectext)
            if (ctxt.aup.post && ctxt.aup.post.length) {
              $('.modal-footer button.btn-primary', $modal).on('click', ctxt, stim.shoot)
            }
            $modal.modal('show')
          }
        }
      }
    }

    svt.stopImmediatePropagation()
    svt.preventDefault()

    //
    // Request policy details from the server and cue the modal dialog
    // when results are retrieved.
    $.get({ url: ctxt.aup.get })
      .done(done_fn(stim, ctxt))

    return false
  }

  //
  //
  //
  shoot(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this

    let postdata = {
      aup_version_id: ctxt.aup.policy,
      profile_id: ctxt.aup.profile,
      aup_transact_type_id: ctxt.aup.ack,
      memo: ctxt.aup['memo'] || 'Accepted by resident while logged-in to their account.'
    }

    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        console.log('aup#shoot#done', xhr, data)
      }
    }
    let fail_fn = function (stim, ctxt) {
      return function (xhr, status, error) {
        let resp = xhr.responseJSON
        console.log('aup#shoot#fail', xhr, error)
      }
    }

    console.log('aup#shoot:', svt, ctxt, postdata)
    svt.stopImmediatePropagation()
    svt.preventDefault()
    if (ctxt.aup.post && ctxt.aup.post.length) {
      $.ajax({
        type: 'POST',
        url: ctxt.aup.post,
        data: $.param(postdata),
        dataType: 'json'
      })
        .done(done_fn(stim, ctxt))
        .fail(fail_fn(stim, ctxt))
        .always(function () {
          console.log('aup#shoot#always')
          location.reload(true)
        })
    }
    return true
  }

}
