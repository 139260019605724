import consumer from "./consumer";

consumer.subscriptions.create(
  {
    channel: "AllAdministratorsChannel",
    room: room_name(),
  },
  {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log(`connected to room ${room_name()}`);
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      console.log("disconnected");
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      console.log(`Received data on room: ${room_name()}`);
    },
  }
);

function room_name() {
  return `admin_channel_on_${gon.cable_vars.tenant_schema}`;
}
