// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

/*
Generic stim controller to load a partial in a modal, driven by the click event of
an element.

Assumed:
 - Page has a modal loaded in the DOM
 - element has the following data tags
   - id: id of the object for the form
   - modal: id of the modal form
   - modal_form_container: id of container that will hold the html content from
     the partial
 -  modal_form_container will have a data tag
    - form-partial-action: rails action that will load a partial form based on the above
      mentioned id

 */

export default class extends Controller {
    static targets = [
        'edit_element', 'delete_element'
    ]


    initialize() {
        let stim = this
        let editElementTargets = stim.edit_elementTargets
        let deleteElementTargets = stim.delete_elementTargets

        $(editElementTargets).each(function (i, editElementTarget) {
            $(editElementTarget).on('click', this, function (e) {
                e.stopImmediatePropagation()
                e.preventDefault()
                let object_id = $(this).data('id');
                let modal = $(this).data('modal');
                let modal_form_container = $(this).data('modalFormContainer');
                let form_partial_action = $(modal_form_container).data('formPartialAction');

                load_form(object_id, modal, modal_form_container, form_partial_action);
            })
        })

        $(deleteElementTargets).each(function (i, editElementTarget) {
            $(editElementTarget).on('click', this, function (e) {
                e.stopImmediatePropagation()
                e.preventDefault()
                let object_id = $(this).data('id');
                let modal = $(this).data('modal');
                let modal_form_container = $(this).data('modalFormContainer');
                let form_partial_action = $(modal_form_container).data('formPartialAction');

                load_form(object_id, modal, modal_form_container, form_partial_action);
            })
        })

        function load_form(id, modal, form_container, form_partial_action){
            $(form_container).html('');
            $(modal)
            $.ajax({
                type: 'POST',
                url: form_partial_action ,
                data: {'id' : id},
                success: function(data, status, xhr) {
                    $(form_container).html(data);
                    $(modal).modal('show');
                },
                error: function (data, status, xhr) {
                    alert("Error please contact our offices!!!")
                }
            })
        }

    }




    connect() {
        let stim = this;

    }

}
