import * as shared_functions from "../shared/shared_functions"

export function parse_batch_sub_data(data) {
  if (shared_functions.get_message_div("batch-sub-message-div").length > 0) {
    console.log(`batch_upload:`, data)
    shared_functions.get_message_div("batch-sub-message-div").html("")
    shared_functions.display_messages(data, "errors", "batch-sub-message-div")
    shared_functions.display_messages(
      data,
      "messages",
      "batch-sub-message-div"
    )
    if (data.hasOwnProperty("refresh_page") && data.refresh_page == true) {
      shared_functions.refresh_page()
    }
    //
    // Whoa -- WTF is this?
    // It completely resets the form and erases any messages just displayed!
    // Commented-out until its original purpose can be determined.
    else {
      // $("#new_batch_subscription").trigger("reset")
    }
  }
  else {
    console.warn(`batch-upload: Failed to deliver`, data)
  }
}
