// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// trix: a controller for manipulating trix rich text editor widgets
//
// This controller provides the ability to select between different service provider
// property gateways, and to update form fields to reflect the current choice.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['noattach']
  connect() {
    console.info("TRIX CONNECT")
    let stim = this
    $(stim.noattachTargets).each(function (e, trix) {
      let $trix = $(trix)
      let toolbar_id = $trix.attr('toolbar')
      // debugger
      if (toolbar_id.length > 0) {
        let $toolbar = $(`#${toolbar_id}`)
        $('button.trix-button--icon-attach', $toolbar).prop('disabled', true)
      }
    })
  }
}