import * as shared_functions from "../shared/shared_functions"

export function parse_unit_wizard_data(data) {
  // lets parse the data for errors and if we should show the
  // datatable
  if (shared_functions.get_message_div("wizard-message-div").length > 0) {
    shared_functions.get_message_div("wizard-message-div").html("")
    shared_functions.display_messages(data, "errors", "wizard-message-div")
    shared_functions.display_messages(data, "messages", "wizard-message-div")
    show_loaded_units(data)
  }
}

export function parse_unit_import_data(data) {
  // lets parse the data for errors and if we should show the
  // datatable
  if (shared_functions.get_message_div("import-message-div").length > 0) {
    shared_functions.get_message_div("import-message-div").html("")
    shared_functions.display_messages(data, "errors", "import-message-div")
    shared_functions.display_messages(data, "messages", "import-message-div")
    show_loaded_units(data)
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
// Progress bar updater
//
// Presumes that there exists a block of one or more progress bars situated inside
// a container identified as
export function unit_deploy_progress(data) {
  let $container = $('#unit-import-progress')

  //
  // The bars item should be an array of [numer, denom] pairs that
  // express the current progress of one or more bars. The pairs
  // are applied to progress bars in that order: first to first,
  // second to second, etc...
  if (data.hasOwnProperty('bars')) {
    data.bars.forEach(function (pair, n) {
      let $progress = $(`div.progress:nth-of-type(${n + 1})`, $container)
      set_progress_bar($progress, pair)
    })
  }
  if (data.hasOwnProperty('fini')) {
    if (data.fini) {
      $container.collapse('show')
    }
    else {
      $container.collapse('hide')
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
function set_progress_bar(progress, pair) {
  let $progress = $(progress)
  let $bar = $('div.progress-bar', $progress)
  let numer = pair[0]
  let denom = pair[1]
  denom = denom != 0 ? denom : (numer != 0 ? numer : 1)
  let percent = Math.ceil((numer / denom) * 100)
  let thruple = pair.length > 2 && pair[2].length > 0
  let label = [thruple ? `${pair[2]}` : null, ($bar.hasClass('fmt-pair') ? `${pair[0]} / ${pair[1]}` : `${percent}%`)].filter(function (el) {return el != null}).join(': ')
  $bar.css('width', `${percent}%`).text(label)
}


function show_loaded_units(data) {
  if (data.hasOwnProperty("show_units") && data.show_units == true) {
    $("#property-unit-wizard-form").trigger("reset")
    $("#property-unit-import-form").trigger("reset")
    $("#collapse-u").siblings().trigger("click")
    $("#property-units-datatable").DataTable().ajax.reload()
  }
}
