// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable";

// unfortunately on production env using a load balancer and
// nginx passing these WSS requests to puma the request just
// doesnt have the FQDN so we need to be creative when creating the
// connection clientside.  We already are passing the tenant in the
// gon so lets just pass it back as a param when making the connection
function getWebSocketURL() {
  var protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
  var host = window.location.host;
  var path = gon.cable_vars.mount_path;
  var url = protocol + host + path;
  return `${url}?tenant=${gon.cable_vars.tenant_schema}`;
}

console.log("Trying to pass tenant in the params!!!!....");
console.log(getWebSocketURL());
export default createConsumer(getWebSocketURL());
