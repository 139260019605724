// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// International Telephone Inputs
//
// This controller provides the ability to request some arbitrary URL but not give a damn
// about its content or whether the request was even successful.
//
// It is typically used by buttons to trigger server-side actions: firing off some email,
// for example, or shooting somebody's password.
//
import { Controller } from 'stimulus'
// import intlTelInput from 'intl-tel-input/build/js/intlTelInput-jquery.min.js'
import intlTelInput from 'intl-tel-input/build/js/intlTelInput'
import 'intl-tel-input/build/js/utils.js'

export default class extends Controller {
  static targets = []   // A shoot button

  initialize() {
    let stim = this
    // console.info("INTL INIT")
    let telopts = {
      formatOnInit: true,
      allowDropdown: true,
      nationalMode: true,
      initialCountry: 'us',
      separateDialCode: false,      // This option breaks CSS badly
      autoHideDialCode: false,
      autoPlaceholder: 'aggressive',
      hiddenInput: "",
    }

    $('form').has('input[type="tel"]')
      .each(function (f, form) {
        let $form = $(form)
        let ctxt = { stim: stim, form: form, iti: null }
        $('input[type="tel"]', $form).each(function (t, tel) {
          let $tel = $(tel)
          let extras = $tel.data('intlopts')
          ctxt.tel = tel
          telopts.allowDropdown = $(tel).parents('div.modal').length < 1
          ctxt.iti = intlTelInput(tel, { ...telopts, ...extras })
          //
          // Correct for the stupid padding that is
          // 'computed' when separate dial code is enabled.
          if (telopts.separateDialCode) {
            $tel.css('padding-left', '80px')
          }

          //
          // Cue a form submit handler that will convert the tel input
          // value to an e164 number with whatever international prefix
          // has been chosen.
          $form.on('submit', ctxt, stim.convert)
        })
      })
  }
  connect() {
    // console.info("INTL CONNECT")
  }

  convert(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let ok = true
    // console.log("iti#convert:", svt, ctxt)
    if (ctxt && ctxt.iti) {
      $(ctxt.tel).toggleClass('is-invalid', false).siblings('div.form-feedback').remove()
      let number = ctxt.iti.getNumber(1) || ''
      $(ctxt.tel).val(number).toggleClass('is-valid', true).toggleClass('is-invalid', false)
      if (!ctxt.iti.isValidNumber() && number.trim().length > 0) {
        let reason = ctxt.iti.getValidationError()
        $(ctxt.tel).toggleClass('is-invalid', true)
        ok = false
        let message = gon.intl_controller.enter_valid
        switch (reason) {
          case window.intlTelInputUtils.validationError.IS_POSSIBLE:
            message = gon.intl_controller.is_possible
            break
          case window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
            message = gon.intl_controller.invalid_cc
            break
          case window.intlTelInputUtils.validationError.TOO_SHORT:
            message = gon.intl_controller.too_short
            break
          case window.intlTelInputUtils.validationError.TOO_LONG:
            message = gon.intl_controller.too_long
            break
          case window.intlTelInputUtils.validationError.NOT_A_NUMBER:
            message = gon.intl_controller.not_a_number
            break
          default:
            message = `[${reason}] ${gon.intl_controller.other_reason}`
        }
        $(ctxt.tel).parent().append(`<div class="form-feedback invalid-feedback">${message}</div>`)
      }

      if (!ok) {
        svt.stopImmediatePropagation()
        svt.preventDefault()
      }
    }
    return ok
  }

}
