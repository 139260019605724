// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// Lookup - Fetch a resource and update a form
//
// This controller provides the ability to select between different record identifiers,
// and to lookup the associated content of a selected record and update update form fields
// to reflect its values.
//
///
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'cue', 'reset']

  connect() {
    console.info("lookup CONNECT")
    let stim = this

    $(stim.selectTargets)
      .filter('select[data-lookup]')
      .each(function (s, select) {
        let $select = $(select)
        let $form = $select.parents('form').first()
        let ctxt = { stim: stim, lookup: $select.data('lookup'), select: select, form: $form.get() }
        $select.on('change', ctxt, stim.fetch)
      })

    $(stim.resetTargets).each(function (s, button) {
      let $button = $(button)
      $button.on('click', function (e) {
        $(e.target.closest('form')).find('[data-target~="lookup.select"]').change()
      })
    })

  }

  //
  // Fetch:
  //
  // Fetch a record from the server, then write its values into the appropriate
  // form inputs.
  //
  fetch(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    console.log('lookup#fetch:', svt, ctxt)

    //
    // Callback with gateway details fetched from the server.
    // This function is called after the ajax request for gateway details
    // completes successfully.
    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        if (resp) {
          console.log("lookup#fetch#done", xhr, data, resp)
          let $form = $(ctxt.form)
          // A data_variable_read_only setting set to true indicates that the form may
          // accept model specified read only capability ( disabling of the html form elements).
          // You may exempt a specific control in these circumstances by setting that control's data_ignore_variable_read_only
          // setting to true ( this can be used for inputs like, a selection of a model instance for editing).
          // The read only specification is included in the returned payload as an attribute called
          // 'read_only' and to have the control always default back to not being disabled you would set the
          // data_default_enabled attribute to true (this is for situations where you might have controls
          // that are always disabled regardless of variable settings)..  Additionally an attribute can be sent
          // with the model payload called specific_read_only_attributes which will house
          // an array of attributes that will be read only ( for situations that you might
          // want to disable specific attributes based on business logic)
          let check_read_only = $form.data("variableReadOnly") == true

          $(":input.form-control", $form).toggleClass("is-invalid", false)
          for (let m in resp) {
            let model = resp[m]
            if (model && m != 'placeholders') {
              let placeholders = resp['placeholders'] === undefined ? undefined : resp['placeholders'][m]
              // debugger
              for (let f in model) {
                let name = `[${f}]`
                //
                // Look for a regular input or for a multi-valued select.
                // Those have an extra '[]' at the end of their name.
                //
                //
                let $input = $(`:input[name$="${name}"]`, $form)
                let $select = $(`select[name$="${name}[]"]`, $form)
                let $img = $(`img[name$="${name}"]`, $form)
                let $trix = $input.siblings("trix-editor")
                if (m !== "img_src_attr") {
                  if ($select.length > 0) {
                    $select.val(model[f])
                  } else {
                    $input.each(function (e, elem) {
                      let $elem = $(elem)
                      let ignore_variable_edit =
                        $elem.data("ignoreVariableReadOnly") == true
                      switch ($elem.attr("type")) {
                        case "checkbox":
                          let c1 = $elem.prop("checked")
                          let c2 = model[f] ? true : false
                          let c3 = c1 !== c2
                          if (c3) {
                            $elem.parent("div.toggle").trigger("click")
                          }
                          break

                        case "radio":
                          $elem.prop("checked", $elem.val() == model[f])
                          break

                        default:
                          $elem.val(model[f])
                          if ($trix.length) {
                            $trix.html(model[f])
                          }
                          break
                      }

                      // conditional will check if we need to set any attributes to read only
                      let just_name = name.replace(/\[|\]/g, '')
                      if (
                          (check_read_only &&
                              model.hasOwnProperty("read_only") &&
                              !ignore_variable_edit)
                      ) {
                        $elem.prop("disabled", model.read_only)
                      } else if (check_read_only &&
                          !ignore_variable_edit &&
                          model.hasOwnProperty("specific_read_only_attributes") &&
                          model.specific_read_only_attributes.indexOf(just_name) >= 0) {
                        $elem.prop("disabled", true)
                      } else if ($elem.data('defaultEnabled') &&
                          $elem.data('defaultEnabled') == true) {
                        $elem.prop("disabled", false)
                      }

                    })
                  }
                } else if ($img.length > 0) {
                  $img.attr("src", model[f])
                }
                //
                // Check for placeholders on this element, and apply
                // if any are specified.
                if (placeholders !== undefined && placeholders.hasOwnProperty(f)) {
                  let holder = placeholders[f]
                  if (holder.length > 0) {
                    $input.attr('placeholder', holder)
                  }
                }

              }
            }
          }
          $form
            .trigger("change")
            .trigger("lookup:success")
            .trigger("lookup:complete")
        }
      }
    }
    let fail_fn = function (stim, ctxt) {
      return function (xhr, status, error) {
        let resp = xhr.responseJSON
        console.log('lookup#fetch#fail', xhr, error)
      }
    }

    svt.stopImmediatePropagation()
    svt.preventDefault()

    //
    // Request SPPG details from the server and cue the modal dialog
    // when results are retrieved.
    let $select = $(svt.currentTarget)
    let $form = $select.parents('form').first()
    let chosen = $select.val()
    let url = ctxt.lookup.get
    //
    // Parse the name field of the select input into two parts,
    // where the second part is the name of the record attribute enclosed
    // in square brackets. That is how rails names inputs in its forms:
    // e.g. [record_type][field_name]
    let re = /(.*)\[(.*)\]$/
    let names = re.exec($select.attr('name'))
    let postdata = {}
    postdata[names[2]] = chosen

    //
    // Look for an alternate id item in the data set.
    // If present it will provide a JQ selector for the select element
    // that provides the record id.
    //
    // Only used when some other select field is mapped for lookup,
    // and only used in a very small number of cases. The first being the
    // ability to pull default metadata after a change of SsoProvider
    // provider type.
    if (ctxt.lookup.hasOwnProperty('alt') && ctxt.lookup.alt.length > 0) {
      let $idsel = $(ctxt.lookup.alt, $form)
      if ($idsel.length > 0) {
        let names = re.exec($idsel.attr('name'))
        postdata[names[2]] = $idsel.val()
      }
    }

    $.get({ url: url, data: $.param(postdata) })
      .done(done_fn(stim, ctxt))
      .fail(fail_fn(stim, ctxt))

    return false
  }

}
