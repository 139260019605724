// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'toggle']

  connect() {
    console.info("CHECKBOX INITIALIZE")
    let stim = this
    let options = {
      on: gon.checkbox_controller.checked,
      off: gon.checkbox_controller.unchecked,
      xon: '<i class="md-glyph md-glyph-outlined">done</i>',
      xoff: '<i class="md-glyph md-glyph-outlined">clear</i>',
      onstyle: "primary",
      offstyle: "outline-primary android_fix",
      size: "xs",
      //
      // width and height must be set explicitly to ensure that any
      // checkboxes that are initially stashed in an accordion or tab will have
      // the correct size when revealed. Values are those calculated by the widget
      // itself when checkboxes are initially visible!
      width: "40.1875px",
      height: "27.5px"
    }

    $(stim.formTargets).each(function (i, form) {
      $(form).on('reset', stim, stim.after_reset)

    })

    $(stim.toggleTargets).each(function (i, toggle) {
      let $toggle = $(toggle)
      //
      // Check for a style override (data-onstyle property) and adjust the
      // options accordingly.
      options.onstyle = $toggle.data('onstyle') || 'primary'
      options.offstyle = `outline-${options.onstyle} android_fix`
      if ($toggle.prop('disabled')) {
        options.onstyle = 'light'
        options.offstyle = 'light'
      }
      $toggle.bootstrapToggle(options).on('change', stim, stim.blur)
    })
  }

  //
  // This function redraws all checkbox toggles some short time after
  // a form reset event. Because reset does not trigger any 'change' events.
  // Instead we allow some time for the reset to complete, then redraw
  // the widgets according to their 'checked' state at that time.
  after_reset(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    setTimeout(function () {
      // console.log('checkbox#after_reset', svt, svt.currentTarget.formData)
      $(stim.toggleTargets, $form).each(function (i, toggle) {
        let $toggle = $(toggle)
        // console.log('---', $toggle, $toggle.prop('checked'))
        $toggle.bootstrapToggle($toggle.prop('checked') ? 'on' : 'off')
      })
    }, 10)
    return true
  }

  blur(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $checkbox = $(svt.currentTarget)
    console.log('checkbox#blur', svt)
    $checkbox.trigger('blur')
    // svt.stopImmediatePropagation()
    // svt.preventDefault()
    return true
  }



}


