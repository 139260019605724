// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// clusterfux - Create and manage widgets / buttons for specifying SPPG clusters
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['card']   // A shoot button

  initialize() {
    console.info("CFUX INITIALIZE")
  }

  connect() {
    console.info("CFUX CONNECT")
    let stim = this
    $(stim.cardTargets)
      .filter('[data-cfux]')
      .each(function (c, card) {
        let $card = $(card)
        let $form = $card.closest('form')
        let cfux = $card.data('cfux')
        let ident = cfux['ident'] || {}
        let state = cfux['state'] || {}
        let binding = cfux['binding'] || {}
        let unbound = cfux['unbound'] || {}
        let attach = cfux['attach'] || {}
        let detach = cfux['detach'] || {}

        //
        // Let's identify the various inputs that will drive our response
        ident['jqo'] = ident.hasOwnProperty('jqsel') ? $(`:input[name$="[${ident.jqsel}]"]`, $form).last() : null
        state['jqo'] = state.hasOwnProperty('jqsel') ? $(`:input[name$="[${state.jqsel}]"]`, $form).last() : null
        binding['jqo'] = binding.hasOwnProperty('jqsel') ? $(`:input[name$="[${binding.jqsel}]"]`, $form).last() : null
        unbound['jqo'] = unbound.hasOwnProperty('jqsel') ? $(`:input[name$="[${unbound.jqsel}]"]`, $form).last() : null
        attach['jqo'] = attach.hasOwnProperty('jqsel') ? $(`:input[name$="[${attach.jqsel}]"]`, $form).last() : null
        detach['jqo'] = detach.hasOwnProperty('jqsel') ? $(`:input[name$="[${detach.jqsel}]"]`, $form).last() : null

        let ctxt = { stim: stim, form: $form, card: $card, cfux: cfux, ident: ident, state: state, binding: binding, unbound: unbound, attach: attach, detach: detach }

        // debugger
        $card
          .on('load turbolinks:load ajax:success cfux:refresh', ctxt, stim.refresh)
          .trigger('cfux:refresh')

        $form.on('lookup:success', ctxt, stim.refresh_all)
      })

  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  // refresh_all:
  //
  // Refresh all clusterfux cards in a form.
  refresh_all(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log('cfux#refresh_all', svt, stim, ctxt)
    // debugger
    $('div[data-cfux]', ctxt.form).each(function (c, card) {
      $(card).trigger('cfux:refresh')
    })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  // refresh:
  //
  // Refresh a single clusterfux card
  //
  refresh(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    console.log('cfux#refresh', svt, stim, ctxt)
    stim.populate_card(ctxt)
    return true
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  // launch_modal:
  //
  // Launch a cluster drag-drop modal in response to 'New' or 'Edit' button click.
  //
  launch_modal(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let $btn = $(svt.currentTarget)
    let $modal = $(ctxt.binding.modal)
    console.log('cfux#launch_modal', svt, stim, ctxt)

    svt.stopImmediatePropagation()
    svt.preventDefault()

    $modal.modal('show')

    return false
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  // shoot:
  //
  // Compose and post an AJAX request to attach to or detach from a cluster.
  //
  shoot(svt) {
    //
    // Callback to handle successful Ajax response.
    // That response will be HTML for the target page.
    // We use it to re-write the document DOM.
    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        console.log('cfux#shoot#done', xhr, data, ctxt)
        //
        // Trigger a 'change' on our identity input. If it is lookup-enabled then
        // we can refresh the form this way.
        ctxt.ident.jqo.trigger('change')
        // debugger
        //
        // If the response is JSON and includes a reload: field,
        // then redirect to that address.
        try {
          if (resp.hasOwnProperty('reload') && resp.reload.length) {
            location = resp.reload
          }
        }
        catch (err) {
          console.log(err)
        }
      }
    }
    //
    // Callback to handle a failed Ajax response.
    // Not much we can do about that.
    let fail_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        // let resp = xhr.responseJSON
        console.error('cfux#shoot#fail', xhr, data)
        debugger

      }
    }

    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let $btn = $(svt.currentTarget)
    let hrefs = $btn.attr('href').split('/') || []
    console.log('cfux#shoot', svt, stim, ctxt)
    // debugger

    svt.stopImmediatePropagation()
    svt.preventDefault()
    if (hrefs.length == 9 && /[0-9]+/.test(hrefs[7])) {
      hrefs[7] = ctxt.ident.jqo.val()
      let href = hrefs.join('/')
      let cluster_id = $btn.data('cfux-clust')
      let postparam = { 'cluster_id': $btn.data('cfux-clust') }

      // debugger
      $.post({
        method: 'POST',
        url: href,
        data: $.param(postparam),
      }).then(done_fn(stim, ctxt), fail_fn(stim, ctxt))
    }
    else {
      console.warn(`--- hrefs too short: expected nine parts, but contains ${hrefs.length}`)
      // debugger
    }

    return false
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  populate_card(ctxt) {
    //
    // This function splits an options string into a list of [text, id] pairs.
    // Because that's kinda how rails will store them as input values.
    //
    // The pairs are separated by commas.
    // The components are separated by '|'
    function to_a(args) {
      let ary = []
      let ary1 = args.split(',')
      args.split(',').forEach((pair) => {
        let x = pair.split('|')
        console.log(pair, ' => ', x)
        if (x.length == 2) { ary.push(x) }
      })
      console.log(`=== '${args}'.to_a = `, ary1, ary)
      return ary
    }

    function button_html(href, glyph, title, text, style) {
      let html = `<div class="mx-1 d-inline-block">
        <a href="${href}" class="btn btn-${style} ez-small" title="${title}">
          <i class="md-glyph md-glyph-outlined mr-1">${glyph}</i>
          ${text}
        </a>
      </div>`
      return (html)
    }


    let stim = ctxt.stim
    let $card = ctxt.card
    console.log('cfux#populate_card', stim, ctxt)

    //
    // If we don't have a state then we can't draw any buttons!
    if (ctxt.state.jqo) {
      let ident = ctxt.ident.jqo.val()
      let state = ctxt.state.jqo.val()
      let binding = to_a(ctxt.binding.jqo ? ctxt.binding.jqo.val() : '')
      let unbound = to_a(ctxt.unbound.jqo ? ctxt.unbound.jqo.val() : '')
      let attach = to_a(ctxt.attach.jqo ? ctxt.attach.jqo.val() : '')
      let detach = to_a(ctxt.detach.jqo ? ctxt.detach.jqo.val() : '')
      let $card = ctxt.card

      //
      // Clear everything from the container, then add buttons as necessary
      $card.empty()
      console.clear()
      console.log(`cfux#populate_card([${ident}])`, stim, ctxt)
      console.log(`--- state: '${state}'`)
      console.log('--- binding:', binding)
      console.log('--- unbound:', unbound)
      console.log('--- attach:', attach)
      console.log('--- detach:', detach)

      //
      // A 'primary' node is binding, and has one or more secondaries
      // An 'available' node is potentially unbound
      if (['available', 'primary'].includes(state) && binding.length > 0) {
        let html = button_html('#',
          state == 'available' ? 'new_releases' : 'edit_note',
          `Cluster peer gateways around ${binding[0]}`,
          `${state == 'available' ? 'New' : 'Edit'} Cluster ${binding[0][0]}`,
          'secondary')
        $card.append(html)
        let $a = $('a', $card).last()
        $a
          .data('cfux-sppg', ident)
          .data('cfux-clust', binding[0][1])
          .data('toggle', 'modal')
          .data('target', '"#cluster-ms-modal"')
          .on('click', ctxt, stim.launch_modal)
      }
      //
      // An 'available' node is one that may be unbounded, or may be attached
      // as a secondary.
      if (attach.length > 0) {
        attach.forEach((pair) => {
          console.log(`---- attach pair:`, pair)
          let html = button_html(ctxt.attach['href'],
            'add_circle',
            `Attach this gateway to cluster at ${pair[0]}`,
            `Join with ${pair[0]}`,
            'outline-secondary')
          $card.append(html)
          let $a = $('a', $card).last()
          $a
            .data('cfux-sppg', ident)
            .data('cfux-clust', pair[1])
            .on('click', ctxt, stim.shoot)
        })
      }
      //
      // A 'secondary' node is already attached to cluster.
      // It may be detached from that cluster.
      if (detach.length > 0) {
        detach.forEach((pair) => {
          let html = button_html(ctxt.detach['href'],
            'remove_circle',
            `Remove this gateway from cluster at ${pair[0]}`,
            `Remove from ${pair[0]}`,
            'outline-secondary')
          $card.append(html)
          let $a = $('a', $card).last()
          $a
            .data('cfux-sppg', ident)
            .data('cfux-clust', pair[1])
            .on('click', ctxt, stim.shoot)
        })
      }
      if (!['primary', 'available', 'secondary', 'available_no_nodes'].includes(state)) {
        console.warn(`--- state '${state}' is not supported`)
      }
    }
  }

}
