// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// smslock - SMT text notifications type vs. group interlocks
//
// This controller is intended for use with resident notice editor forms, where it will
// ensure that an SMS text notice cannot be typed as 'initially checked' or vice-versa.
//
// It works by selectively enabling or disabling options in a'group' or 'type' select menu
// when some change is made in selected value of the other.
//
// Hard-coded for the following group/type values:
// ===============================================
// group 0: email notification
// group 1: SMS text notification
//
// type 1: always checked
// type 2: initially unchecked
// type 3: initially checked
//
// Because _of course_ one would be enumerated from 0.. but the other from 1..
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['groupsel', 'typesel']   // A shoot button

  connect() {
    let stim = this
    // console.info("SMSLOCK CONNECT", stim)

    $(stim.groupselTargets).each(function (s, sel) {
      let $sel = $(sel)
      let ctxt = { stim: stim, form: $sel.parents('form').first() }
      // console.log('--- grpsel:', $sel, ctxt)
      $sel
        .each(function () { stim.set_groupsel($sel, ctxt) })
        .on('change', ctxt, stim.groupsel)
    })


    $(stim.typeselTargets).each(function (s, sel) {
      let $sel = $(sel)
      let ctxt = { stim: stim, form: $sel.parents('form').first() }
      // console.log('--- typesel:', $sel, ctxt)
      $sel
        .each(function () { stim.set_typesel($sel, ctxt) })
        .on('change', ctxt, stim.typesel)
    })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Event handlers / actions: these are mere wrappers for implementer function calls.
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // groupsel() responds to a change of notice group.
  //
  groupsel(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $gsel = $(svt.currentTarget)
    stim.set_groupsel($gsel, ctxt)
    return true
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // typesel() responds to change of notice type.
  //
  typesel(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $tsel = $(svt.currentTarget)
    stim.set_typesel($tsel, ctxt)
    return true
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // Implementers
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // set_groupsel(): enable or disable typesel options according to group selection.
  //
  // A notice may only be typed as 'initially checked' (typesel == '3') if it is not
  // grouped as 'SMS Texts' (groupsel == '1')
  //
  // This function will disable a typesel 'initially checked' option if groupsel is
  // 'SMS Texts', or enable that option otherwise.
  //
  set_groupsel($gsel, ctxt) {
    let disabled = $gsel.val() == '1'
    // console.log(`smslock#groupsel(${$gsel.val()}/${disabled}):`, svt)
    $('select[data-target="smslock.typesel"]', ctxt.form).each(function (t, tsel) {
      let $tsel = $(tsel)
      // console.log('---', $tsel)
      $tsel.find('option[value="3"]').prop('disabled', disabled)
    })
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // set_typesel(): enable or disable groupsel options according to type selection.
  //
  // A notice may only be in group 'SMS Texts' (groupsel == '1') if it is not
  // typed ed as 'initially checked' (typesel == '3')
  //
  // This function will disable a groupsel 'SMS Texts' option if typesel is
  // 'initially checked', or enable that option otherwise.
  set_typesel($tsel, ctxt) {
    let disabled = $tsel.val() == '3'

    // console.log(`smslock#typesel(${$tsel.val()}/${disabled}):`, svt)
    $('select[data-target="smslock.groupsel"]', ctxt.form).each(function (g, gsel) {
      let $gsel = $(gsel)
      // console.log('---', $gsel)
      $gsel.find('option[value="1"]').prop('disabled', disabled)
    })

  }

}
