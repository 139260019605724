// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// united - Service provider property UNIT EDitor
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'launch', 'form', 'pin']   // A shoot button

  connect() {
    let stim = this
    console.info("UNITED CONNECT:", stim)

    $(stim.launchTargets)
      .filter('[data-united]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let ctxt = { stim: stim, url: $btn.attr('href'), united: $btn.data('united') }
        $btn.on('click', ctxt, stim.launch)
      })

    $(stim.formTargets)
      .filter('[data-united]')
      .each(function (f, form) {
        let $form = $(form)
        let ctxt = { stim: stim, url: $form.attr('action'), united: $form.data('united') }
        $form.on('submit', ctxt, stim.submit)
      })
  }

  launch(svt) {
    // console.log('united#launch:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this

    //
    // Callback with gateway details fetched from the server.
    // This function is called after the ajax request for gateway details
    // completes successfully.
    let done_fn = function (stim, ctxt) {
      return function (data, status, xhr) {
        let resp = xhr.responseJSON
        if (resp) {
          // console.log('united#launch#done', xhr, data, resp, ctxt)
          let $modal = $(ctxt.united.modal)
          let $form = $('form', $modal)
          // reset the form to prevent previous errors from displaying
          if ($form.length >= 1) {
            $form[0].reset()
          }
          //
          $modal.modal('show')
          for (let m in resp) {
            let model = resp[m]
            if (model) {
              for (let f in model) {
                let name = `[${f}]`
                let $input = $(`:input[name$="${name}"]`, $form)
                if ($input.is(':checkbox')){
                  if (model[f].toString() == 'true'){
                    $input.prop('checked', true)
                  }
                  else {
                    $input.prop('checked', false)
                  }
                }
                else {
                  $input.val(model[f])
                }
              }
            }
          }
          $form.trigger('loaded')
        }
      }
    }
    let fail_fn = function (stim, ctxt) {
      return function (xhr, status, error) {
        let resp = xhr.responseJSON
        // console.log('united#launch#fail', xhr, error)
        // debugger
      }
    }

    //
    // Go no farther with the click event... we'll take it from here.
    svt.stopImmediatePropagation()
    svt.preventDefault()

    $.get({ url: ctxt.url })
      .then(done_fn(stim, ctxt), fail_fn(stim, ctxt))

    return false
  }

  submit(svt) {
    // console.log('united#submit:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $form = $(svt.currentTarget)
    let $modal = $form.parents('div.modal').first()
    let $id = $(`input[name$="${ctxt.united.id}"]`, $form)
    let id = parseInt($id.val())

    let method = isNaN(id) || !id ? 'post' : 'patch'
    let url = method === 'post' ? ctxt.united.post : ctxt.united.put.replace(/\/\d+$/, `/${id}`)
    $form.attr('action', url)
    $form.attr('method', method)
    // debugger
    // svt.stopImmediatePropagation()
    // svt.preventDefault()
    // $modal.modal('hide')
    return true
  }

  //
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // change_pin_code: Change unit pin_code via behind-the-scenes callout.
  //
  // This function submits an ajax call that returns a new pin code for this unit that
  // is guaranteed unique within its parent property.
  //
  // The results of the callout are used to update the form controls. The form itself
  // is NOT submitted by this action. Rather its fields may be updated.
  //
  change_pin_code(svt, check_disabled = false) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget).parents('form[data-target*="united.form"]').first()
    console.log("united#new_pin_code:", svt, $form)
    if ($form) {
      let united = $form.data('united')
      let $unit = $(':input[name="property_unit[unit_id]"]', $form)
      let $pin = $(':input[data-target*="united.pin"]', $form)
      console.log('--- for unit/pin:', $unit, $pin, united)
      if ($unit.length && $pin.length) {
        let ctxt = {
          stim: stim,
          form: $form,
        }
        let done_fn = function (ctxt) {
          return function (data, status, xhr) {
            let resp = xhr.responseJSON
            // debugger
            if (resp) {
              let $form = ctxt.form
              for (let f in resp) {
                let $input = $(`:input[name$="[${f}]"]`, $form)
                if (!ctxt.check || !$input.prop('disabled')) {
                  $input.val(resp[f])
                }
              }
            }
          }
        }

        let fail_fn = function (ctxt) {
          return function (xhr, status, error) {
            let resp = xhr.responseJSON
            // debugger
          }
        }


        $.ajax({
          type: 'POST',
          url: united.pin,
          // data: $.param(qdata),
          dataType: 'json'
        }).then(done_fn(ctxt), fail_fn(ctxt))

      }
    }
    return true
  }
}
