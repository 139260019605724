// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'
// This js will intercept a form submit, get a new token from google recaptcha API
// and then populate the hidden key field.  Time-outs, stale keys and all that non-sense should
// disappear
// prerequisites:
// - Google API loaded on the page with the correct API key
//   <script src="https://www.google.com/recaptcha/api.js?render=#{RECAPTCHA_SITE_KEY}"></script>
// - data-key attribute on form with key
// - data-recap_action attribute on form with action that recap will preform
// - Hidden input in form with name recaptcha_token.  Application helper has a method
//   recaptcha_input to facilitate.

export default class extends Controller {
    static targets = ['form']     // Form using google recaptcha API

    connect() {
        console.info("Recaptcha CONNECT")
        let stim = this
        $(stim.formTargets)
            .each(function (i, form) {
                let $recap_form = $(form)
                $(form).one('submit', stim, stim.refresh_recap_token)
        })
    }

    refresh_recap_token(svt){
        //debugger
        svt.preventDefault()
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        let $recap_form = $(svt.currentTarget)
        let key = $recap_form.data('key')
        let action = $recap_form.data('recap-action')
        //debugger

        grecaptcha.execute(key, {action: action}).then(function(token) {
            $recap_form.find('input[name="recaptcha_token"]')[0].value = token
            $recap_form.submit()
        });
    }


}
