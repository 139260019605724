// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side input locking controls.
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'key']        // Clickable key to a locket. Requires data-key-to selector too

  connect() {
    console.info("LOCKET CONNECT")
    let stim = this
    $(stim.keyTargets).filter('[data-key-to]').each(function (i, elem) {
      let $key = $(elem)
      $key.attr('title', 'Click here to unlock this field').find('>i.md-glyph').text('lock')
      $($key.data('key-to')).filter(':input').prop('disabled', true).toggleClass('safe', true)
    })
    stim.reset()
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTIONS AND JQ EVENT HANDLERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // reset: reset password-related form content
  //
  // This controller method resets all password inputs to password type,
  // resets all password reveal buttons to their 'show' state, and erases
  // any feedback containers.
  reset(svt) {
    console.info('locket#reset', svt)
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
  }

  soft(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    stim.switch(svt)
  }

  hard(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    stim.switch(svt, true)
  }

  switch(svt, hard = false) {
    let selector = $(svt.currentTarget).data('key-to')
    console.log('locket#switch', selector, hard)
    if (selector.length) {
      let $glyph = $('>i.md-glyph', svt.currentTarget)
      let glyph = $glyph.text()
      let disable = glyph !== 'lock'
      $(svt.currentTarget).attr('title', disable ? 'Click to unlock this field' : 'Click to lock this field')
      $glyph.text(disable ? 'lock' : 'lock_open')
      $glyph.toggleClass('text-danger', !disable)
      $(selector).filter(':input').prop('disabled', disable).toggleClass('safe', disable)
      if (hard) {
        $(selector).filter('input').val('')
      }
    }
  }

}
