// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Client-side account password validation and form submission.
//
// This controller is all about account passwords: showing them or hiding them;
// validating them; submitting their forms; and reporting any errors.
//
// By convention: stimulus event handlers accept stimulus event arguments ('svt')
// whereas JQ event handlers accept JQ event arguments ('jvt')
//
import { Controller } from 'stimulus'
// import parsePhoneNumber from 'libphonenumber-js'
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js'

export default class extends Controller {
  static targets = [
    'form',       // A change-password or signin form that can be validated or submitted
    'username',   // A username input
    'current',    // A current password input
    'new',        // A new password input
    'confirm',    // A new password confirmation input
    'notify',     // A notification container for form-submit sresult notification
    'recover',    // A password recovery button
    'reveal']     // A password show/hide button

  connect() {
    console.info("PASSWD CONNECT")
    let stim = this
    stim.reset()

    $(stim.recoverTargets)
      .filter('[data-recover-url]')
      .each(function (b, btn) {
        let $btn = $(btn)
        let ctxt = { stim: stim, url: $btn.data('recover-url'), modal: $btn.data('recover-modal') }
        $btn.on('click', ctxt, stim.recover)
      })
  }

  recover(svt) {
    console.log('passwd#recover:', svt)
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    $.get({ url: ctxt.url })
    if (ctxt.modal) {
      let $modal = $(ctxt.modal)
      if ($modal.length) {
        svt.stopImmediatePropagation()
        svt.preventDefault()
        $modal.modal('show')
      }
    }
    return true
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  //
  // ACTIONS AND JQ EVENT HANDLERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // reset: reset password-related form content
  //
  // This controller method resets all password inputs to password type,
  // resets all password reveal buttons to their 'show' state, and erases
  // any feedback containers.
  reset(svt) {
    console.info('passwd#reset', svt)
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    $(stim.usernameTargets).filter('input').removeClass('is-valid is-invalid')
    $(stim.currentTargets).filter('input').removeClass('is-valid is-invalid').attr('type', 'password').val('')
    $(stim.newTargets).filter('input').removeClass('is-valid is-invalid').attr('type', 'password').val('')
    $(stim.confirmTargets).filter('input').removeClass('is-valid is-invalid').attr('type', 'password').val('')
    $(stim.revealTargets).find('>i.md-glyph').text('visibility')
    $(stim.formTargets).find('div.passwd-feedback').remove()
    $(stim.notifyTargets).empty()
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // switch: toggle password visibility
  //
  // This stimulus action handler is bound to a password visibility button.
  // It will toggle password input visibility according to the glyph that is
  // currently shown by the button:
  //
  // visibility:     switches input type to 'text' and glyph to 'visibility_off'
  // visibility_off: switches input type to 'password' and glyph to 'visibility'
  //
  // The button is expected to hold a jquery selector string in a 'data-selector'
  // attribute that identifies which password inputs this button controls.
  //
  switch(svt) {
    let selector = $(svt.currentTarget).data('selector')
    let $glyph = $('>i.md-glyph', svt.currentTarget)
    let glyph = $glyph.text()
    if (selector.length) {
      $(selector).filter('input').attr('type', glyph === 'visibility' ? 'text' : 'password')
      $glyph.text(glyph === 'visibility' ? 'visibility_off' : 'visibility')
    }
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // validate: validate all username and password fields in a form, either after something
  // has changed, or before the form submits.
  //
  // There _is_ a difference: if called as part of 'submit', the submission will be cancelled
  // if any errors are detected.
  //
  validate(svt) {
    console.log('passwd#validate:', svt)
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let submitting = (svt.type === 'submit')
    let errors = (svt.currentTarget instanceof (HTMLFormElement)) ? stim.validate_form($(svt.currentTarget), submitting) : 0

    //
    // Prevent submission if errors have been detected and 'submit'
    // was the originating event
    if (errors && submitting) {
      svt.stopImmediatePropagation()
      svt.preventDefault()
    }
    return errors
  }


  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // submit: validate all form password fields, and submit the form to the server
  //
  submit(svt) {
    console.log('passwd#submit:', svt)
    let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
    let $form = $(svt.currentTarget)
    let $current = $form.find('[data-target="passwd.current"]')
    let $new = $form.find('[data-target="passwd.new"]')
    let $confirm = $form.find('[data-target="passwd.confirm"]')
    let errors = 0
    //
    // This will be passed as data to Ajax...
    let postdata = {
      _method: $('input[name="_method"]', $form).val(),
    }

    //
    ///////////////////////////////////////////////////////////////////////////////////////
    // Ajax Asynchronous Response Closures
    //
    // Here we create two closures to handle asynchronous 'done' and 'fail' callbacks from
    // Ajax. We use closures so that the handlers can access the stimulus controller and
    // the form JQ object.
    //
    // Submit Success:
    //
    // The success response from the controller will be empty. Clean-up the form and post
    // a smiley.
    //
    let done_fn = function (stim, $form) {
      return function (data, status, xhr) {
        stim.reset()
        stim.dismissable($form, gon.passwd_controller.password_changed_message, 'alert-success')
      }
    }

    //
    // Submit Failure:
    //
    // The response will be a hash of input names to errors. Use those
    // here to to apply the necessary feedback messages.
    let fail_fn = function (stim, $form) {
      return function (xhr, status, error) {
        let resp = xhr.responseJSON
        let map = {
          current_password: $form.find('[data-target="passwd.current"]'),
          password: $form.find('[data-target="passwd.new"]'),
          password_confirmation: $form.find('[data-target="passwd.confirm"]')
        }
        for (let x in resp) {
          stim.feedback(map[x], resp[x])
          map[x]
            .toggleClass('is-valid', false)
            .toggleClass('is-invalid', true)
        }
        stim.dismissable($form, gon.passwd_controller.password_not_changed_message, 'alert-danger')
      }
    }

    //
    ///////////////////////////////////////////////////////////////////////////////////////
    // Form Submit Processing:
    //
    // First thing to do is prevent default submit action and propagation of the event:
    // because we are going to use ajax to submit the form by-hand.
    svt.stopImmediatePropagation()
    svt.preventDefault()

    //
    // Next, copy our form input values into the postdata hash and,
    // just for giggles, validate form inputs client-side as best we can.
    // Any errors detected here will prevent submission to avoid unnecessary work.
    errors = stim.validate_form($form, true)

    //
    // If there are no validation errors then we proceed with submission.
    // Construct a map of input names to value...
    if (!errors) {
      $current.each(function (i, e) {
        postdata[$(e).attr('name')] = $(e).val().trim()
      })
      $new.each(function (i, e) {
        postdata[$(e).attr('name')] = $(e).val().trim()
      })
      $confirm.each(function (i, e) {
        postdata[$(e).attr('name')] = $(e).val().trim()
      })

      //
      // FINALLY we submit the form by hand, as it were, that we can properly
      // deal with the result.
      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: $.param(postdata),
        dataType: 'json'
      }).then(done_fn(stim, $form), fail_fn(stim, $form))
    }

    return false
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  //
  // INTERNAL METHODS
  //
  // these exist for internal use only, and are NOT to be invoked as stimulus actions
  // or as JQ event handlers.
  //
  /////////////////////////////////////////////////////////////////////////////////////////
  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // feedback: append bootstrap form feedback message
  feedback($for, messages = [], cat = 'invalid') {
    let $parent = $for.parent()
    $parent.find('div.passwd-feedback').remove()
    if (messages.length && ['invalid', 'valid'].includes(cat)) {
      $parent.append('<div class="passwd-feedback ' + cat + '-feedback">' + messages.join(', ') + '</div>')
    }
    return messages.length
  }

  //
  // validate_username: check whether a current username input is valid or invalid
  validate_username($input, submitting = false) {
    let stim = this
    let errors = []
    let val = $input.val().trim()
    let reMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!$input.prop('disabled')) {
      let digits = val.replace(/[^0-9]/, '')
      let is_mail = reMail.test(val)
      let is_phone = isPossiblePhoneNumber(digits, 'US')
      // debugger
      if (!val.length || !(is_mail || is_phone)) {
        if (submitting) {
          errors.push(gon.passwd_controller.email_validation)
        }
      }

      if (submitting) {
        $input.val(val)
      }
    }
    let is_valid = false
    let is_invalid = (errors.length > 0)

    $input
      .toggleClass('is-valid', is_valid)
      .toggleClass('is-invalid', is_invalid)

    return stim.feedback($input, errors, (is_invalid ? 'invalid' : (is_valid ? 'valid' : '')))
  }

  //
  // validate_current: check whether a current password input is valid or invalid
  validate_current($input, submitting = false) {
    let stim = this
    let errors = []
    let val = $input.val().trim()

    if (!$input.prop('disabled')) {
      if (!val.length) {
        if (submitting) {
          errors.push(gon.passwd_controller.password_validation)
        }
      }
      else if (val.length < 8) {
        errors.push(gon.passwd_controller.password_complexity)
      }
      if (submitting) {
        $input.val(val)
      }
    }
    let is_valid = false
    let is_invalid = (errors.length > 0)

    $input
      .toggleClass('is-valid', is_valid)
      .toggleClass('is-invalid', is_invalid)

    return stim.feedback($input, errors, (is_invalid ? 'invalid' : (is_valid ? 'valid' : '')))
  }

  //
  // validate_new: check whether a new password is valid or invalid
  validate_new($input, submitting = false) {
    let stim = this
    let errors = []
    let val = $input.val().trim()
    let $feedback = $input.siblings('div.feedback')

    //
    // Determine minimum password length by looking at data-passwd['minlen']
    // and ensure a minimum of ten characters.
    let qualifiers = $input.data('passwd') || { minlen: 10 }
    let minlen = parseInt(qualifiers['minlen'])
    console.log(`passwd#validate_new: minlen-data(${minlen})`)
    minlen = (isNaN(minlen) || minlen < 10) ? 10 : minlen
    console.log(`passwd#validate_new: minlen(${minlen})`)

    if (!$input.prop('disabled')) {
      if (!val.length) {
        if (submitting) {
          errors.push(gon.passwd_controller.new_password)
        }
      }
      else if (val.length < minlen) {
        errors.push(gon.passwd_controller.new_password_complexity_1 + minlen + gon.passwd_controller.new_password_complexity_2)
      }
      else {
        let missing = []
        let symbols = "-^$*.[\\]{}()?\"!@#%&/,><':;|_~`"
        let reU = /[A-Z]/
        let reL = /[a-z]/
        let reN = /[0-9]/
        let reS = new RegExp(`[${symbols}]`)
        let reW = /[\s]/

        if (!reU.test(val)) {
          missing.push(gon.passwd_controller.password_tests.upper_case)
        }
        if (!reL.test(val)) {
          missing.push(gon.passwd_controller.password_tests.lower_case)
        }
        if (!reN.test(val)) {
          missing.push(gon.passwd_controller.password_tests.digit)
        }
        if (!(reS.test(val) || reW.test(val))) {
          missing.push(gon.passwd_controller.password_tests.symbol)
        }
        if (missing.length > 0) {
          if (missing.length > 1) {
            let and = missing.pop()
            let connecting_and = gon.passwd_controller.password_tests.connecting_and
            missing.push(`${connecting_and} ${and}`)
          }
          errors.push(gon.passwd_controller.password_tests.main_message + missing.join(', '))
        }
      }
    }

    let is_valid = (val.length > 11)
    let is_invalid = (errors.length > 0)
    $input
      .toggleClass('is-valid', is_valid)
      .toggleClass('is-invalid', is_invalid)

    return stim.feedback($input, errors, (is_invalid ? 'invalid' : (is_valid ? 'valid' : '')))
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // validate_confirm: check whether a password confirmation is valid or invalid
  validate_confirm($input, submitting = false) {
    let stim = this
    let errors = []
    let selector = $input.data('selector') || ''
    let is_valid = false
    let is_invalid = false

    if (selector.length && $(selector).length == 1) {
      let val = $input.val().trim()
      let $feedback = $input.siblings('div.feedback')
      let compare = $(selector).val().trim()

      if (!$input.prop('disabled')) {
        if (val.length) {
          if (compare.length && val != compare) {
            errors.push(gon.passwd_controller.password_matching)
          }
        }
        else if (submitting) {
          errors.push(gon.passwd_controller.new_password_retype)
        }
      }
      is_valid = (errors.length == 0 && val.length > 11 && val == compare)
      is_invalid = (errors.length > 0)
      $input
        .toggleClass('is-valid', is_valid)
        .toggleClass('is-invalid', is_invalid)
    }

    return stim.feedback($input, errors, (is_invalid ? 'invalid' : (is_valid ? 'valid' : '')))
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // validate_form: check all form password inputs
  validate_form($form, submitting = false) {
    let stim = this
    let errors = 0
    let $username = $form.find('[data-target="passwd.username"]')
    let $current = $form.find('[data-target="passwd.current"]')
    let $new = $form.find('[data-target="passwd.new"]')
    let $confirm = $form.find('[data-target="passwd.confirm"]')

    $username.each(function (i, e) {
      errors += stim.validate_username($(e), submitting)
    })

    $current.each(function (i, e) {
      errors += stim.validate_current($(e), submitting)
    })

    $new.each(function (i, e) {
      errors += stim.validate_new($(e), submitting)
    })

    $confirm.each(function (i, e) {
      errors += stim.validate_confirm($(e), submitting)
    })

    return errors
  }

  //
  /////////////////////////////////////////////////////////////////////////////////////////
  // dismissable: generate a dismissable bootstrap alert inside a 'notify' container.
  dismissable($form, message, cat = '') {
    $('div[data-target="passwd.notify"]', $form)
      .html('<div class="alert alert-dismissable fade show ' + cat + '" role="alert">' +
        '<span>' + message + '</span>' +
        '<button class="close" type="button" data-dismiss="alert" aria-label="close">' +
        '<span aria-hidden="true"><i class="md-glyph">close</i></span></button></div>')
  }

}
