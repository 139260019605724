// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// tabsel - Select table cells or rows using custom checkboxes
//
// Written originally to produce and manage pseudo-checkboxes in table columns,
// but later adapted to work with real checkboxes too.
//
// The table thing is utterly irrelevant, and merely descriptive of original intent.
// Which was to generate a linked set of checkboxes for 'selecting' table rows, but which
// could all be switched on or off by clicking on a 'bossbox' in the table header.
//
// Later came to use ths module for opt-in selections, where bossboxes can select or deselect
// all e-mail opt-ins, or all text opt-ins.
//
// check_box_outline_blank  e835
// check_box                e834
// indeterminate_check_box  e909
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['table', 'bossbox', 'cellbox', 'action', 'ignored_element', 'disclaimer_check', 'disable_after_click']

  connect() {
    let stim = this;
    // console.log('TABSEL#connect')

    /* Start
        Code to ignore events from designated elements to be used for disclaimers.
        The driving checkbox for allowing/disallowing the selection is target = disclaimer_check
        This is the main checkbox that will need to be checked to allow the selections
        Assumed:
         - data-target for elements : ignored_element
         - each ignored element will need a data attribute 'ignore_class' limited to the section being controlled
   */
    $(stim.ignored_elementTargets).each(function (i, ignoredElementTarget) {
      $(ignoredElementTarget).on("click", this, function (e) {
        // console.log('tabsel#ignored:', this)
        let currentObj = $(this);
        let ignore_class = currentObj.data("ignore-class");
        let $form = currentObj.parents("form").first();
        let agreed = $(
          `input[data-disclaimer-class='${ignore_class}'`,
          $form
        ).is(":checked");
        let $dis_alert = $(".disclaimer-alert", $form);
        //debugger;
        if (currentObj.hasClass(ignore_class) && !agreed) {
          currentObj.attr("title", gon.tabsel_controller.accept_terms);
          e.stopImmediatePropagation();
          e.preventDefault();
          currentObj.tooltip("show");
          $dis_alert.removeClass("invisible");

          setTimeout(function () {
            currentObj.tooltip("hide");
            currentObj.attr("title", "");
            currentObj.data("original-title", "");
          }, 2000);

          setTimeout(function () {
            $dis_alert.addClass("invisible");
          }, 10000);
        } else {
          currentObj.tooltip("hide");
          $dis_alert.addClass("invisible");
        }
      });
    });
    // end

    /* Start
      Code to drive ignored element behaviour.
      Assumed:
        - data-target : disclaimer_check
        - data attribute disclaimer-class : This will allow the selectors of the checkboxes it is controlling
        by data selectors

   */
    $(stim.disclaimer_checkTargets).each(function (i, disclaimer_checkTarget) {
      $(disclaimer_checkTarget).on("click", this, function (e) {
        let $form = $(this).parents("form").first();
        let currentObj = $(this);
        let ignored_class = $(this).data("disclaimer-class");
        let input_selector =
          "input[data-ignore-class=" + "'" + ignored_class + "'" + "]:enabled";
        let span_selector =
          "span[data-ignore-class=" + "'" + ignored_class + "'" + "]";
        let ignored_input_class_elements = $(input_selector, $form);
        let ignored_span_class_elements = $(span_selector, $form);
        let mandatory = $(this).hasClass("mandatory");
        let $dis_alert = $(".disclaimer-alert", $form);

        /*
        if (mandatory) {
          currentObj.attr('title', gon.tabsel_controller.mandatory);
          e.stopImmediatePropagation()
          e.preventDefault()
          currentObj.tooltip('show');
          setTimeout(function () {
              currentObj.tooltip('hide');
              currentObj.attr('title', '');
              currentObj.data('original-title', '');
          }, 2000);
          return
        } else {
          currentObj.tooltip('hide')
        }
        */

        if ($(this).is(":checked")) {
          // console.log('tabsel#disclaimer.checked:', this, ignored_input_class_elements, ignored_span_class_elements)
          // remove all classes
          $dis_alert.addClass("invisible");
          if (ignored_input_class_elements.length > 0) {
            ignored_input_class_elements.removeClass(ignored_class);
          }
          if (ignored_span_class_elements.length > 0) {
            ignored_span_class_elements.removeClass(ignored_class);
          }
        } else {
          // console.log('tabsel#disclaimer.unchecked:', this, ignored_input_class_elements, ignored_span_class_elements)
          // remove all checks and add classes
          if (ignored_input_class_elements.length > 0) {
            ignored_input_class_elements.prop("checked", false);
            ignored_input_class_elements.addClass(ignored_class);
          }
          if (ignored_span_class_elements.length > 0) {
            ignored_span_class_elements
              .find("i")
              .html("check_box_outline_blank");
            ignored_span_class_elements.data("tabsel").count = 0;
            ignored_span_class_elements.addClass(ignored_class);
          }
        }
      });
    });
    // end

    //
    // Boss cells: boss cells occupy table header slots -- typically for columns with
    // matching selector cells -- where they provide the ability to switch all related
    // cells on or off.
    $(stim.bossboxTargets)
      .filter("[data-tabsel]")
      .each(function (i, boss) {
        let $cell = $(boss);
        let config = $cell.data("tabsel");
        let $table = $cell.parents("table").first();
        config.count = 0;
        $cell.data("tabsel", config);
        //
        // Ensure that the cell has a marker
        if ($cell.has("i.md-glyph").length < 1) {
          $cell.append('<i class="md-glyph md-glyph-outlined">check_box</i>');
        }
        let $marker = $("i.md-glyph", $cell).first();

        let ctxt = {
          stim: stim,
          table: $table,
          boss: $cell,
          marker: $marker,
          check: null,
          config: config,
        };
        $cell.addClass(`tabsel-boss tabsel-boss-${config.id}`);
        // console.log('tabsel.bossbox:', $cell)
        $marker.text("check_box_outline_blank");

        $cell
          .on("click", ctxt, stim.boss_toggle)
          .on("tabsel:inc", ctxt, stim.boss_inc)
          .on("tabsel:dec", ctxt, stim.boss_dec);
      });

    //
    // Cell targets: check boxes embedded in a table cell
    $(stim.cellboxTargets)
      .filter("[data-tabsel]")
      .has('input[type="checkbox"]')
      .each(function (i, cell) {
        let $cell = $(cell);
        let config = $cell.data("tabsel");
        let $table = $cell.parents("table").first();
        // let $boss = $('> thead > tr > th span[data-tabsel]', $table).filter(function () { let x = $(this).data('tabsel'); return x.id == config.id; })
        let $check = $('input[type="checkbox"]', $cell);
        //
        // Ensure that the cell has a marker
        if ($cell.has("i.md-glyph").length < 1) {
          $cell.append('<i class="md-glyph md-glyph-outlined">check_box</i>');
        }
        let $marker = $("i.md-glyph", $cell).first();

        let ctxt = {
          stim: stim,
          table: $table,
          marker: $marker,
          check: $check,
          config: config,
        };
        $cell.addClass(`tabsel-cell tabsel-cell-${config.id}`);
        $marker.text("check_box_outline_blank");
        // $check.prop('checked', false)
        if ($check.prop("checked")) {
          $marker.text("check_box");
        }

        if (!$check.prop("disabled")) {
          $cell
            .on("click", ctxt, stim.cell_toggle)
            .on("tabsel:set", ctxt, stim.cell_set)
            .on("tabsel:clear", ctxt, stim.cell_clear);
        }
      });

    //
    // Unadorned checkbox targets (solocells):
    //
    $(stim.cellboxTargets)
      .filter('input[type="checkbox"][data-tabsel]')
      .each(function (i, check) {
        let $check = $(check);
        let config = $check.data("tabsel");
        let $table = $check.parents("table").first();
        let ctxt = {
          stim: stim,
          table: $table,
          marker: null,
          check: $check,
          config: config,
        };
        $check.addClass(`tabsel-cell tabsel-cell-${config.id}`);

        if (!$check.prop("disabled")) {
          $check
            .on("click", ctxt, stim.cell_toggle)
            .on("tabsel:set", ctxt, stim.cell_set)
            .on("tabsel:clear", ctxt, stim.cell_clear);
        }
      });

    //
    // When all cellboxes have been set-up, cue the initial counts on the bossboxes
    // to reflect the initial state of the check boxes.
    $(stim.cellboxTargets)
      .filter("[data-tabsel]")
      .each(function (i, cell) {
        let $cell = $(cell);
        let $check = $('input[type="checkbox"]', $cell);
        let config = $cell.data("tabsel");
        let $bossbox = $(`.tabsel-boss-${config.id}`);

        $check = $check.length === 1 ? $check : $cell;
        // console.log('tabsel.check.init:',)
        if (!$check.prop("disabled") && $check.prop("checked")) {
          $bossbox.trigger("tabsel:inc");
        }
      });

    // Check to see if there is an event or if we just
    // organically connected the stim controller
    // skip actions below if we are calling via an event
    // LA
    // this test and condition below
    // was removed due to the new connect done via a remote partial loading

    //let perform_disable_routines = ((typeof event === 'undefined') || ((typeof event === 'object') && !(event.srcElement.classList.contains('check')) ))

    //
    // Hide any bossboxes that have no contributors.
    //
    // Again, after all cellboxes have been set-up, remove boss check boxes that
    // have zero feeder cells. Because clicking the damned things will do nothing,
    // and is just begging for support calls and QA slack bleating.
    //if (perform_disable_routines) {
    $(stim.bossboxTargets)
      .filter("[data-tabsel]")
      .each(function (i, boss) {
        let $cell = $(boss);
        let config = $cell.data("tabsel");
        let $cells = $(`.tabsel-cell-${config.id}`).not(":disabled");
        // console.log(`tabsel::bossbox(${config.id}):`, $cells)
        if ($cells.length < 1) {
          $("i.md-glyph", $cell).addClass("blank");
        } else {
          $("i.md-glyph", $cell).removeClass("blank");
        }
      });

    //
    // Action buttons: these are buttons that operate upon selected cells.
    // They are presumed siblings of a boss cell, and will be enabled or disabled
    // by the presence or absence of selected cells.
    //
    // All that tabsel does with these is tweak their 'disabled' property.
    //
    $(stim.actionTargets)
      .filter("button[data-tabsel]")
      .each(function (b, btn) {
        let $btn = $(btn);
        let config = $btn.data("tabsel");
        let ctxt = { stim: stim, config: config };

        let $cells = $(`.tabsel-cell-${config.id}`).not(":disabled");
        let disabled = $cells.length < 1 ? true : false;

        $btn
          .addClass(`tabsel-action tabsel-action-${config.id}`)
          .prop("disabled", disabled)
          .on("click", ctxt, stim.launch_action);
      });

    $(stim.disable_after_clickTargets).each(function (b, btn) {
      $(btn).on("click", this, function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        $(this).prop("disabled", true);
        $(this).siblings("div.alert").removeClass("d-none");
        let form = $(this).parents().closest("form");
        let action = form.attr("action");
        $.ajax({
          type: "POST",
          url: action,
          data: form.serialize(),
        }).then(
          function (data) {},
          function (data) {
            alert("Problem encountered please contact our offices!!!");
          }
        );
      });
    });

    //}
  }

  //
  // toggle: Respond to cellbox click by toggling its selected state from checked to unchecked or vice-versa.
  //
  cell_toggle(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let solocell = (ctxt.marker === null)
    // console.log('tabsel#cell_toggle', svt, ctxt)

    //
    // For regular cells -- those that are fake checkboxes -- this is the end of the line for click event handling.
    // But for solocells it is entirely feasible that other click handlers are queued on these inputs that must be
    // serviced too. In _those_ cases the event must be allowed to propagate.
    if (!solocell) {
      svt.stopImmediatePropagation()
      svt.preventDefault()
    }

    //
    // Jiggery-pokery: this is an XOR operation.
    // For a solocell -- an actual checkbox -- the 'checked' property is actual. The browser sets it.
    // But for a wrapping cell the 'checked' property is artificial and rests with us to toggle.
    let checked = ctxt.check.prop('checked') ? solocell : !solocell
    let glyph = checked ? 'check_box' : 'check_box_outline_blank'
    let hit_with = checked ? 'tabsel:inc' : 'tabsel:dec'

    //
    if (!solocell) {
      ctxt.check.prop('checked', checked)
      ctxt.marker.text(glyph)
    }

    $(`.tabsel-boss-${ctxt.config.id}`).trigger(hit_with)

    return solocell
  }

  //
  // Set (check) a checkbox cell and update its boss count.
  cell_set(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    // console.log('tabsel#cell_set', svt, ctxt)
    svt.stopImmediatePropagation()
    svt.preventDefault()
    if (!ctxt.check.prop('checked') && !ctxt.check.prop('disabled')) {
      ctxt.check.prop('checked', true)
      if (ctxt.marker) ctxt.marker.text('check_box')
      $(`.tabsel-boss-${ctxt.config.id}`).trigger('tabsel:inc')
    }
    return false
  }

  //
  // Clear (uncheck) a checkbox cell and update its boss count.
  cell_clear(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    // console.log('tabsel#cell_clear', svt, ctxt)
    svt.stopImmediatePropagation()
    svt.preventDefault()
    if (ctxt.check.prop('checked') && !ctxt.check.prop('disabled')) {
      ctxt.check.prop('checked', false)
      if (ctxt.marker) ctxt.marker.text('check_box_outline_blank')
      $(`.tabsel-boss-${ctxt.config.id}`).trigger('tabsel:dec')
    }
    return false

  }

  //
  // Toggle all dependents of a boss.
  //
  // toggle is too simple: it will either check or uncheck all of its dependents,
  // according to its current state.
  //
  // Called in response to clicking on a bossbox.
  boss_toggle(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let $boss = $(svt.currentTarget)
    // console.log('tabsel#boss_toggle', svt, ctxt)

    svt.stopImmediatePropagation()
    svt.preventDefault()

    let config = $boss.data('tabsel')
    let selector = `.tabsel-cell-${config.id}`
    // console.log('tabsel#boss_toggle->', $(selector))
    $(selector).trigger(config.count > 0 ? 'tabsel:clear' : 'tabsel:set')
    return false
  }

  //
  // Increment the count of checked boxes associated with a bos, and update its icon
  // accordingly.
  //
  // Typically triggered when a dependent checkbox is checked.
  boss_inc(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let $boss = $(svt.currentTarget)
    // console.log('tabsel#boss_inc', svt, ctxt)

    svt.stopImmediatePropagation()
    svt.preventDefault()

    let config = $boss.data('tabsel')
    config.count += 1
    $boss.data('tabsel', config)

    if (ctxt.marker) {
      let total = $(`input[type="checkbox"].tabsel-cell-${config.id}`).not(':disabled')
      ctxt.marker.text(config.count == total.length ? 'check_box' : 'indeterminate_check_box')
    }

    //
    // Ensure that any sibling action buttons aer enabled after the first check
    // on a cell occurs: because _now_ those actions have at least one cell to
    // work upon.
    if (config.count === 1) {
      $boss.siblings(`.tabsel-action-${config.id}`).prop('disabled', false)
    }

    return false
  }

  //
  // Decrement the count of checked boxes associated with a bos, and update its icon
  // accordingly.
  //
  // Typically triggered when a dependent checkbox is unchecked.
  boss_dec(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let $boss = $(svt.currentTarget)
    // console.log('tabsel#boss_dec', svt, ctxt)

    svt.stopImmediatePropagation()
    svt.preventDefault()

    let config = $boss.data('tabsel')
    if (config.count > 0) {
      config.count -= 1
      $boss.data('tabsel', config)
      if (config.count < 1) {
        if (ctxt.marker) ctxt.marker.text('check_box_outline_blank')
        $boss.siblings(`.tabsel-action-${config.id}`).prop('disabled', true)
      }
      else {
        if (ctxt.marker) ctxt.marker.text('indeterminate_check_box')
      }
    }

    return false
  }


  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // HACK ALERT:
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  // Here-on out is a total HACK, with handlers written for specific actions for specific
  // views.
  //
  //
  launch_action(svt) {
    let stim = (svt && svt instanceof jQuery.Event) ? (svt.data['stim'] || svt.data) : this
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let $boss = $(svt.currentTarget)
    // console.log('tabsel#launch_action', svt, ctxt)

    if (ctxt.config.hasOwnProperty('action')) {
      switch (ctxt.config.action) {
        case 'destroy-units': return stim.show_modal(svt, ctxt, 'unit', 'destroy');
        case 'recode-units': return stim.show_modal(svt, ctxt, 'unit', 'recode');
        case 'destroy-notices': return stim.show_modal(svt, ctxt, 'notice', 'destroy');
        case 'clear-notices': return stim.show_modal(svt, ctxt, 'notice', 'clear');
        default:
          // console.log(`tabsel#launch_action ${ctxt.config.action} is not supported!`);
          break;
      }
    }
    return true
  }


  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  //
  //
  show_modal(svt, ctxt, what, action) {
    let $btn = $(svt.currentTarget)
    let $form = $btn.parents('form').first()
    let $modal = $(ctxt.config.modal)
    let $selected = $('input[type="checkbox"]:checked', $form)

    if ($selected.length > 0) {
      if ($selected.length == 1) $('span[name="what"]', $modal).text(`a ${what}`)
      else $('span[name="what"]', $modal).text(`${$selected.length} ${what}s`)

      //
      // Record our action in the batch operation input.
      $('input[name$="[batchop]"]', $form).val(action)
      //
      // Launch the modal
      $modal.modal('show')

      //
      // Leave nothing behind.
      svt.stopImmediatePropagation()
      svt.preventDefault()
      return false
    }
    return true

  }
}
