import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [
        'form'
    ]

    initialize() {
        console.info("PAYMENT CHANGES INITIALIZE")
    }

    connect() {
        let stim = this
        let ctxt = {stim: stim}
        $(this.formTargets)
            .on('change', ctxt, stim.set_payment_state)
            .each(function (f, form) {
                stim.set_payment_state(ctxt, form)
            })
    }

    set_payment_state(svt, form = null) {
        // This method will fire set the form
        // changes accordingly ( using the form changes method )
        let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
        let stim = (ctxt) ? ctxt.stim : this

        let sub_id = $(this).data('subId')
        if (sub_id === undefined) {
            sub_id = $(form).data('subId')
        }

        let selected_plan_billable = $(`#plans-${sub_id}_subscription_preview_tier_id`).find(":selected").data('billable')
        let selected_payment_valid = $(`input.payment-radio-${sub_id}:radio:checked`).data('valid')

        let allow_transaction = true

        if (selected_plan_billable == true && selected_payment_valid == false) {
            allow_transaction = false
        }

        stim.form_changes(sub_id, allow_transaction)
    }

    form_changes(sub_id, valid = false) {
        // This method will set the current form to its state depending
        // on the choices.
        // - A valid card payment will allow the normal form selections.
        // - A non billable tier/plan will not worry about payment.
        // - A billable tier with a bad payment will notify and not allow form submission
        let submit_div = `#submit-${sub_id}-div`
        let submit_button = `#submit-${sub_id}-button`
        let payment_alert = `#payment-alert-${sub_id}`
        if (valid) {
            $(submit_div).removeClass('d-none')
            $(submit_button).removeProp('disabled')
            $(payment_alert).addClass('d-none')
        } else {
            $(submit_div).addClass('d-none')
            $(submit_button).prop('disabled', true)
            $(payment_alert).removeClass('d-none')
        }

    }
}