// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
//
/////////////////////////////////////////////////////////////////////////////////////////
// Conjoin: binds a select input to a group of radio buttons such that any change in
// selection will cause the corresponding radio button to be clicked too.
//
// Used in resident home page subscription plan selection to use a select box to drive
// a series of plan descriptions, each with its own radio button. The radio buttons are
// used as actual plan select inputs, whereas the select is used to preview plan details.
//
// The select tag should be mapped to conjoined.radio action; and the selector and all
// related radio buttons should have a data-conjoined hash with a 'group' element that
// provides a common name for the group. Any change in select will translate to a change
// in the group element that matches on value.
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['radio']

  connect() {
    let stim = this
    console.info("CONJOIN CONNECT", stim)

    //
    $(stim.radioTargets)
      .filter('[data-conjoin]')
      .each(function (e, elem) {
        let $elem = $(elem)
        let conjoin = $elem.data('conjoin')
        let $form = conjoin.hasOwnProperty('form') ? $(conjoin.form) : $elem.closest('form')
        let ctxt = { stim: stim, conjoin: conjoin, form: $form, elem: $elem, src: null }
        $elem.on('change', ctxt, stim.radio)
      })
  }

  //
  //
  radio(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $targets = $('input[type="radio"][data-conjoin]', ctxt.form)
      .not(':disabled')
      .filter('[data-conjoin]')
      .filter(function () { let x = $(this).data('conjoin'); return x.hasOwnProperty('group') && x.group == ctxt.conjoin.group })
    // debugger
    $targets.each(function (t, target) {
      if ($(target).val() == ctxt.elem.val()) {
        $(target).trigger('click')
      }
    })
    return (true)
  }

}
