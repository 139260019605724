// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

// This JS will display an overlay spinner when a form is
// submitted
// expects page to have a div for the spinner
//<div id="overlay" style="display:none;">
//    <div id="loader" class="spinner-border">
//    </div>
//</div>


export default class extends Controller {
    static targets = ['form', 'test_button', 'link']

    connect() {
        let stim = this
        $(stim.formTargets).each(function (i, form) {
            $(form).on('submit', stim, stim.show_overlay_spinner)
        })

        $(stim.test_buttonTargets).each(function (i, test_button) {
            $(test_button).on('click', stim, stim.show_overlay_spinner)
        })

        $(stim.linkTargets).each(function (i, link) {
            $(link).on('click', stim, stim.show_overlay_spinner)
        })

    }

    show_overlay_spinner(svt) {
        // svt.preventDefault()
        let spinner_overlay = $('#overlay');
        spinner_overlay.show();
    }

    spinnerConnected(event) {
        console.log(event.target) // the element that was just connected
        $(event.target).on('click', this, this.show_overlay_spinner)
    }

}