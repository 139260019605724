// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import {Controller} from "stimulus";

// This controller will submit ransack forms and
// return a partial in a designated div:

// data-render-div : Div id where returned information should
// be rendered

export default class extends Controller {
    static targets = ['form', 'csvlink']

    initialize() {
        let stim = this
        $(stim.formTargets).each(function (f, form) {
            let $form = $(form)
            $form.on('submit', stim, stim.setupForm)
        })

        $(stim.linkTargets).each(function (f, link) {
            let $link = $(link)
            $link.on('click', stim, stim.setupLink)
        })
    }

    linkConnected(event) {
        console.log(event.target) // the element that was just connected
        event.stopImmediatePropagation()
        event.preventDefault()
        $(event.target).on('click', this, this.setupLink)
    }

    setupForm(svt){
      let stim = svt && svt instanceof jQuery.Event ? svt.data : this;
      let $form = $(svt.currentTarget);

      if (!$form.data("clear")) {
        $("#" + $form.data("render-div")).html("");
      }
      $.ajax({
        method: $form.attr("method").toUpperCase(),
        url: $form.attr("action"),
        data: $form.serialize(),
        dataType: "json",
      }).then(
        function (data) {
          if (typeof $form.data("spinner-div-id") !== "undefined") {
            $("#" + $form.data("spinner-div-id")).hide();
          }

          $("#" + $form.data("render-div")).html(data.partial);
        },
        function (data) {
          if (typeof $link.data("spinner-div-id") !== "undefined") {
            $("#" + $link.data("spinner-div-id")).hide();
          }
          alert("Problem encountered!!! Contact offices!!");
        }
      );
      svt.stopImmediatePropagation();
      svt.preventDefault();
    }

    setupLink(svt) {
        let stim = (svt && svt instanceof jQuery.Event) ? svt.data : this
        let $link = $(svt.target)
        $.ajax({
            method: 'GET',
            url: $link.attr('href'),
        }).then(
           function(data){
                if (typeof $link.data('spinner-div-id') !== 'undefined'){
                   $('#'+$link.data('spinner-div-id')).hide()
                }
               var encodedUri = 'data:application/csv;charset=utf-8,' + encodeURIComponent(data);
               var link = document.createElement("a");
               link.style = "display: none";
               link.id = 'ajax-csv-tmp'
               link.setAttribute("href", encodedUri);
               link.setAttribute("download", "test.csv");
               link.innerHTML = "Download";
               document.body.appendChild(link);
               link.click();
               $('#ajax-csv-tmp').remove();
           },
            function (data) {
                if (typeof $link.data('spinner-div-id') !== 'undefined') {
                    $('#' + $link.data('spinner-div-id')).hide()
                }
                alert('Problem encountered!!! Contact offices!!')
            })
        svt.stopImmediatePropagation()
        svt.preventDefault()
    }

    /*  Not sure we need to do this type of thing yet
        reconnect_stim_controllers(stim){
            let ajax_render_controller = stimulus.controllers.filter(
                obj => {
                    return obj.context.scope.identifier == 'ajax-render'
                })[0]

            let billable_tier_display_controller = stimulus.controllers.filter(
                obj => {
                    return obj.context.scope.identifier == 'billable-tier-display'
                })[0]

            if (ajax_render_controller){
                ajax_render_controller.connect()
            }

            if (billable_tier_display_controller){
                billable_tier_display_controller.connect()
            }
        }*/


}