// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// region: Responsive Country, Region, Time-zone selection utilities
//
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cc', 'rg', 'tz']   // A shoot button

  connect() {
    // console.info("REGION CONNECT")
    let stim = this
    $(stim.ccTargets)
      .filter('select[data-rgn]')
      .each(function (t, target) {
        let $target = $(target)
        let ctxt = { stim: stim, selector: target, rgn: $target.data('rgn') }
        $target.on('change', ctxt, stim.query)
      })

    $(stim.rgTargets)
      .filter('select')
      .on('change', { stim: stim }, stim.check)

    $(stim.tzTargets)
      .filter('select')
      .on('change', { stim: stim }, stim.check)
  }

  query(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    // console.log('region#query', svt, ctxt)
    if (ctxt && ctxt.rgn.query.length) {
      let $select = $(svt.currentTarget)
      let done_fn = function (stim, ctxt) {
        return function (data, status, xhr) {
          let resp = xhr.responseJSON
          // console.log('region#query#done', xhr, data)
          if (resp) { stim._rewrite(ctxt, resp) }
          return true
        }
      }

      //
      // We include current region and timezone values as parameters, so that mismatched
      // values will always appear in the resultant option lists.
      let paramset = { cc: $select.val(), rg: $(ctxt.rgn.rg).val(), tz: $(ctxt.rgn.tz).val() }
      $.get({ url: ctxt.rgn.query, data: $.param(paramset), dataType: 'json' }).done(done_fn(stim, ctxt))
    }
    return true
  }

  check(svt) {
    let ctxt = (svt && svt instanceof jQuery.Event) ? svt.data : null
    let stim = (ctxt) ? ctxt.stim : this
    let $select = $(svt.currentTarget)
    let $selected = $select.find(`option[value="${$select.val()}"]`)
    let invalid = $selected.length ? $selected.text().endsWith('*') : false

    // console.log('region#check', svt, ctxt, $select)
    $select.toggleClass('is-invalid', invalid)
    return true
  }

  _rewrite(ctxt, choices) {
    if (ctxt && ctxt.rgn) {
      // console.log('region##rewrite', ctxt, choices)
      if (ctxt.rgn.rg && ctxt.rgn.rg.length) {
        ctxt.stim._rewrite_select($(ctxt.rgn.rg), ctxt, choices.regions)
      }
      if (ctxt.rgn.tz && ctxt.rgn.tz.length) {
        ctxt.stim._rewrite_select($(ctxt.rgn.tz), ctxt, choices.timezones)
      }
    }
  }

  _rewrite_select($select, ctxt, choices) {
    // console.log('region##rewrite_select', choices)
    if ($select.length) {
      let invalid = false
      let $prior = $select.find('option[selected]').first()
      let was_v = $prior.length ? $prior.val().trim() : $select.val().trim()
      let was_t = $prior.length ? $prior.text().replace(/[*]*$/, '').trim() : $select.val().trim()
      // console.log(`region##rewrite_select(${was_v})`, choices)
      $select.toggleClass('is-invalid', false).empty().append("<option value></option>")

      for (let o in choices) {
        let choice = choices[o]
        let selected = was_v.toLowerCase().trim() == choice[1].toLowerCase().trim()
        //
        // If option text ends with an asterisk, it means that the option represents an invalid
        // choice: that the selected value is no longer legitimate.
        invalid = choice[0].endsWith('*') || invalid
        let opt = `<option value="${choice[1]}" ${selected ? 'selected' : ''}">${choice[0]}</option>`
        $select.append(opt)
      }

      //
      // Re-write original choice into the select element, and mark it as invalid if such
      // is the case.
      $select.toggleClass('is-invalid', invalid).val(was_v)
    }

  }


}
