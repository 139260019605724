// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
//
/////////////////////////////////////////////////////////////////////////////////////////
// Disable and deselect a checkbox if another checkbox has been disabled.
//
// target "allowed" is the checkbox that allows/disallows the
// target checkbox "modify"
//
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['allowed', 'modify']

  connect() {
    let stim = this
    console.info("disabler CONNECT", stim)
  }

  changed(event) {
    let $input = $(this.allowedTargets)
    let checked = $input.is(':checked')
    console.log("Disabler event, checked: ", checked)
    let $modify_selected = $(this.modifyTargets)
    //console.log("Disabler Modifying: ", $modify_selected.val())
    if (!checked) {
      $modify_selected.prop("checked", false)
    }
    $modify_selected.attr("disabled", !checked)
  }
}
