import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["billing_select"];

  connect() {
    let stim = this
    $(stim.billing_selectTargets).each(function (i, select) {
      $(select).on("change", this, function () {
        let subscription_id = $(this).data("subscriptionId")
        let selected_option = $(this).find(":selected")
        let description = $(selected_option).data("description")
        //let display = $("#billing-description-display");
        let display = $(`#billing-description-display-${subscription_id}`)
        display.html(description)

        let payment_form_div = $(`#paygay-cc-form-${subscription_id}`)
        let nocharge_form_div = $(`#no-charge-plan-${subscription_id}`)
        // This div is for changing your plan
        let payment_change_form_div = $(
          `#change-your-payment-div-${subscription_id}`
        )

        let non_billable_tier_id = $(
          `#non-billable-tier-id-${subscription_id}`
        )
        if ($(selected_option).data("billable") == true) {
          nocharge_form_div.addClass("d-none")
          payment_form_div.removeClass("d-none")
          payment_change_form_div.removeClass("d-none")
        } else {
          non_billable_tier_id.val(selected_option.val())
          nocharge_form_div.removeClass("d-none")
          payment_form_div.addClass("d-none")
          payment_change_form_div.addClass("d-none")
        }
      })
    })
  }
}
