//# (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
export function display_messages(
  data,
  message_type,
  display_div_id,
  auto_close = false
) {
  let bs_message_type = ""
  switch (message_type) {
    case "errors":
      bs_message_type = "danger"
      break
    case "messages":
      bs_message_type = "success"
      break
    default:
      console.warn(`display_messages: Unknown message type "${message_type}"`)
  }

  if (data.hasOwnProperty(message_type)) {
    for (var key of Object.keys(data[message_type])) {
      let bs_message_type_actual = bs_message_type
      //
      // Allow 'alert' and 'info' keys to override the default color class
      switch (key) {
        case "alert":
          bs_message_type_actual = "danger"
          break
        case "info":
          bs_message_type_actual = "success"
          break
      }
      if (data[message_type][key] instanceof Array) {
        // This should be an array ignoring anything else for now
        data[message_type][key].forEach(function (msg, ind) {
          add_a_message(bs_message_type_actual, msg, display_div_id, auto_close)
        })
      }
    }
  }
}

function add_a_message(type, message, display_div_id, auto_close = false) {
  let dismiss_button = $(
    "<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>"
  )
  let div_html = $(`<strong>${message}</strong>`)
  let new_message_alert = $("<div></div>")
    .addClass(`alert alert-${type} alert-dismissable fade show`)
    .html(div_html)
  new_message_alert.append(dismiss_button)
  let alert_div = get_message_div(display_div_id)
  $(alert_div).append(new_message_alert)
  // if set autoclose the alerts in 5 seconds
  if (auto_close == true) {
    $(new_message_alert)
      .delay(5000)
      .slideUp(200, function () {
        $(this).alert("close")
      })
  }
}

export function get_message_div(display_div_id) {
  return $(`#${display_div_id}`)
}

export function refresh_page() {
  location.reload()
}

export function refresh_datatable(dt_id = null) {
  try {
    if ($(`#${dt_id}`).length == 1) {
      setTimeout(function () {
        $(`#${dt_id}`).DataTable().ajax.reload()
      }, 2000)
    }
  } catch (error) {
    console.log(error)
  }
}
