// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL **
import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['select']

    connect() {
        let stim = this

        $(stim.selectTargets).each(function (i, select) {
            //$(select).selectpicker()
        })
    }
}