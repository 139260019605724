// (c) Copyright 2022 Nomadix Inc, ** PRIVILEGED & CONFIDENTIAL ** 
import { Controller } from 'stimulus'

// this controller will load a remote partial based on a
// rails controller action or using a data-target.
// Using a data-action
// (E.G."click->remote-partial#load_partial" ) and
// requires a few data attributes
//
// data-partial-action - controller action with ids and parameters in the URL.
// In the case that you are using this controller for the purpose of loading a
// nested attribute replace the primary key in the URL with a -1 which will be
// replaced by the controls value when the js event is triggered.
//
// data-display-id - container selector ID (E.G #YourId ) to display the returned parsed partial
//
// data-is-loaded (optional) - if not used it will only load the partial once
// per event trigger if set to 'always_load' it will always load the partial


export default class extends Controller {
  static targets = ['select', 'autoload']

  connect() {
    let stim = this
    $(stim.selectTargets).each(function (f, select) {
      let select_for_remote = $(select)
      select_for_remote.on('change', stim, stim.load_partial)
    })

    $(stim.autoloadTargets).each(function (f, a_obj) {
      console.log('remote load')
      stim.load_partial()
    })

  }

  load_partial() {
    let nested_attribute_partial_load = (this.element == undefined)
    let this_element = (nested_attribute_partial_load) ? this : this.element
    let already_loaded = $(this_element).data('isLoaded')
    if (already_loaded == 'loaded') {
      return true
    }
    let f_action = $(this_element).data('partialAction')

    if(nested_attribute_partial_load){
      // if we are here this is loading a partial for
      // nested attributes which is triggered by a data-target in lieu
      // of a data-action
      let primary_key = $(this_element).val()
      f_action = (primary_key === '') ? f_action :
          f_action.replace(/\/-1\//i,  `/${primary_key}/`)
    }
    let p_container = $(this_element).data('displayId')
    $.ajax({
      type: "get",
      url: f_action,
      success: function (data, status, xhr) {
        if ($(this_element).data("isLoaded") !== "always_load") {
          $(this_element).data("isLoaded", "loaded");
        }
        $(p_container).html(data);
        if ($(this_element).data("spinner-div-id")) {
          $(`#${$(this_element).data("spinner-div-id")}`).hide();
        }

        if ($(this_element).data("modal")) {
          $(`#${$(this_element).data("modal")}`).modal("show");
        }
      },
      error: function (data, status, xhr) {
        alert("Error please contact our offices!!!");
        console.log(
          data.status + " " + data.statusText + " " + data.responseText
        );
        $(p_container).html(
          '<div class="text-center">Error please contact our offices!!!</div'
        );
      },
    });
  }

}